import { ILevel, Level } from './level';
import { ISize, Size } from './size';
import { DocumentModel } from './utils/document-model';

export interface IGroup {
  code?: string;
  description?: string;
  favourite?: boolean;
  levels?: ILevel[];
  mixmatch?: boolean;
  price?: number;
  size?: ISize;
  synchronization?: boolean;
  synchronizationSize?: boolean;
  title?: string;
}

export class Group extends DocumentModel<IGroup> implements IGroup {
  readonly code: string;
  readonly description: string;
  readonly favourite: boolean;
  readonly levels: Level[];
  readonly mixmatch: boolean;
  readonly price: number;
  readonly size: Size;
  readonly synchronization: boolean;
  readonly synchronizationSize: boolean;
  readonly title: string;

  constructor(id: string, data: IGroup) {
    super(id, data);
    this.key = id;
    this.code = data.code ?? '';
    this.description = data.description ?? '';
    this.favourite = data.favourite ?? false;
    this.levels = data.levels?.map((level) => new Level(level)) ?? [];
    this.mixmatch = data.mixmatch ?? false;
    this.price = data.price ?? 0;
    this.size = new Size(data.size);
    this.synchronization = data.synchronization ?? false;
    this.synchronizationSize = data.synchronizationSize ?? false;
    this.title = data.title ?? '';
  }

  toFirestore() {
    return super.toFirestore();
  }
}
