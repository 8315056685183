import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../firebase/Auth";
import { BusySpin } from "../routes/Routes";

// Sets correct company id to path.
const Redirector = () => {
  const { currentCompany, loading } = useContext(AuthContext);

  if (loading) {
    return <BusySpin></BusySpin>;
  } else if (currentCompany) {
    return (
      <Redirect
        to={{
          pathname: `/c/${currentCompany.key}/overview`,
        }}
      />
    );
  } else {
    return <Redirect to={{ pathname: "/onboarding" }}></Redirect>;
  }
};

export { Redirector };
