import {
  collection,
  DocumentData,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  Timestamp,
  where,
} from 'firebase/firestore';
import { DateTime } from 'luxon';
import { db } from '../firebase/Fb';

const StreamSales = (
  companyId: string,
  storeId: string,
  startDateTime: DateTime | undefined,
  endDateTime: DateTime | undefined,
  observer: {
    next?: ((snapshot: QuerySnapshot<DocumentData>) => void) | undefined;
    error?: ((error: Error) => void) | undefined;
    complete?: (() => void) | undefined;
  }
) => {
  if (companyId && storeId) {
    console.log('Requesting for Sales.');
    let q = query(
      collection(db, `companies/${companyId}/stores/${storeId}/sales`),
      orderBy('createdTime', 'desc')
    );
    if (startDateTime) {
      q = query(
        q,
        where('createdTime', '>=', Timestamp.fromDate(startDateTime.toJSDate()))
      );
      if (endDateTime) {
        q = query(
          q,
          where('createdTime', '<=', Timestamp.fromDate(endDateTime.toJSDate()))
        );
      }
    }
    return onSnapshot(q, observer);
  }
};

export { StreamSales };
