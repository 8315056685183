import {
  MinusOutlined,
  PictureOutlined,
  PlusOutlined,
} from '@ant-design/icons/lib/icons';
import { Avatar, Button, Modal, Radio, Row, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import _ from 'lodash';
import { useEffect, useState } from 'react';
// @ts-ignore
import NumPad from 'react-numpad';
import { Amount } from '../../../models/amount';
import { CartItem } from '../../../models/cart-item';
import { ppPrice } from '../../../utils/Formatter';
import './ProductCart.scss';

const cartItemDialog = (
  cartItem: CartItem,
  onSave: Function,
  maxDiscount: number
) => {
  const Dialog = Modal.success({
    centered: true,
    closable: true,
    maskClosable: false,
    title: (
      <Space>
        <Avatar shape='square' size='large' icon={<PictureOutlined />} />
        <Text>{cartItem.product.title}</Text>
      </Space>
    ),
    okText: null,
    icon: null,
    content: (
      <CartItemDialogContent
        cartItem={cartItem}
        onSave={() => {
          onSave();
          Dialog.destroy();
        }}
        onCancel={() => {
          Dialog.destroy();
        }}
        maxDiscount={maxDiscount}
      />
    ),
    okButtonProps: {
      style: {
        display: 'none',
      },
    },
  });
};

const CartItemDialogContent = ({
  cartItem,
  onSave,
  onCancel,
  maxDiscount,
}: {
  cartItem: CartItem;
  onSave: Function;
  onCancel: Function;
  maxDiscount: number;
}) => {
  const [isValid, setIsValid] = useState(true);
  const [quantity, setQuantity] = useState(cartItem.quantity);
  const [discountType, setDiscountType] = useState<'dollar' | 'percentage'>(
    cartItem.discounts[0]?.unit === 'AmountUnit.Dollar'
      ? 'dollar'
      : 'percentage'
  );
  const [discountDollarValue, setDiscountDollarValue] = useState(
    cartItem.discounts[0]?.unit === 'AmountUnit.Dollar'
      ? cartItem.discounts[0].number
      : 0
  );
  const [discountPercentValue, setDiscountPercentValue] = useState(
    cartItem.discounts[0]?.unit === 'AmountUnit.Percentage'
      ? cartItem.discounts[0].number * 100
      : 0
  );

  useEffect(() => {
    if (discountType === 'percentage' && discountPercentValue > maxDiscount) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [discountType, discountPercentValue]);

  return (
    <Space direction='vertical' style={{ width: '100%', marginTop: 16 }}>
      <Text strong>Quantity</Text>
      <Row justify='space-between' wrap={false}>
        <Button
          block
          icon={<MinusOutlined />}
          style={{ marginRight: 8, width: '100%' }}
          onClick={() => {
            if (quantity <= 1) {
              setQuantity(0);
            } else {
              setQuantity(quantity - 1);
            }
          }}
        />
        <span id='numpad-text-area-center' className='numpad-height'>
          <NumPad.Number
            position='center'
            onChange={(value: any) => {
              setQuantity(_.round(_.toNumber(value), 0));
            }}
            placeholder={quantity}
            decimal={0}
            negative={false}
          />
        </span>
        <Button
          block
          icon={<PlusOutlined />}
          style={{ marginLeft: 8, width: '100%' }}
          onClick={() => setQuantity(quantity + 1)}
        />
      </Row>
      <span></span>
      <span></span>
      {!cartItem.product.discountExcept && (
        <>
          <Text strong>Discount</Text>
          <Radio.Group
            value={discountType}
            onChange={(e) => setDiscountType(e.target.value)}
          >
            <Radio.Button value='percentage'>Percentage (%)</Radio.Button>
            <Radio.Button value='dollar'>Amount ($)</Radio.Button>
          </Radio.Group>
          <div>
            <span
              id='numpad-text-area-center'
              className='numpad-height'
              style={{ display: discountType === 'dollar' ? '' : 'none' }}
            >
              <NumPad.Number
                position='center'
                onChange={(value: any) => {
                  setDiscountDollarValue(_.round(_.toNumber(value), 2));
                }}
                placeholder={ppPrice(discountDollarValue)}
                decimal={2}
                negative={false}
              />
            </span>
            <span
              id='numpad-text-area-center'
              className='numpad-height'
              style={{ display: discountType === 'percentage' ? '' : 'none' }}
            >
              <Space>
                <NumPad.Number
                  position='center'
                  onChange={(value: any) => {
                    setDiscountPercentValue(_.round(_.toNumber(value), 0));
                  }}
                  placeholder={`${discountPercentValue}%`}
                  decimal={0}
                  negative={false}
                />
                <Text style={{ display: isValid ? 'none' : '' }} type='danger'>
                  CANNOT EXCEED {maxDiscount}%
                </Text>
              </Space>
            </span>
          </div>
        </>
      )}
      <Row justify='space-between' style={{ marginTop: 32 }}>
        <Button
          danger
          onClick={() => {
            cartItem.quantity = 0;
            onSave();
          }}
        >
          Remove
        </Button>
        <Space>
          <Button onClick={() => onCancel()}>Cancel</Button>
          <Button
            disabled={!isValid}
            type='primary'
            onClick={() => {
              cartItem.quantity = quantity;
              if (discountType === 'dollar') {
                if (discountDollarValue > 0) {
                  cartItem.discounts = [
                    new Amount({
                      number: discountDollarValue,
                      unit: 'AmountUnit.Dollar',
                    }),
                  ];
                } else {
                  cartItem.discounts = [];
                }
              } else if (discountType === 'percentage') {
                if (discountPercentValue >= 100) {
                  cartItem.discounts = [
                    new Amount({
                      number: 1,
                      unit: 'AmountUnit.Percentage',
                    }),
                  ];
                } else if (discountPercentValue <= 0) {
                  cartItem.discounts = [];
                } else {
                  cartItem.discounts = [
                    new Amount({
                      number: discountPercentValue / 100,
                      unit: 'AmountUnit.Percentage',
                    }),
                  ];
                }
              } else {
                throw Error('Unsupported discount type');
              }
              onSave();
            }}
          >
            Save
          </Button>
        </Space>
      </Row>
    </Space>
  );
};

export { cartItemDialog };
