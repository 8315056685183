import moment, { Moment } from 'moment';
import { convertDateToMoment } from '../utils/Formatter';
import { Company } from './company';
import { Date } from './date';
import { PayPeriodType, Payroll } from './payroll';

export interface PayPeriod {
  period: number;
  start: Date;
  startHash: number; // 2020-01-15 => 20200115
  end: Date;
  endHash: number;
}

export class PayPeriodGenerator {
  year: Moment;
  type: PayPeriodType;
  start: Moment | undefined;

  constructor(year: number, type: PayPeriodType, start: Moment | undefined) {
    this.year = moment(`${year}-06-01`); // To be safe, using middle of the calendar year.
    this.type = type;
    this.start = start ? moment(start).startOf('day') : undefined;
  }

  getStartAndEndYearDate() {
    if (this.start) {
      // Find start
      let start = moment(this.start);
      if (this.year.year() > this.start.year()) {
        start = moment(this.year).startOf('year');
        if (
          this.type === PayPeriodType.BiWeekly ||
          this.type === PayPeriodType.Weekly
        ) {
          start.day(this.start.day());
        }
        if (this.type === PayPeriodType.BiWeekly) {
          if (Math.abs(start.diff(this.start, 'weeks')) % 2 === 1) {
            start.add(1, 'week');
          }
        }
        if (moment(start).subtract(1, 'day').year() === this.year.year()) {
          if (this.type === PayPeriodType.Weekly) {
            start.subtract(1, 'week');
          } else if (this.type === PayPeriodType.BiWeekly) {
            start.subtract(2, 'week');
          }
        }
      }

      // Find end
      let end = moment(this.year).endOf('year');
      if (
        this.type === PayPeriodType.BiWeekly ||
        this.type === PayPeriodType.Weekly
      ) {
        end.day(this.start.day()).subtract(1, 'day');
      }
      if (this.type === PayPeriodType.BiWeekly) {
        let nextYearStartDate = moment(this.year)
          .endOf('year')
          .day(this.start.day());
        if (Math.abs(nextYearStartDate.diff(this.start, 'weeks')) % 2 === 1) {
          end.add(1, 'week');
        }
      }
      if (end.year() > this.year.year()) {
        if (this.type === PayPeriodType.Weekly) {
          end.subtract(1, 'weeks');
        } else if (this.type === PayPeriodType.BiWeekly) {
          end.subtract(2, 'weeks');
        }
      }
      return [start, end];
    }
    return [undefined, undefined];
  }

  getPayPeriodsForWeeklies(type: PayPeriodType): PayPeriod[] {
    const periods: PayPeriod[] = [];
    let offsetWeeks = 0;
    if (type === PayPeriodType.BiWeekly) {
      offsetWeeks = 2;
    } else if (type === PayPeriodType.Weekly) {
      offsetWeeks = 1;
    }

    const [startOfYearDate, endOfYearDate] = this.getStartAndEndYearDate();
    if (!startOfYearDate || !endOfYearDate || offsetWeeks === 0) {
      return [];
    }
    for (
      let payrollNumber = 1,
        startOfPeriod = startOfYearDate,
        endOfPeriod = moment(startOfPeriod)
          .add(offsetWeeks, 'week')
          .subtract(1, 'days');
      startOfPeriod < endOfYearDate;
      payrollNumber++,
        startOfPeriod.add(offsetWeeks, 'week'),
        endOfPeriod.add(offsetWeeks, 'week')
    ) {
      periods.push({
        period: payrollNumber,
        start: new Date({
          year: startOfPeriod.year(),
          month: startOfPeriod.month() + 1,
          day: startOfPeriod.date(),
        }),
        startHash: +`${startOfPeriod.format('YYYYMMDD')}`,
        end: new Date({
          year: endOfPeriod.year(),
          month: endOfPeriod.month() + 1,
          day: endOfPeriod.date(),
        }),
        endHash: +`${endOfPeriod.format('YYYYMMDD')}`,
      });
    }

    return periods;
  }

  getPayPeriods(): PayPeriod[] {
    let periods: PayPeriod[] = [];

    if (
      this.type === PayPeriodType.Weekly ||
      this.type === PayPeriodType.BiWeekly
    ) {
      periods = this.getPayPeriodsForWeeklies(this.type);
    } else if (this.type === PayPeriodType.SemiMonthly) {
      const [startOfYearDate, endOfYearDate] = this.getStartAndEndYearDate();
      if (!startOfYearDate || !endOfYearDate || !this.start) return [];
      for (
        let payrollNumber = 1,
          startOfPeriod = moment(startOfYearDate),
          endOfPeriod = moment(startOfPeriod).endOf('month');
        startOfPeriod < endOfYearDate;
        payrollNumber++,
          startOfPeriod.add(1, 'month').startOf('month'),
          endOfPeriod = moment(startOfPeriod).endOf('month')
      ) {
        let midOfMonth = moment(startOfPeriod).date(endOfPeriod.date() / 2);

        if (this.start <= midOfMonth) {
          periods.push({
            period: payrollNumber,
            start: new Date({
              year: startOfPeriod.year(),
              month: startOfPeriod.month() + 1,
              day: startOfPeriod.date(),
            }),
            startHash: +`${startOfPeriod.format('YYYYMMDD')}`,
            end: new Date({
              year: midOfMonth.year(),
              month: midOfMonth.month() + 1,
              day: midOfMonth.date(),
            }),
            endHash: +`${midOfMonth.format('YYYYMMDD')}`,
          });

          payrollNumber++;
        }

        midOfMonth.add(1, 'day');

        if (this.start > midOfMonth) {
          midOfMonth = moment(this.start);
        }

        periods.push({
          period: payrollNumber,
          start: new Date({
            year: midOfMonth.year(),
            month: midOfMonth.month() + 1,
            day: midOfMonth.date(),
          }),
          startHash: +`${midOfMonth.format('YYYYMMDD')}`,
          end: new Date({
            year: endOfPeriod.year(),
            month: endOfPeriod.month() + 1,
            day: endOfPeriod.date(),
          }),
          endHash: +`${endOfPeriod.format('YYYYMMDD')}`,
        });
      }
    } else if (this.type === PayPeriodType.Monthly) {
      const [startOfYearDate, endOfYearDate] = this.getStartAndEndYearDate();
      if (!startOfYearDate || !endOfYearDate) return [];
      for (
        let payrollNumber = 1,
          startOfPeriod = moment(startOfYearDate),
          endOfPeriod = moment(startOfPeriod).endOf('month');
        startOfPeriod < endOfYearDate;
        payrollNumber++,
          startOfPeriod.add(1, 'month').startOf('month'),
          endOfPeriod = moment(startOfPeriod).endOf('month')
      ) {
        periods.push({
          period: payrollNumber,
          start: new Date({
            year: startOfPeriod.year(),
            month: startOfPeriod.month() + 1,
            day: startOfPeriod.date(),
          }),
          startHash: +`${startOfPeriod.format('YYYYMMDD')}`,
          end: new Date({
            year: endOfPeriod.year(),
            month: endOfPeriod.month() + 1,
            day: endOfPeriod.date(),
          }),
          endHash: +`${endOfPeriod.format('YYYYMMDD')}`,
        });
      }
    }

    return periods;
  }
}

export const isPayPeriodRouteValid = (
  year: number | undefined,
  type: PayPeriodType | undefined,
  start: Date | undefined,
  hash: string | undefined
) => {
  if (!year || !type || !start || !hash) {
    return false;
  }
  const [
    currentPayPeriod,
    totalPayPeriods,
    currentPayPeriodStartHash,
    currentPayPeriodEndHash,
  ] = hash.split('-');
  if (
    !currentPayPeriod ||
    !totalPayPeriods ||
    !currentPayPeriodStartHash ||
    !currentPayPeriodEndHash
  ) {
    return false;
  }
  const generator = new PayPeriodGenerator(
    year,
    type,
    convertDateToMoment(start)
  );
  return generator.getPayPeriods().some((payPeriod) => {
    return (
      payPeriod.period === +currentPayPeriod &&
      payPeriod.startHash === +currentPayPeriodStartHash &&
      payPeriod.endHash === +currentPayPeriodEndHash
    );
  });
};

/**
 * Returns undefined if prev payroll cannot be found. Otherwise, return nth from Payroll.
 * @param currentPayrollId
 * @param payrolls
 * @param company
 * @returns
 */
const getNthFromPayroll = (
  nth: number,
  currentPayrollId: string,
  payrolls: Payroll[],
  company: Company
) => {
  if (
    currentPayrollId &&
    company.payPeriodType &&
    company.payPeriodStartDate &&
    !!payrolls.length
  ) {
    const [_, __, startDate, endDate] = currentPayrollId.split('-');

    if (_ && __ && startDate && endDate) {
      const generator = new PayPeriodGenerator(
        moment(endDate).year(),
        company.payPeriodType,
        convertDateToMoment(company.payPeriodStartDate)
      );

      // Find current payroll from generator. We will use this to -1 index to get the the last payroll.
      const totalPayPeriods = generator.getPayPeriods();
      const index = totalPayPeriods.findIndex(
        (payPeriod) =>
          payPeriod.startHash === +startDate && payPeriod.endHash === +endDate
      );

      // Insure nextOrPrevIndex is inbound and verify if it actually exists in payroll array.
      const nextOrPrevIndex = index + nth;
      if (
        nextOrPrevIndex >= 0 &&
        nextOrPrevIndex <= totalPayPeriods.length - 1
      ) {
        const prevPayroll = totalPayPeriods[nextOrPrevIndex];
        return payrolls.find((p) => {
          return (
            p.startDate.year === prevPayroll.start.year &&
            p.startDate.month === prevPayroll.start.month &&
            p.startDate.day === prevPayroll.start.day &&
            p.endDate.year === prevPayroll.end.year &&
            p.endDate.month === prevPayroll.end.month &&
            p.endDate.day === prevPayroll.end.day
          );
        });
      }
    }
  }
  return undefined;
};

export { getNthFromPayroll };
