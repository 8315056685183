import {
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';

export interface IDrawerTransfer {
  companyId: string;
  storeId: string;
  reason: string;
  value: number;
  type: 'ReasonType.payout' | 'ReasonType.transfer';
  createdTime: Timestamp;
}

class DrawerTransfer implements IDrawerTransfer {
  companyId: string;
  storeId: string;
  reason: string;
  value: number;
  type: 'ReasonType.payout' | 'ReasonType.transfer';
  createdTime: Timestamp;

  // Non-db columns
  reference: DocumentReference;
  key: string; // Uuid

  constructor(snapshot: QueryDocumentSnapshot<DocumentData>) {
    const document = snapshot.data();

    this.companyId = document.companyId ?? '';
    this.storeId = document.storeId ?? '';
    this.reason = document.reason ?? '';
    this.value = document.value ?? 0;
    this.type = document.type ?? '';
    this.createdTime = document.createdTime ?? { nanoseconds: 0, seconds: 0 };

    // Non-db columns
    this.reference = snapshot.ref;
    this.key = snapshot.id;
  }
}

export interface IDrawer {
  initialCash: number;
  salesCash: number;
  transferCash: number;
  actualCash: number;
  reason: string;
  createdTime: Timestamp;
  updatedTime: Timestamp;
}

class Drawer implements IDrawer {
  initialCash: number;
  salesCash: number;
  transferCash: number;
  actualCash: number;
  reason: string;
  createdTime: Timestamp;
  updatedTime: Timestamp;

  // Non-db columns
  reference: DocumentReference;
  key: string; // Uuid

  constructor(snapshot: QueryDocumentSnapshot<DocumentData>) {
    const document = snapshot.data();

    this.initialCash = document.initialCash ?? 0;
    this.salesCash = document.salesCash ?? 0;
    this.transferCash = document.transferCash ?? 0;
    this.actualCash = document.actualCash ?? -1;
    this.reason = document.reason ?? '';
    this.createdTime = document.createdTime ?? { nanoseconds: 0, seconds: 0 };
    this.updatedTime = document.updatedTime ?? { nanoseconds: 0, seconds: 0 };

    // Non-db columns
    this.reference = snapshot.ref;
    this.key = snapshot.id;
  }

  isActive() {
    return this.actualCash < 0;
  }

  getCashShort() {
    if (this.isActive()) return 0;
    // if (
    //   this.actualCash -
    //     (this.initialCash + this.salesCash + this.transferCash) >
    //   100
    // ) {
    //   debugger;
    // }
    return (
      this.actualCash - (this.initialCash + this.salesCash + this.transferCash)
    );
  }
}

export { Drawer, DrawerTransfer };
