import { masterRepository } from '../../firebase/db';
import { SalesOrder } from '../../models/sales-order';
import {
  createGenericContext,
  createGenericProvider,
} from './generics/GenericContextProvider';

const SalesOrderContext = createGenericContext<SalesOrder>();
const SalesOrderProvider = createGenericProvider<SalesOrder>(
  masterRepository.salesorder,
  SalesOrderContext,
  'SalesOrder'
);

export { SalesOrderContext, SalesOrderProvider };
