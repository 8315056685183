export interface IRange {
  max?: number;
  min?: number;
}

export class Range implements IRange {
  readonly max: number;
  readonly min: number;

  constructor(data?: IRange) {
    this.max = data?.max ?? 0;
    this.min = data?.min ?? 0;
  }
}
