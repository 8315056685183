import { masterRepository } from '../../firebase/db';
import { Product } from '../../models/product';
import {
  createGenericContext,
  createGenericProvider,
} from './generics/GenericContextProvider';

const ProductContext = createGenericContext<Product>();
const ProductProvider = createGenericProvider<Product>(
  masterRepository.product,
  ProductContext,
  'Product'
);

export { ProductContext, ProductProvider };
