import { MehOutlined } from '@ant-design/icons';
import { message, notification } from 'antd';

const Messages = {
  saved: () => message.success('Saved'),
  created: () => message.success('Created 🎉'),
  deleted: () => message.success('Deleted'),
  info: (msg: string) => message.info(msg),
};

const Notifications = {
  error: (e: any) => {
    notification['error']({
      message: (
        <span>
          Something went wrong... <MehOutlined />
        </span>
      ),
      description: e + '',
      duration: 0,
    });
    console.error(e);
  },
};

export { Messages, Notifications };
