import { existSpacer } from '../utils/Formatter';
import { Address, IAddress } from './address';
import { DocumentModel, FirestoreTimestamp } from './utils/document-model';

interface ICustomer {
  address?: IAddress;
  businessname?: string;
  costPlus?: number;
  email?: string;
  firstname?: string;
  lastname?: string;
  loyalty?: boolean;
  phone?: string;
  points?: number;
  lastLoyaltyUsedTime?: FirestoreTimestamp;
  createdTime?: FirestoreTimestamp;
  updatedTime?: FirestoreTimestamp;
}

export class Customer extends DocumentModel<ICustomer> implements ICustomer {
  readonly address: Address;
  readonly businessname: string;
  readonly costPlus: number;
  readonly email: string;
  readonly firstname: string;
  readonly lastname: string;
  readonly loyalty: boolean;
  readonly phone: string;
  readonly points: number;
  readonly lastLoyaltyUsedTime?: FirestoreTimestamp;
  readonly createdTime?: FirestoreTimestamp;
  readonly updatedTime?: FirestoreTimestamp;

  constructor(id: string, data: ICustomer) {
    super(id, data);
    this.address = new Address(data.address);
    this.businessname = data.businessname ?? '';
    this.costPlus = data.costPlus ?? 0;
    this.email = data.email ?? '';
    this.firstname = data.firstname ?? '';
    this.lastname = data.lastname ?? '';
    this.loyalty = data.loyalty ?? false;
    this.phone = data.phone ?? '';
    this.points = data.points ?? 0;
    this.lastLoyaltyUsedTime = data.lastLoyaltyUsedTime;
    this.createdTime = data.createdTime;
    this.updatedTime = data.updatedTime;
  }

  toFirestore() {
    const iCustomer = super.toFirestore();
    return iCustomer;
  }

  getName() {
    return `${this.firstname}${existSpacer(this.lastname)}`;
  }

  getAddress() {
    if (this.address) {
      return `${
        this.address.street ? this.address.street : this.address.address
      }${existSpacer(this.address.city)}${existSpacer(
        this.address.province
      )}${existSpacer(this.address.postalcode)}`;
    }
    return '';
  }

  getAddressMultiline() {
    if (this.address) {
      return `${
        this.address.street ? this.address.street : this.address.address
      }\n${existSpacer(this.address.city)}${existSpacer(
        this.address.province
      )}${existSpacer(this.address.postalcode)}`;
    }
    return '';
  }

  getBusinessName() {
    return this.businessname ? this.businessname : this.getName();
  }
}
