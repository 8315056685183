import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { SaleProvider } from '../../../providers/pagination/SaleProvider';
import { TransactionDetail } from './detail/TransactionDetail';
import { TransactionList } from './list/TransactionList';

const PaginatedTransactions = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Redirect from='/:url*(/+)' to={window.location.pathname.slice(0, -1)} />
      <Route
        path={`${url}/:id`}
        render={({ match }) => (
          <SaleProvider id={match.params.id}>
            <TransactionDetail />
          </SaleProvider>
        )}
      />
      <Route exact path={url} render={() => <TransactionList />} />
    </Switch>
  );
};

export { PaginatedTransactions };
