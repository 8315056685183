import {
  CaretDownOutlined,
  MenuFoldOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  Modal,
  Popover,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import {
  Link,
  RouteComponentProps,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import { AuthContext } from '../firebase/Auth';
import { generatePersonas } from '../models/employee';
import { getItemGroups, getRoutes, PosyRoutes } from '../routes/Routes';
import { Company } from '../services/CompanyService';
import './Appshell.scss';
import { PosyLogo, PosySingleLogo3 } from './common/PosyLogo';
import { Notification } from './notification/Notification';
import { UserProfileMenu } from './user/UserProfileMenu';

const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;

const Appshell = ({ location }: RouteComponentProps) => {
  const {
    currentUser,
    currentCompany,
    currentConfiguration,
    companies,
    setCompany,
    isTrial,
    trialDaysLeft,
  } = useContext(AuthContext);
  const { url } = useRouteMatch();
  const [minimized, setMinimized] = useState(
    !!Cookies.get('posy_sider_minimized')
  );
  const [notifiedUser, setNotifiedUser] = useState(false);
  const hasMultipleCompanies = companies.length > 1;

  let role: 'owner' | 'manager' | 'member' = 'member';
  if (currentCompany?.key) {
    switch (currentUser.firestore?.companies[currentCompany.key]) {
      case 'owner':
        role = 'owner';
        break;

      case 'manager':
        role = 'manager';
        break;

      default:
        role = 'member';
        break;
    }
  }

  useEffect(() => {
    Cookies.set('posy_sider_minimized', minimized ? 'minimized' : '');
  }, [minimized]);

  const isCompanyTrialExpired = (company: Company) => {
    if (company.trial) {
      return false;
    }
    if (Object.keys(company?.configurations ?? {}).length === 0) {
      return true;
    }
    return false;
  };

  const companyListMenu = (
    <Menu
      onClick={(e) => {
        const companyKey = e.key;
        const selectedCompany = companies.find((c) => c.key === companyKey);
        if (selectedCompany) {
          setCompany(selectedCompany);
        }
      }}
      selectedKeys={currentCompany?.key ? [currentCompany.key] : undefined}
    >
      {companies
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .map((company) => {
          return (
            <Menu.Item key={company.key}>
              <Space>
                {`${company?.name}${
                  company?.operatingName ? ` - ${company.operatingName}` : ''
                }`}
                {company.trial && <Tag color='blue'>Trial</Tag>}
                {isCompanyTrialExpired(company) && (
                  <Tag color='red'>Trial Expired</Tag>
                )}
              </Space>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  if (isTrial && !notifiedUser) {
    Modal.info({
      centered: true,
      title: 'Trial Period',
      content: (
        <div>
          <p>
            Thank you for signing up for a free trial. Once the Free Trial
            period is over, you will only have access to features specific to
            your plan.
          </p>
          <p>You have {trialDaysLeft} days remaining.</p>
        </div>
      ),
      onOk() {},
    });
    setNotifiedUser(true);
  }

  return (
    <Layout style={{ minHeight: '100%' }}>
      <Sider
        collapsible={minimized}
        collapsed={minimized}
        onCollapse={(onCollapse) => setMinimized(onCollapse)}
        style={{
          overflow: 'auto',
          height: '100%',
          position: 'fixed',
          left: 0,
          zIndex: 1,
        }}
      >
        <div
          style={{
            height: '48px',
            marginLeft: !minimized ? '15px' : '24px',
            paddingTop: '7px',
            transition:
              'font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
          }}
        >
          <Row justify='space-between'>
            <Col>
              <span
                onClick={() => setMinimized(!minimized)}
                style={{ cursor: 'pointer' }}
              >
                <PosySingleLogo3
                  style={{
                    height: '34px',
                    width: '34px',
                    verticalAlign: 'middle',
                    marginRight: minimized ? '' : '10px',
                    transition: 'all 0.3s',
                  }}
                />
                <PosyLogo
                  color='#FFFFFF'
                  style={{
                    verticalAlign: 'middle',
                    height: '28px',
                    width: '58px',
                    marginBottom: '5px',
                    display: minimized ? 'none' : '',
                    transition: 'all 0.3s',
                  }}
                />
              </span>
            </Col>
            <Col>
              <Button
                type='link'
                icon={<MenuFoldOutlined />}
                style={{
                  marginRight: '8px',
                  color: 'grey',
                  display: minimized ? 'none' : '',
                }}
                onClick={() => setMinimized(true)}
              ></Button>
            </Col>
          </Row>
        </div>
        <Menu
          theme='dark'
          mode='inline'
          selectedKeys={[location.pathname.split('/')[3]]} // Matching base route /inventory/:productId should also match with inventory
          className='align-posy-logo'
        >
          {getRoutes(currentConfiguration, isTrial, url, role)
            .filter((route) => !route.itemGroup)
            .map((route) => {
              return (
                <Menu.Item
                  key={route.key}
                  icon={route.icon}
                  style={{ marginTop: 0 }} // To remove small gap between the logo and the selected area.
                >
                  {route.title}
                  <Link to={route.path} />
                </Menu.Item>
              );
            })}
          {getItemGroups(currentConfiguration).map((group) => {
            return (
              <Menu.ItemGroup
                key={group.key}
                title={group.title}
                className={
                  minimized ? 'posy-group-item-hidden' : 'posy-group-item'
                }
              >
                {getRoutes(currentConfiguration, isTrial, url, role)
                  .filter(
                    (route) => route.itemGroup === group.key && route.isSubMenu
                  )
                  .map((route) => {
                    return (
                      <SubMenu
                        disabled={!route.hasLicense}
                        key={route.key}
                        icon={route.icon}
                        title={route.title}
                      >
                        {getRoutes(currentConfiguration, isTrial, url, role)
                          .filter((subroute) => subroute.subMenu === route.key)
                          .map((subroute) => {
                            return (
                              <Menu.Item
                                disabled={!subroute.hasLicense}
                                key={subroute.key}
                                icon={subroute.icon}
                                style={{ marginTop: 0, marginLeft: 0 }} // To remove small gap between the logo and the selected area.
                              >
                                {subroute.openWithNewTab ? (
                                  <a
                                    href={subroute.path}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    {subroute.title}
                                  </a>
                                ) : (
                                  <>
                                    {subroute.title}
                                    <Link to={subroute.path} />
                                  </>
                                )}
                              </Menu.Item>
                            );
                          })}
                      </SubMenu>
                    );
                  })}
                {getRoutes(currentConfiguration, isTrial, url, role)
                  .filter(
                    (route) =>
                      route.itemGroup === group.key &&
                      !route.isSubMenu &&
                      !route.subMenu
                  )
                  .map((route) => {
                    return (
                      <Menu.Item
                        disabled={!route.hasLicense}
                        key={route.key}
                        icon={route.icon}
                        style={{ marginTop: 0 }} // To remove small gap between the logo and the selected area.
                      >
                        {route.openWithNewTab ? (
                          <a
                            href={route.path}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {route.title}
                          </a>
                        ) : (
                          <>
                            {route.title}
                            <Link to={route.path} />
                          </>
                        )}
                      </Menu.Item>
                    );
                  })}
              </Menu.ItemGroup>
            );
          })}
        </Menu>
      </Sider>
      <Layout
        style={{ marginLeft: minimized ? 80 : 200, transition: 'all 0.3s' }}
      >
        <Header
          style={{
            background: '#fff',
            height: '48px',
            lineHeight: '48px',
            padding: '0 20px 0 32px',
            boxShadow: '0 1px 4px rgba(0,21,41,.08)',
          }}
        >
          <div className='toolbar'>
            <div>
              {hasMultipleCompanies ? (
                <Dropdown overlay={companyListMenu} trigger={['click']}>
                  <Button type='text' style={{ padding: 0 }}>
                    <Text strong>
                      {`${currentCompany?.name}${
                        currentCompany?.operatingName
                          ? ` - ${currentCompany.operatingName}`
                          : ''
                      }`}
                    </Text>
                    <CaretDownOutlined />
                  </Button>
                </Dropdown>
              ) : (
                <Space>
                  <Text strong>
                    {currentCompany?.name ?? currentCompany?.key}
                  </Text>
                  {hasMultipleCompanies ? <CaretDownOutlined /> : <></>}
                </Space>
              )}
            </div>
            <div className='toolbar-area'>
              <div className='action'>
                <Popover
                  placement='bottomLeft'
                  title={"Need help? We're here to help!"}
                  content={
                    <Space direction='vertical'>
                      <Text>
                        <span role='img' aria-label='canada-flag'>
                          🇨🇦
                        </span>{' '}
                        English +1 (403) 473-6704
                      </Text>
                      <Text>
                        <span role='img' aria-label='korea-flag'>
                          🇰🇷
                        </span>{' '}
                        Korean +1 (403) 975-0543
                      </Text>
                    </Space>
                  }
                  trigger='click'
                >
                  <QuestionCircleOutlined className='action-icon-size' />
                </Popover>
              </div>
              <div className='action'>
                <Notification />
              </div>
              <Popover
                overlayClassName='profile-popover'
                trigger='click'
                content={UserProfileMenu}
                placement='bottomRight'
              >
                <div className='action'>
                  <Avatar
                    icon={<UserOutlined />}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: '#ccc',
                    }}
                    // src={currentUser.firebase?.photoURL ?? ''}
                    src={
                      currentUser.firebase?.uid &&
                      generatePersonas(currentUser.firebase.uid)
                    }
                  />
                </div>
              </Popover>
            </div>
          </div>
        </Header>
        <Content className='main-content-area'>
          <MemoizedPosyRoutes />
        </Content>
        {/* <Footer style={{ textAlign: 'center' }}>
          <Text>
            Made with{' '}
            <span style={{ verticalAlign: '-0.125em' }}>
              <HeartSvg />
            </span>{' '}
            in Vancouver
          </Text>
        </Footer> */}
      </Layout>
    </Layout>
  );
};

const MemoizedPosyRoutes = React.memo(PosyRoutes);

export default withRouter(Appshell);
