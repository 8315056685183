import _ from 'lodash';
import { CartItem } from '../cart-item';

/**
 * Create a 2d delta table to record the price discount.
 *
 * Key: represented by 'group-id:product-id'
 * Value: array of number where index represents the number of items in cart, and the value being the price discount.
 * @param cart
 * @returns
 */
const generateDeltaTable = (cartItems: CartItem[]) => {
  const hashMap = new Map<string, number[]>();

  cartItems.forEach((item) => {
    // If it doesn't have groups or more than 1, skip.
    if (item.groups.length === 0 || item.groups.length > 1) {
      return; // continue.
    }

    // If delta table is already created for this group, skip.
    const key = `${item.groups[0].key}:${item.product.key}`;
    if (hashMap.has(key)) {
      return; // continue.
    }

    // If group doesn't have levels, skip.
    if (item.groups[0].levels.length === 0) {
      return; // continue;
    }

    // Set delta discount array.
    hashMap.set(key, generateDiscountArray(item));
  });

  return hashMap;
};

const generateDiscountArray = (cartItem: CartItem) => {
  const group = cartItem.groups[0];
  const levels = group.levels;

  // Sort from smallest quantity to biggest.
  levels.sort((a, b) => a.quantity - b.quantity);

  // Get the last element and create a delta array with last.quantity.
  const last = levels[levels.length - 1];
  const deltaTable = new Array(last.quantity).fill(0);

  levels.forEach((level) => {
    const pastDiscounts = deltaTable
      .slice(0, level.quantity)
      .reduce((a, b) => a + b, 0);
    deltaTable[level.quantity] = _.round(
      cartItem.product.price * level.quantity - pastDiscounts - level.price,
      2
    );
  });

  return deltaTable;
};

export { generateDeltaTable };
