import { Result } from 'antd';
import React from 'react';

const PermissionDenied = () => {
  return (
    <Result
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      status='403'
      title='403'
      subTitle='Sorry, you are not authorized to access this page.'
    />
  );
};

export { PermissionDenied };
