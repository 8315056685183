import { masterRepository } from '../../firebase/db';
import { Employee } from '../../models/employee';
import {
  createGenericContext,
  createGenericProvider,
} from './generics/GenericContextProvider';

const EmployeeContext = createGenericContext<Employee>();
const EmployeeProvider = createGenericProvider<Employee>(
  masterRepository.employee,
  EmployeeContext,
  'Employee'
);

export { EmployeeContext, EmployeeProvider };
