import { masterRepository } from '../../firebase/db';
import { Sale } from '../../models/sale';
import {
  createGenericContext,
  createGenericProvider,
} from './generics/GenericContextProvider';

const SaleContext = createGenericContext<Sale>();
const SaleProvider = createGenericProvider<Sale>(
  masterRepository.sale,
  SaleContext,
  'Sale'
);

export { SaleContext, SaleProvider };
