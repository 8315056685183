import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/Fb';

const StreamCart = (path: string, observer: any) => {
  if (path) {
    return onSnapshot(doc(db, path), observer);
  }
};

const StreamBridge = (path: string, observer: any) => {
  if (path) {
    return onSnapshot(doc(db, path), observer);
  }
};

export { StreamCart, StreamBridge };
