import _ from 'lodash';
import { Amount, calculateAmount, IAmount } from './amount';
import { CartItem, ICartItem } from './cart-item';
import { Department } from './department';
import { Fee } from './fee';
import { Group } from './group';
import { Product } from './product';
import { generateDeltaTable } from './utils/mix-match-calculation';

export interface ICart {
  cartItems?: ICartItem[];
  customerId?: string;
  deltaTable?: Map<string, number[]>;
  discounts?: IAmount[];
  displayId?: string;
  feesInclusive?: boolean;
}

export class Cart implements ICart {
  cartItems: CartItem[];
  readonly customerId: string;
  deltaTable: Map<string, number[]>;
  displayId: string;
  readonly discounts: Amount[];
  readonly feesInclusive: boolean;

  constructor(data?: ICart) {
    this.cartItems =
      data?.cartItems?.map((cartItem) => new CartItem(cartItem)) ?? [];
    this.discounts =
      data?.discounts?.map((discount) => new Amount(discount)) ?? [];
    this.feesInclusive = data?.feesInclusive ?? false;
    this.displayId = data?.displayId ?? '';
    this.customerId = data?.customerId ?? '';
    this.deltaTable = new Map<string, number[]>();
  }

  calculateSubtotal() {
    if (this.cartItems) {
      return this.cartItems.reduce(
        (prev, curr) => prev + curr.calculateSubtotal(),
        0
      );
    }
    return 0;
  }

  calculateFees(fee?: 'eco' | 'tax' | 'deposit' | 'custom') {
    if (this.cartItems) {
      return this.cartItems.reduce((prevCartItemAcc, currCartItem) => {
        return prevCartItemAcc + currCartItem.calculateFees(fee);
      }, 0);
    }
    return 0;
  }

  calculateTotal() {
    if (this.cartItems) {
      return this.calculateSubtotal() + this.calculateFees();
    }
    return 0;
  }

  calculateDiscounts() {
    if (this.cartItems) {
      return this.cartItems.reduce((prev, currCartItem) => {
        if (currCartItem.discounts.length) {
          return prev + currCartItem.calculateDiscount();
        }
        return prev;
      }, 0);
    }
    return 0;
  }

  getTaxes() {
    const hash = new Map<string, number>();
    if (this.cartItems) {
      this.cartItems.forEach((item) => {
        item.fees.forEach((fee) => {
          if (fee.tax) {
            if (hash.has(fee.title)) {
              hash.set(
                fee.title,
                (hash.get(fee.title) ?? 0) +
                  calculateAmount(
                    fee.amount,
                    item.calculateSubtotal(),
                    item.quantity
                  )
              );
            } else {
              hash.set(
                fee.title,
                calculateAmount(
                  fee.amount,
                  item.calculateSubtotal(),
                  item.quantity
                )
              );
            }
          }
        });
      });
    }

    const taxes = [];
    // @ts-ignore
    for (const [key, value] of hash.entries()) {
      taxes.push({
        title: key as string,
        amount: value as number,
      });
    }
    return taxes;
  }

  add({
    product,
    departments,
    groups,
    discounts,
    fees,
    quantity,
  }: {
    product: Product;
    departments: Department[];
    groups: Group[];
    discounts: Amount[];
    fees: Fee[];
    quantity: number;
  }) {
    const lastCartItem = this.cartItems.slice(-1)[0];
    if (
      lastCartItem &&
      lastCartItem.product.barcode === product.barcode &&
      !lastCartItem.discounts.length &&
      !lastCartItem.product.variable
    ) {
      lastCartItem.quantity += quantity;
    } else {
      const item = new CartItem({
        product: product,
        departments: departments,
        discounts: discounts,
        groups: groups,
        fees: fees,
        quantity: quantity,
        returnQuantity: 0,
      });
      this.cartItems.push(item);
    }

    this.computeMixAndMatchDiscounts();
  }

  computeMixAndMatchDiscounts() {
    // Generate delta table.
    this.deltaTable = generateDeltaTable(this.cartItems);

    // Keep track of group counter.
    const groupCount = new Map<string, number>();

    // Apply discount.
    this.cartItems.forEach((item) => {
      // We only support for 1 to 1 product to group mapping & only allow mix and match enablement.
      if (item.groups[0] && item.groups[0].mixmatch) {
        const groupKey = item.groups[0].key;
        const deltaTableKey = `${groupKey}:${item.product.key}`;
        const discountArray = this.deltaTable.get(deltaTableKey)!;
        const discountArraySize = discountArray.length - 1; // 0 index must be skipped as we don't apply discounts with 0 count.

        // Add up total discounts.
        let count =
          ((groupCount.get(groupKey) ?? 0) % discountArraySize) + item.quantity;
        let totalDiscount = 0;
        while (count > discountArraySize) {
          totalDiscount += discountArray[discountArraySize];
          count -= discountArraySize;
        }
        totalDiscount += discountArray[count];

        // Apply discount.
        if (totalDiscount > 0) {
          item.discounts = [
            new Amount({
              number: totalDiscount,
              unit: 'AmountUnit.Dollar',
            }),
          ];
        } else {
          item.discounts = [];
        }

        // Update total item quantity for mix and match.
        groupCount.set(
          groupKey,
          (groupCount.get(groupKey) ?? 0) + item.quantity
        );
      }
    });
  }

  size() {
    return this.cartItems
      .map((item) => item.quantity)
      .reduce((prev, curr) => prev + curr, 0);
  }

  // TODO: change behaviour of customer display with Sale instead. Cart is for temp.
  toFirestore() {
    return JSON.parse(JSON.stringify(this));
  }

  toFirestoreDisplayMode() {
    // Remove department as this object is too big for a single Document to hold.
    const cartObject = JSON.parse(JSON.stringify(this));
    cartObject?.cartItems?.forEach((item: { departments: any[] }) => {
      item.departments = [];
    });
    return cartObject;
  }

  /**
   * Returns total loyalty exemptions in points ($1 === 100 points). It is used to subtract it from total points earned in
   * createTransaction(...)
   */
  calculateLoyaltyExemption() {
    if (this.cartItems) {
      return _.round(
        this.cartItems.reduce(
          (prev, curr) => prev + curr.calculateLoyaltyExemption(),
          0
        ),
        0
      );
    }
    return 0;
  }
}
