import { Col, Divider, Layout, Row, Space } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';

const DetailLayout = ({
  pageHeader,
  mainContent,
  sideContent,
  footerContent,
}: {
  pageHeader: JSX.Element;
  mainContent: JSX.Element;
  sideContent?: JSX.Element;
  footerContent: JSX.Element;
}) => {
  return (
    <Layout>
      {pageHeader}
      <Content style={{ padding: '0px 24px' }}>
        <Row gutter={[16, 16]}>
          <Col flex='100 0 auto'>
            <Space
              size='large'
              direction='vertical'
              style={{ minWidth: '300px', width: '100%' }}
            >
              {mainContent}
            </Space>
          </Col>
          {sideContent && (
            <Col flex='1 0 auto'>
              <Space
                size='large'
                direction='vertical'
                style={{ minWidth: '300px', width: '100%' }}
              >
                {sideContent}
              </Space>
            </Col>
          )}
        </Row>
      </Content>
      <Footer style={{ padding: '24px 24px' }}>
        <Divider></Divider>
        {footerContent}
      </Footer>
    </Layout>
  );
};

export { DetailLayout };
