export interface IAmount {
  number?: number;
  unit?: 'AmountUnit.Percentage' | 'AmountUnit.Dollar' | 'AmountUnit.Quantity';
}

export class Amount implements IAmount {
  readonly number: number;
  readonly unit:
    | 'AmountUnit.Percentage'
    | 'AmountUnit.Dollar'
    | 'AmountUnit.Quantity';

  constructor(data?: IAmount) {
    this.number = data?.number ?? 0;
    this.unit = data?.unit ?? 'AmountUnit.Percentage';
  }
}

const calculateAmount = (amount: Amount, price: number, quantity: number) => {
  if (amount.unit === 'AmountUnit.Dollar') {
    return amount.number * quantity;
  } else if (amount.unit === 'AmountUnit.Percentage') {
    return amount.number * price;
  } else {
    return 0;
  }
};

export { calculateAmount };
