import { DocumentReference } from 'firebase/firestore';
import { DocumentModel, FirestoreTimestamp } from './utils/document-model';
import {
  documentReference2String,
  string2DocumentReference,
} from './utils/document-reference-serializer';

export interface IPurchaseOrderItem {
  accepted?: number;
  canceled?: number;
  caseQuantity?: number;
  companyId?: string;
  cost?: number; // cost = price + deposits + fees (does not include taxes)
  taxes?: number;
  finalCost?: number; // finalCost = cost + taxes
  product?: DocumentReference | string;
  purchaseOrderId?: string;
  quantity?: number;
  rejected?: number;
  storeId?: string;
  unreceived?: number;
  createdTime?: FirestoreTimestamp;
  updatedTime?: FirestoreTimestamp;
}

export class PurchaseOrderItem
  extends DocumentModel<IPurchaseOrderItem>
  implements IPurchaseOrderItem
{
  readonly accepted: number;
  readonly canceled: number;
  readonly caseQuantity: number;
  readonly companyId: string;
  readonly cost: number;
  readonly taxes: number;
  readonly finalCost: number;
  readonly product: string;
  readonly purchaseOrderId: string;
  readonly quantity: number;
  readonly rejected: number;
  readonly storeId: string;
  readonly unreceived: number;
  readonly createdTime?: FirestoreTimestamp;
  readonly updatedTime?: FirestoreTimestamp;

  constructor(id: string, data: IPurchaseOrderItem) {
    super(id, data);
    this.accepted = data?.accepted ?? 0;
    this.caseQuantity = data?.caseQuantity ?? 1;
    this.canceled = data?.canceled ?? 0;
    this.companyId = data?.companyId ?? '';
    this.cost = data?.cost ?? 0;
    this.taxes = data?.taxes ?? 0;
    this.finalCost = data?.finalCost ?? 0;
    this.product = documentReference2String(data.product);
    this.purchaseOrderId = data?.purchaseOrderId ?? '';
    this.quantity = data?.quantity ?? 0;
    this.rejected = data?.rejected ?? 0;
    this.storeId = data?.storeId ?? '';
    this.unreceived = data?.unreceived ?? 0;
    this.createdTime = data.createdTime;
    this.updatedTime = data.updatedTime;
  }

  toFirestore() {
    // Do not update 'unreceived' field directly! This gets calculated on Cloud Funcitons after an update has been persisted!
    delete this._modifiedProperties.unreceived;

    const iPurchaseOrderItem = super.toFirestore();

    if (this._modifiedProperties.product) {
      iPurchaseOrderItem.product = string2DocumentReference(this.product);
    }

    return iPurchaseOrderItem;
  }
}
