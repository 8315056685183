import { Alert } from "antd";
interface UpgradeAccountProps {
  title: string;
}
const UpgradeAccount = ({ title }: UpgradeAccountProps) => {
  return (
    <Alert
      message="Please upgrade your account!"
      description={`'${title}' is unavailable for your account type.`}
      type="warning"
      showIcon
    />
  );
};

export default UpgradeAccount;
