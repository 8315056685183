import { Button, Result, Space, Typography } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

const GotoMyCompany = () => {
  const history = useHistory();
  return (
    <Result
      status="success"
      title="Successfully created!"
      extra={[
        <Button
          type="primary"
          key="console"
          onClick={() => {
            history.push("/");
          }}
        >
          Enter Posy
        </Button>,
      ]}
    />
  );
};

export { GotoMyCompany };
