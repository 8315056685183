import { Col, Divider, Row } from 'antd';
import { DocumentData, DocumentReference } from 'firebase/firestore';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../firebase/Auth';
import { Cart } from '../../../models/cart';
import RouteLeavingGuard from '../../common/guard/RouteLeavingGuard';
import { useUnbeforeUnloadDialog } from '../../common/use/useDirty';
import { ProductCart } from './ProductCart';
import { ProductSelector } from './ProductSelector';
// import barcode from './barcode.png';

// Set mobile innerHeight https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
// Use this instead of maxHeight: 'calc(100vh - 48px - 64px)'
// 48px and 64px are from top bar (header) and margins and paddings for body.
const maxHeight = 'calc((var(--vh, 1vh) * 100) - 48px - 64px)';

const focusBarcodeScanner = (e: KeyboardEvent) => {
  // @ts-ignore
  if (e.target.tagName !== 'INPUT') {
    var input = document.getElementById(
      'barcode-input-area'
    ) as HTMLInputElement;
    if (input) {
      input.focus();
      input.value = e.key;
      e.preventDefault();
    }
  }
};

const Register = ({ drawerId }: { drawerId: string }) => {
  const history = useHistory();
  const { currentStore, currentCompany } = useContext(AuthContext);
  const [isDirty, setIsDirty] = useState(false);
  const [displayRef, setDisplayRef] =
    useState<DocumentReference<DocumentData>>();
  const [cart, setCart] = useState<Cart>(
    new Cart({
      feesInclusive: currentStore?.feesInclusive,
      displayId: displayRef?.id,
    })
  );

  useUnbeforeUnloadDialog(isDirty);

  useEffect(() => {
    const setInnerHeight = () => {
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight / 100}px`
      );
      // There's a bug on iOS landscape and vertical change where innerHeight is delayed. Use setTimeout
      // to set innerHeight again after 100ms to ensure correct height.
      setTimeout(() => {
        document.documentElement.style.setProperty(
          '--vh',
          `${window.innerHeight / 100}px`
        );
      }, 100);
    };
    setInnerHeight();

    window.addEventListener('resize', setInnerHeight);
    return () => {
      window.removeEventListener('resize', setInnerHeight);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('keypress', focusBarcodeScanner);
    return () => {
      window.removeEventListener('keypress', focusBarcodeScanner);
    };
  }, []);

  // Generate new display id
  // TODO: Save displayId to cookie and reuse the same displayId
  // useEffect(() => {
  //   if (currentCompany?.key && currentStore?.key) {
  //     const displayRef = doc(
  //       collection(
  //         db,
  //         `companies/${currentCompany.key}/stores/${currentStore.key}/carts`
  //       )
  //     );
  //     setDisplayRef(displayRef);
  //   }
  // }, [currentCompany, currentStore]);

  useEffect(() => {
    // Stream cart to display reference
    // if (!cart.displayId) {
    //   initCart();
    // } else if (cart && cart.displayId && displayRef) {
    //   displayRef.set(cart.toFirestore());
    // }

    if (cart.cartItems.length) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [cart, displayRef]);

  // const initCart = () => {
  //   setCart(
  //     new Cart({
  //       feesInclusive: currentStore?.feesInclusive,
  //       displayId: displayRef?.id,
  //     })
  //   );
  // };

  const initCart = useCallback(() => {
    setCart(
      new Cart({
        feesInclusive: currentStore?.feesInclusive,
        displayId: displayRef?.id,
      })
    );
  }, [currentStore, displayRef]);

  // Prevent child component from rerendering as setCart is initialized once.
  const memoProductSelector = useMemo(
    () => <ProductSelector setCart={setCart} maxHeight={maxHeight} />,
    [setCart]
  );

  return (
    <>
      <RouteLeavingGuard
        when={isDirty}
        navigate={(path) => {
          history.push(path);
        }}
        shouldBlockNavigation={(location) => {
          return true;
        }}
      />
      <div style={{ height: '100%' }}>
        <Row style={{ height: '100%' }}>
          <Col
            style={{
              flex: '3 3 100px',
            }}
          >
            {memoProductSelector}
          </Col>
          <Col>
            <Divider type='vertical' style={{ height: '100%' }} />
          </Col>
          <Col flex={2}>
            <ProductCart
              drawerId={drawerId}
              cart={cart}
              setCart={setCart}
              initCart={initCart}
              maxHeight={maxHeight}
            />
          </Col>
        </Row>
        <iframe
          style={{ display: 'none' }}
          id='print-iframe'
          width='100%'
          height='100%'
        />
      </div>
    </>
  );
};

export default Register;
