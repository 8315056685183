import { Button, Form, Input, PageHeader, Select, Typography } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { httpsCallable } from 'firebase/functions';
import { useContext, useState } from 'react';
import { AuthContext } from '../../firebase/Auth';
import { fn } from '../../firebase/Fb';
const { Text } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 16, span: 16 },
};

const CompanyForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { reloadCurrentUser } = useContext(AuthContext);
  // const history = useHistory();

  const handleSubmit = async (values: Store) => {
    try {
      setLoading(true);
      setError('');
      const fnUpdateUserInfo = httpsCallable(fn, 'createCompany');
      await fnUpdateUserInfo({
        name: values.name,
        address: {
          address: values.street,
          city: values.city,
          province: values.province,
          postalcode: values.postalcode,
        },
      });
      reloadCurrentUser();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageHeader title='Company Info'>
      <Form {...layout} onFinish={handleSubmit}>
        <Form.Item
          name='name'
          label='Name'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          name='street'
          label='Street'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          name='city'
          label='City'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          name='province'
          label='Province'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option value='AB'>Alberta</Select.Option>
            <Select.Option value='BC'>British Columbia</Select.Option>
            <Select.Option value='SK'>Saskatchewan</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='postalcode'
          label='Postal Code'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button htmlType='submit' type='primary' block loading={loading}>
            Next
          </Button>
        </Form.Item>
        <Text type='danger'>{error}</Text>
      </Form>
    </PageHeader>
  );
};

export { CompanyForm };
