import { Descriptions, InputNumber } from 'antd';
import { Product } from '../../models/product';
import { ppFullSize, ppPrice } from '../../utils/Formatter';

const ProductTransfer = ({
  product,
  sizeQuantity,
  setSizeQuantity,
}: {
  product?: Product;
  sizeQuantity: number;
  setSizeQuantity: React.Dispatch<React.SetStateAction<number>>;
}) => {
  if (!product) {
    return <></>;
  }
  return (
    <Descriptions bordered size='small' style={{ marginTop: 16 }}>
      <Descriptions.Item label='Title' span={2}>
        {product.title}
      </Descriptions.Item>
      <Descriptions.Item label='In stock' span={1}>
        {product.quantity}
      </Descriptions.Item>
      <Descriptions.Item label='Size' span={1}>
        {ppFullSize(product.size)}
      </Descriptions.Item>
      <Descriptions.Item label='Price' span={1}>
        {ppPrice(product.price)}
      </Descriptions.Item>
      <Descriptions.Item label='Total product cost' span={1}>
        {ppPrice(product.totalCost)}
      </Descriptions.Item>
      <Descriptions.Item label='Singles in a unit' span={3}>
        <InputNumber
          precision={0}
          min={1}
          value={sizeQuantity}
          onChange={setSizeQuantity}
        />
      </Descriptions.Item>
    </Descriptions>
  );
};

export { ProductTransfer };
