import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import { Product } from '../../models/product';
import { Size } from '../../models/size';
import { ppPrice } from '../../utils/Formatter';
import { HighlighterStyle } from '../common/detail/cards/card-styles';
import { ProductPopover } from '../inventory/product/popover/ProductPopover';
const { Text } = Typography;

const LabelColumn = ({
  stringFilter,
}: {
  stringFilter: string;
}): ColumnsType<Product> => {
  return [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder='Search'
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type='primary'
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size='small'
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  if (clearFilters) {
                    clearFilters();
                  }
                  confirm();
                }}
                size='small'
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      onFilter: (value, record) => {
        if (typeof value === 'string') {
          return record.title
            .toLowerCase()
            .includes(_.trim(value.toLowerCase()));
        }
        return true;
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      render: (title: string, record) => (
        <ProductPopover
          showTitle
          productId={record.key}
          href={`./products/${record.key}`}
        >
          <Typography.Link strong>
            <Highlighter
              highlightStyle={HighlighterStyle}
              searchWords={[stringFilter]}
              textToHighlight={title}
            />
          </Typography.Link>
        </ProductPopover>
      ),
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      ellipsis: true,
      render: (size: Size, record) => <Text>{size.ppFullSize()}</Text>,
    },
    {
      title: 'Barcode',
      dataIndex: 'barcode',
      key: 'barcode',
      ellipsis: true,
      responsive: ['xl'],
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder='Search'
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type='primary'
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size='small'
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  if (clearFilters) {
                    clearFilters();
                  }
                  confirm();
                }}
                size='small'
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      onFilter: (value, record) => {
        if (typeof value === 'string') {
          return record.barcode
            .toLowerCase()
            .includes(_.trim(value.toLowerCase()));
        }
        return true;
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      render: (barcode: string) => (
        <Highlighter
          highlightStyle={HighlighterStyle}
          searchWords={[stringFilter]}
          textToHighlight={barcode}
        />
      ),
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      ellipsis: true,
      responsive: ['xl'],
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder='Search'
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type='primary'
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size='small'
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  if (clearFilters) {
                    clearFilters();
                  }
                  confirm();
                }}
                size='small'
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      onFilter: (value, record) => {
        if (typeof value === 'string') {
          return record.sku.toLowerCase().includes(_.trim(value.toLowerCase()));
        }
        return true;
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      render: (sku: string) => (
        <Highlighter
          highlightStyle={HighlighterStyle}
          searchWords={[stringFilter]}
          textToHighlight={sku}
        />
      ),
    },
    {
      title: 'Retail Price',
      dataIndex: 'price',
      key: 'price',
      ellipsis: true,
      align: 'center',
      width: 100,
      responsive: ['sm'],
      render: (value: number) => ppPrice(value),
    },
  ];
};

export { LabelColumn };
