import { Badge, Descriptions, Popover } from 'antd';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ProductContext } from '../../../../providers/base/ProductProvider';
import { ppPrice } from '../../../../utils/Formatter';

const ProductPopover = ({
  productId,
  href,
  children,
  showTitle,
}: {
  productId: string;
  href: string;
  children: JSX.Element;
  showTitle?: boolean;
}) => {
  return (
    <Popover
      destroyTooltipOnHide={true}
      content={
        <Content
          productId={productId}
          href={href}
          showTitle={showTitle}
        ></Content>
      }
      trigger='click'
    >
      {children}
    </Popover>
  );
};

const Content = ({
  productId,
  href,
  showTitle,
}: {
  productId: string;
  href: string;
  showTitle?: boolean;
}) => {
  const { snapshots: productSnapshots } = useContext(ProductContext);
  const productSnapshot = productSnapshots.find(
    (snapshot) => snapshot.ref.id === productId
  );
  const product = productSnapshot?.data();

  if (!product) {
    return <>Product not found.</>;
  }

  return (
    <Descriptions
      bordered
      style={{ maxWidth: 500 }}
      size='small'
      title={showTitle ? <Link to={href}>{product?.title}</Link> : undefined}
    >
      <Descriptions.Item label='Status' span={3}>
        <Badge
          status={product.disabled ? 'warning' : 'processing'}
          text={product.disabled ? 'Disabled' : 'Active'}
        />
      </Descriptions.Item>
      <Descriptions.Item label='SKU' span={3}>
        {product.sku}
      </Descriptions.Item>
      <Descriptions.Item label='Barcode' span={3}>
        {product.barcode}
      </Descriptions.Item>
      <Descriptions.Item label='Retail price' span={3}>
        {ppPrice(product.price)}
      </Descriptions.Item>
      <Descriptions.Item label='Size' span={3}>
        {product.size.ppFullSize()}
      </Descriptions.Item>
      <Descriptions.Item label='In stock' span={3}>
        {product.quantity}
      </Descriptions.Item>
      <Descriptions.Item label='Total value' span={3}>
        {ppPrice(product.totalCost)}
      </Descriptions.Item>
    </Descriptions>
  );
};

export { ProductPopover };
