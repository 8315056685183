import { notification } from 'antd';
import {
  FieldPath,
  limit,
  onSnapshot,
  orderBy,
  OrderByDirection,
  query,
  QueryDocumentSnapshot,
  where,
  WhereFilterOp,
} from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../firebase/Auth';
import {
  CollectionRef,
  DocumentRef,
  GenericRepository,
} from '../../../firebase/db';

export interface Repository<T> {
  collection: () => CollectionRef<T>;
  document: (documentId: string) => DocumentRef<T>;
}

interface WhereClause {
  fieldPath: string | FieldPath;
  opStr: WhereFilterOp;
  value: any;
}

interface OrderByClause {
  fieldPath: string | FieldPath;
  directionStr?: OrderByDirection | undefined;
}

interface ProviderProps {
  children: React.ReactNode;
  subCollectionId?: string;
  where?: WhereClause;
  orderBy?: OrderByClause;
  limit?: number;
}

interface GenericContextProps<T> {
  snapshots: QueryDocumentSnapshot<T>[];
  isLoading: boolean;
  repository?: Repository<T>;
}

const createGenericContext = <T,>() => {
  return React.createContext<GenericContextProps<T>>({
    snapshots: [],
    isLoading: true,
    repository: undefined,
  });
};

const createGenericProvider = <T,>(
  genericRepository: GenericRepository<T>,
  ResourceContext: React.Context<GenericContextProps<T>>,
  className: string // For debugging purposes (logging). PLEASE DO NOT RELY ON THIS VARIABLE.
) => {
  const GenericProvider: React.FC<ProviderProps> = ({
    children,
    subCollectionId, // Optional sub-collection id i.e. PurchaseOrderItems collection within PurchaseOrders.
    where: whereClause,
    orderBy: orderByClause,
    limit: limitClause,
  }) => {
    const { currentCompany, currentStore } = useContext(AuthContext);
    const [repository, setRepository] = useState<Repository<T>>();
    const [snapshots, setSnapshots] = useState<QueryDocumentSnapshot<T>[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
      if (
        className !== 'Company' &&
        className !== 'Employee' &&
        className !== 'Payroll' &&
        className !== 'PayrollEmployee' &&
        (!currentCompany || !currentStore)
      ) {
        return console.warn(
          'Company or store context is empty upon initializing stream. Make sure to ensure that the AuthProvider is setup correctly.'
        );
      }

      // Set model's repository context.
      const repository: Repository<T> = {
        collection: genericRepository.collection(
          currentCompany?.key ?? 'wrong-company',
          currentStore?.key ?? 'wrong-store',
          subCollectionId
        ),
        document: genericRepository.document(
          currentCompany?.key ?? 'wrong-company',
          currentStore?.key ?? 'wrong-store',
          subCollectionId
        ),
      };
      setRepository(repository);

      setIsLoading(true);

      // Query builder.
      let q = whereClause
        ? query(
            repository.collection(),
            where(whereClause.fieldPath, whereClause.opStr, whereClause.value)
          )
        : repository.collection();
      q = orderByClause
        ? query(q, orderBy(orderByClause.fieldPath, orderByClause.directionStr))
        : q;
      q = limitClause ? query(q, limit(limitClause)) : q;

      const unsubscribe = onSnapshot(q, {
        next: (collectionSnapshot) => {
          console.warn(
            `[Generic provider] Snapshot listener obtained ${className} collection size of ${collectionSnapshot.size}.`
          );
          const resources = collectionSnapshot.docs.map((documentSnapshot) => {
            return documentSnapshot;
          });
          setSnapshots(resources);
          setIsLoading(false);
        },
        error: (error) => {
          notification['error']({
            message: 'Database Error',
            description: error.toString(),
          });
          setIsLoading(false);
        },
      });

      return () => {
        console.warn(
          `[Generic provider] Snapshot listener destroyed ${className} snapshots.`
        );
        unsubscribe();
      };
    }, [
      currentCompany,
      currentStore,
      subCollectionId,
      limitClause,
      // orderByClause, // Todo: bug where orderByClause in provider causes re-render for browser back button (dirty state enabled)
      // whereClause, // Todo: same as above
    ]);

    return (
      <ResourceContext.Provider
        value={{
          snapshots: snapshots,
          isLoading: isLoading,
          repository: repository,
        }}
      >
        {children}
      </ResourceContext.Provider>
    );
  };

  return GenericProvider;
};

export { createGenericContext, createGenericProvider };
