import { masterRepository } from '../../firebase/db';
import { Customer } from '../../models/customer';
import {
  createGenericContext,
  createGenericProvider,
} from './generics/GenericContextProvider';

const CustomerContext = createGenericContext<Customer>();
const CustomerProvider = createGenericProvider<Customer>(
  masterRepository.customer,
  CustomerContext,
  'Customer'
);

export { CustomerContext, CustomerProvider };
