import { personas } from '@dicebear/collection';
import { createAvatar } from '@dicebear/core';
import { Address, IAddress } from './address';
import { Bank, IBank } from './bank';
import { Date, IDate } from './date';
import { ISplitDeposit, SplitDeposit } from './split-deposit';
import { DocumentModel, FirestoreTimestamp } from './utils/document-model';
import { IWage, Wage } from './wage';

export enum EmployeeStatus {
  Active = 'Active',
  Terminated = 'Terminated',
}

interface IEmployee {
  address?: IAddress;
  bank?: IBank;
  bankSecondary?: IBank;
  cloverEmployeeId?: string;
  cpp?: boolean;
  dentalBenefitsCode?: string;
  dob?: IDate;
  eft?: boolean;
  eftSecondary?: boolean;
  ei?: boolean;
  email?: string;
  endDate?: IDate;
  firstName?: string;
  lastName?: string;
  phone?: string;
  position?: string;
  sin?: string;
  startDate?: IDate;
  status?: EmployeeStatus;
  splitDeposit?: ISplitDeposit;
  vacationPayout?: boolean; // false = no vacation, true = vacation
  vacationPayoutToBalance?: boolean; // false = payout, true = accrual
  wage?: IWage;
  createdTime?: FirestoreTimestamp;
  updatedTime?: FirestoreTimestamp;
}

export class Employee extends DocumentModel<IEmployee> implements IEmployee {
  readonly address: Address;
  readonly bank: Bank;
  readonly bankSecondary: Bank;
  readonly cloverEmployeeId: string;
  readonly cpp: boolean;
  readonly dentalBenefitsCode: string;
  readonly dob: Date;
  readonly eft: boolean;
  readonly eftSecondary: boolean;
  readonly ei: boolean;
  readonly email: string;
  readonly endDate: Date;
  readonly firstName: string;
  readonly lastName: string;
  readonly phone: string;
  readonly position: string;
  readonly sin: string;
  readonly startDate: Date;
  readonly status: EmployeeStatus;
  readonly splitDeposit: SplitDeposit;
  readonly vacationPayout: boolean;
  readonly vacationPayoutToBalance: boolean;
  readonly wage: Wage;
  readonly createdTime?: FirestoreTimestamp;
  readonly updatedTime?: FirestoreTimestamp;

  constructor(id: string, data: IEmployee) {
    super(id, data);
    this.address = new Address(data.address);
    this.bank = new Bank(data.bank);
    this.bankSecondary = new Bank(data.bankSecondary);
    this.cloverEmployeeId = data.cloverEmployeeId ?? '';
    this.cpp = data.cpp ?? true;
    this.dentalBenefitsCode = data.dentalBenefitsCode ?? '1';
    this.dob = new Date(data.dob);
    this.eft = data.eft ?? false;
    this.eftSecondary = data.eftSecondary ?? false;
    this.ei = data.ei ?? true;
    this.email = data.email ?? '';
    this.endDate = new Date(data.endDate);
    this.firstName = data.firstName ?? '';
    this.lastName = data.lastName ?? '';
    this.phone = data.phone ?? '';
    this.position = data.position ?? '';
    this.sin = data.sin ?? '';
    this.startDate = new Date(data.startDate);
    this.status = data.status ?? EmployeeStatus.Active;
    this.splitDeposit = new SplitDeposit(data.splitDeposit);
    this.vacationPayout = data.vacationPayout ?? true;
    this.vacationPayoutToBalance = data.vacationPayoutToBalance ?? false;
    this.wage = new Wage(data.wage);
    this.createdTime = data.createdTime;
    this.updatedTime = data.updatedTime;
  }

  getFullname() {
    return `${this.lastName.toLocaleUpperCase()}, ${this.firstName.toUpperCase()} `;
  }
  getInitials() {
    return `${this.firstName[0] ?? ''}${this.lastName[0] ?? ''}`;
  }
  getDefaultPicUrl() {
    return generatePersonas(this.key);
  }
}

const generatePersonas = (key: string) => {
  const avatar = createAvatar(personas, {
    seed: key,
    mouth: ['smile', 'surprise', 'bigSmile', 'smirk'],
  });
  return avatar.toDataUriSync();
};

export { generatePersonas };
