import { PictureOutlined } from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Card,
  Col,
  Layout,
  List,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useRef, useState } from 'react';
import { fn } from '../firebase/Fb';
import { Cart } from '../services/Cart';
import { StreamBridge, StreamCart } from '../services/CartService';
import { ppPrice } from '../utils/Formatter';

var QRCode = require('qrcode.react');

const { Content } = Layout;
const { Text, Title } = Typography;

const TotalCartArea = ({ cart }: { cart?: Cart }) => {
  return (
    <List>
      <List.Item>
        <List.Item.Meta title='Subtotal' />
        <Space>
          <Text strong>{ppPrice(cart?.calculateSubtotal() ?? 0)}</Text>
        </Space>
      </List.Item>
      <List.Item
        style={{
          display: (cart?.calculateFees('deposit') ?? 0) > 0 ? '' : 'none',
        }}
      >
        <List.Item.Meta title='Deposit' />
        <Space>
          <Text strong>{ppPrice(cart?.calculateFees('deposit') ?? 0)}</Text>
        </Space>
      </List.Item>
      <List.Item
        style={{
          display: (cart?.calculateFees('eco') ?? 0) > 0 ? '' : 'none',
        }}
      >
        <List.Item.Meta title='Eco-fee' />
        <Space>
          <Text strong>{ppPrice(cart?.calculateFees('eco') ?? 0)}</Text>
        </Space>
      </List.Item>
      <List.Item
        style={{
          display: (cart?.calculateFees('custom') ?? 0) > 0 ? '' : 'none',
        }}
      >
        <List.Item.Meta title='Fees' />
        <Space>
          <Text strong>{ppPrice(cart?.calculateFees('custom') ?? 0)}</Text>
        </Space>
      </List.Item>
      {cart?.getTaxes().map((tax) => (
        <List.Item key={tax.title}>
          <List.Item.Meta title={tax.title} />
          <Space>
            <Text strong>{ppPrice(tax.amount)}</Text>
          </Space>
        </List.Item>
      ))}
      <List.Item>
        <List.Item.Meta title='Total' />
        <Space>
          <Title level={4}>{ppPrice(cart?.calculateTotal() ?? 0)}</Title>
        </Space>
      </List.Item>
    </List>
  );
};

const Carts: React.FC = () => {
  const [error, setError] = useState('');
  const [cartPath, setCartPath] = useState('');
  const [cart, setCart] = useState<Cart>();
  const [qrCode, setQrCode] = useState('');
  const pageEndRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    const getBridgePath = httpsCallable(fn, 'getBridgePath');
    getBridgePath().then((result) => {
      const data = result.data as { path: string };
      if (data?.path) {
        setQrCode(data.path);
      }
    });
  }, []);

  useEffect(() => {
    const path = qrCode;
    const unsubscribe = StreamBridge(path, {
      next: (snapshot: any) => {
        setCartPath(snapshot.data().path);
      },
      error: () => {
        setError('Fail to retrieve cart path from the database.');
      },
    });
    return unsubscribe;
  }, [qrCode]);

  useEffect(() => {
    const unsubscribe = StreamCart(cartPath, {
      next: (snapshot: any) => {
        setCart(new Cart(snapshot.data()));
        pageEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
      },
      error: () => {
        setError('Fail to retrieve cart from the database.');
      },
    });
    return unsubscribe;
  }, [cartPath, pageEndRef]);

  if (cartPath) {
    return (
      <Layout style={{ minHeight: '100%' }}>
        <Content style={{ padding: '50px' }}>
          <Row justify='space-between' align='bottom'>
            <Col span={14}>
              <Card>
                <List
                  itemLayout='horizontal'
                  dataSource={cart?.cartItems}
                  renderItem={(cartItem) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Badge count={cartItem.quantity}>
                            <Avatar
                              shape='square'
                              size='large'
                              icon={<PictureOutlined />}
                            />
                          </Badge>
                        }
                        title={cartItem?.product?.title}
                        description={cartItem?.product?.description}
                      />
                      <Space direction='vertical' align='end' size={0}>
                        <Text strong>
                          {ppPrice(cartItem.calculateSubtotal())}
                        </Text>
                        {!!cartItem.discounts.length && (
                          <Text type='secondary' delete>
                            {ppPrice(
                              (cartItem?.product?.price ?? 0) *
                                cartItem.quantity
                            )}
                          </Text>
                        )}
                      </Space>
                    </List.Item>
                  )}
                />
                <div ref={pageEndRef}></div>
              </Card>
            </Col>
            <Col span={10}>
              <Card style={{ marginLeft: '50px' }}>
                <TotalCartArea cart={cart}></TotalCartArea>
              </Card>
            </Col>
          </Row>
        </Content>
        {error}
      </Layout>
    );
  } else if (qrCode) {
    return (
      <QRCode
        value={qrCode}
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    );
  }
  return (
    <Spin
      size='large'
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  );
};

export { Carts, TotalCartArea };
