import { Address, IAddress } from './address';
import { DocumentModel, FirestoreTimestamp } from './utils/document-model';

interface ISupplier {
  address?: IAddress;
  name?: string;
  createdTime?: FirestoreTimestamp;
  updatedTime?: FirestoreTimestamp;
}

export class Supplier extends DocumentModel<ISupplier> implements ISupplier {
  readonly address: Address;
  readonly name: string;
  readonly createdTime?: FirestoreTimestamp;
  readonly updatedTime?: FirestoreTimestamp;

  constructor(id: string, data: ISupplier) {
    super(id, data);
    this.address = new Address(data.address);
    this.name = data.name ?? '';
  }

  toFirestore() {
    return super.toFirestore();
  }
}
