import _ from 'lodash';
import { Supplier } from '../../../models/supplier';
import { SupplierFormFields } from './supplier-form-fields';

const form2Supplier = (
  formFields: Partial<SupplierFormFields>
): Partial<Supplier> => {
  const supplier = {
    ...formFields,
  };
  return _.pick(supplier, _.keys(new Supplier('', {})));
};

export { form2Supplier };
