export interface IBank {
  accountNumber: string;
  institutionNumber: string;
  transitNumber: string;
}

export class Bank implements IBank {
  readonly accountNumber: string;
  readonly institutionNumber: string;
  readonly transitNumber: string;

  constructor(data?: IBank) {
    this.accountNumber = data?.accountNumber ?? '';
    this.institutionNumber = data?.institutionNumber ?? '';
    this.transitNumber = data?.transitNumber ?? '';
  }
}
