import { Address, IAddress } from './address';
import { Bank, IBank } from './bank';
import { Date, IDate } from './date';
import { PayPeriodType } from './payroll';
import { DocumentModel, FirestoreTimestamp } from './utils/document-model';

enum PaymentType {
  None = '',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

interface ICompany {
  address?: IAddress;
  bank?: IBank;
  configurations?: Map<string, string>;
  construction: boolean;
  destinationCode?: string;
  fileCreationId?: number;
  firstname?: string;
  lastname?: string;
  name?: string;
  institution?: string;
  originatorId?: string;
  originatorShortName?: string;
  originatorLongName?: string;
  operatingName?: string;
  profileId?: string;
  paymentType?: PaymentType;
  payPeriodLocked?: boolean;
  payPeriodStartDate?: IDate;
  payPeriodType?: PayPeriodType;
  payrollnumber?: string;
  phone?: string;
  trial?: boolean;
  createdTime?: FirestoreTimestamp;
  updatedTime?: FirestoreTimestamp;
}

export class Company extends DocumentModel<ICompany> implements ICompany {
  readonly address: IAddress;
  readonly bank: Bank;
  readonly configurations: Map<string, string>;
  readonly construction: boolean;
  readonly destinationCode: string;
  readonly fileCreationId: number;
  readonly firstname: string;
  readonly lastname: string;
  readonly name: string;
  readonly institution: string;
  readonly originatorId: string;
  readonly originatorShortName: string;
  readonly originatorLongName: string;
  readonly operatingName: string;
  readonly profileId: string;
  readonly paymentType: PaymentType;
  readonly payPeriodLocked: boolean;
  readonly payPeriodStartDate?: Date;
  readonly payPeriodType?: PayPeriodType;
  readonly payrollnumber: string;
  readonly phone: string;
  readonly trial: boolean;
  readonly createdTime?: FirestoreTimestamp;
  readonly updatedTime?: FirestoreTimestamp;

  constructor(id: string, data: ICompany) {
    super(id, data);
    this.address = new Address(data.address);
    this.bank = new Bank(data.bank);
    this.configurations = new Map(Object.entries(data.configurations ?? {})); // Todo: create a class for configuration object.
    this.construction = data.construction ?? false;
    this.destinationCode = data.destinationCode ?? '';
    this.fileCreationId = data.fileCreationId ?? 1;
    this.firstname = data.firstname ?? '';
    this.lastname = data.lastname ?? '';
    this.name = data.name ?? '';
    this.institution = data.institution ?? '';
    this.originatorId = data.originatorId ?? '';
    this.originatorShortName = data.originatorShortName ?? '';
    this.originatorLongName = data.originatorLongName ?? '';
    this.operatingName = data.operatingName ?? '';
    this.profileId = data.profileId ?? '';
    this.paymentType = data.paymentType ?? PaymentType.None;
    this.payPeriodLocked = data.payPeriodLocked ?? false;
    this.payPeriodStartDate = data.payPeriodStartDate
      ? new Date(data.payPeriodStartDate)
      : undefined;
    this.payPeriodType = data.payPeriodType;
    this.payrollnumber = data.payrollnumber ?? '';
    this.phone = data.phone ?? '';
    this.trial = data.trial ?? false;
    this.createdTime = data.createdTime;
    this.updatedTime = data.updatedTime;
  }
}
