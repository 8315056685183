import { DocumentModel, FirestoreTimestamp } from './utils/document-model';

interface ITransfer {
  reason?: string;
  type?: 'ReasonType.transfer' | 'ReasonType.payout';
  value?: number;
  createdTime?: FirestoreTimestamp;
  updatedTime?: FirestoreTimestamp;
}
export class Transfer extends DocumentModel<ITransfer> implements ITransfer {
  readonly reason: string;
  readonly type: 'ReasonType.transfer' | 'ReasonType.payout';
  readonly value: number;
  readonly createdTime?: FirestoreTimestamp;
  readonly updatedTime?: FirestoreTimestamp;

  constructor(id: string, data: ITransfer) {
    super(id, data);
    this.reason = data.reason ?? '';
    this.type = data.type ?? 'ReasonType.transfer';
    this.value = data.value ?? 0;
    this.createdTime = data.createdTime;
    this.updatedTime = data.updatedTime;
  }

  toFirestore() {
    return super.toFirestore();
  }
}
