import { masterRepository } from '../../firebase/db';
import { Department } from '../../models/department';
import {
  createGenericContext,
  createGenericProvider,
} from './generics/GenericContextProvider';

const DepartmentContext = createGenericContext<Department>();
const DepartmentProvider = createGenericProvider<Department>(
  masterRepository.department,
  DepartmentContext,
  'Department'
);

export { DepartmentContext, DepartmentProvider };
