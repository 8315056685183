import { Button, Input, List, Space } from 'antd';
import { ModalStaticFunctions } from 'antd/lib/modal/confirm';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Cart } from '../../../models/cart';
import { Product } from '../../../models/product';
import { DepartmentContext } from '../../../providers/base/DepartmentProvider';
import { FeeContext } from '../../../providers/base/FeeProvider';
import { GroupContext } from '../../../providers/base/GroupProvider';
import { ProductContext } from '../../../providers/base/ProductProvider';
import { ppPrice } from '../../../utils/Formatter';

const SearchProduct = ({
  setCart,
  destroyModal,
}: {
  setCart: React.Dispatch<React.SetStateAction<Cart>>;
  destroyModal: () => void;
}) => {
  const { snapshots: productSnapshots } = useContext(ProductContext);
  const { snapshots: groupSnapshots } = useContext(GroupContext);
  const { snapshots: feeSnapshots } = useContext(FeeContext);
  const { snapshots: departmentSnapshots } = useContext(DepartmentContext);
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    setProducts(productSnapshots.map((snap) => snap.data()));
  }, [productSnapshots]);

  useEffect(() => {
    setIsLoading(true);
    if (filter === '') {
      setFilteredProducts(products);
    } else {
      const newList = products.filter((product) => {
        return (
          product.title.toLowerCase().includes(filter.toLowerCase()) ||
          product.barcode.toLowerCase().includes(filter.toLowerCase()) ||
          product.sku.toLowerCase().includes(filter.toLowerCase())
        );
      });
      setFilteredProducts(newList);
    }
    setIsLoading(false);
  }, [products, filter]);

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Input
        onPressEnter={(e) => {
          //@ts-ignore
          setFilter(e.target.value);
        }}
        placeholder='Search by description, SKU, or EAN/UPC'
      />
      <List
        loading={isLoading}
        style={{ height: 400, overflow: 'auto' }}
        dataSource={filteredProducts}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={item.title}
              description={`Sku:${item.sku} Qty:${item.quantity}`}
            />
            <Button
              type='primary'
              onClick={() => {
                const pSnap = productSnapshots.find(
                  (snap) => snap.data().barcode === item.barcode
                );
                if (pSnap) {
                  const groups = _.compact(
                    item.groups.map((path) =>
                      groupSnapshots
                        .find((snap) => snap.ref.path === path)
                        ?.data()
                    )
                  );
                  const fees = _.compact(
                    item.fees.map((path) =>
                      feeSnapshots
                        .find((snap) => snap.ref.path === path)
                        ?.data()
                    )
                  );
                  const departments = departmentSnapshots
                    .filter((snap) =>
                      snap
                        .data()
                        .products.some((path) => path === pSnap.ref.path)
                    )
                    .map((snap) => snap.data());

                  setCart((prevCart) => {
                    const newCart = _.cloneDeep(prevCart);
                    newCart.add({
                      product: item,
                      departments: departments,
                      groups: groups,
                      fees: fees,
                      discounts: [],
                      quantity: 1,
                    });
                    return newCart;
                  });
                }
                destroyModal();
              }}
            >
              {ppPrice(item.price)}
            </Button>
          </List.Item>
        )}
      />
    </Space>
  );
};

const SearchProductDialog = (
  modal: Omit<ModalStaticFunctions, 'warn'>,
  setCart: React.Dispatch<React.SetStateAction<Cart>>
) => {
  const { destroy, update } = modal.success({});
  update({
    centered: true,
    closable: true,
    maskClosable: false,
    title: 'Search Inventory',
    okText: null,
    icon: null,
    okButtonProps: {
      style: {
        display: 'none',
      },
    },
    content: <SearchProduct setCart={setCart} destroyModal={destroy} />,
  });
};

export { SearchProductDialog };
