import { masterRepository } from '../../firebase/db';
import { Supplier } from '../../models/supplier';
import {
  createGenericContext,
  createGenericProvider,
} from './generics/GenericContextProvider';

const SupplierContext = createGenericContext<Supplier>();
const SupplierProvider = createGenericProvider<Supplier>(
  masterRepository.supplier,
  SupplierContext,
  'Supplier'
);

export { SupplierContext, SupplierProvider };
