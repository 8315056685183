import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../firebase/Auth';
import { CompanyProvider } from '../../providers/base/CompanyProvider';
import { TenantTable } from './tenants/TenantTable';

const Admin = () => {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();

  if (!currentUser?.firestore?.admin) {
    history.replace('/403');
  }

  return (
    <Layout>
      <Content style={{ margin: 32 }}>
        <Title level={3}>Tenant Management</Title>
        <CompanyProvider orderBy={{ fieldPath: 'name', directionStr: 'asc' }}>
          <TenantTable />
        </CompanyProvider>
      </Content>
    </Layout>
  );
};

export { Admin };
