import _ from 'lodash';
import moment, { Moment } from 'moment';
import { Address } from '../models/address';
import { Date } from '../models/date';
import { PaymentType } from '../models/payment';
import { Amount } from '../services/Cart';
import { ITDate } from '../services/Employee';

export interface Size {
  number: number;
  unit:
    | 'SizeUnit.CS'
    | 'SizeUnit.CT'
    | 'SizeUnit.DZ'
    | 'SizeUnit.EA'
    | 'SizeUnit.FT'
    | 'SizeUnit.G'
    | 'SizeUnit.KG'
    | 'SizeUnit.L'
    | 'SizeUnit.LB'
    | 'SizeUnit.ML'
    | 'SizeUnit.OZ'
    | 'SizeUnit.PK';
  quantity: number;
}

const PriceFormatter = new Intl.NumberFormat('en-ca', {
  style: 'currency',
  currency: 'CAD',
});

const ppAmount = (amount: Amount) => {
  if (amount.unit === 'AmountUnit.Dollar') {
    return PriceFormatter.format(amount.number);
  } else if (amount.unit === 'AmountUnit.Percentage') {
    return `${ppRoundTo2Decimal(amount.number * 100)}%`;
  } else {
    return `${ppRoundTo2Decimal(amount.number)}`;
  }
};

const ppPaymentType = (payment: PaymentType) => {
  let ppString;

  switch (payment) {
    case 'PaymentType.Cash':
      ppString = 'Cash';
      break;
    case 'PaymentType.Cheque':
      ppString = 'Cheque';
      break;
    case 'PaymentType.Credit':
      ppString = 'Credit';
      break;
    case 'PaymentType.Debit':
      ppString = 'Debit';
      break;
    case 'PaymentType.Loyalty':
      ppString = 'Loyalty';
      break;
    case 'PaymentType.AR':
      ppString = 'AR';
      break;
  }

  return ppString;
};

const ppSize = (size: Size | number) => {
  if (!size) return '';
  if (typeof size === 'number') return `${size} ML`;
  let unit: string;
  switch (size.unit) {
    case 'SizeUnit.CS':
      unit = 'CS';
      break;
    case 'SizeUnit.CT':
      unit = 'CT';
      break;
    case 'SizeUnit.DZ':
      unit = 'DZ';
      break;
    case 'SizeUnit.EA':
      unit = 'EA';
      break;
    case 'SizeUnit.FT':
      unit = 'FT';
      break;
    case 'SizeUnit.G':
      unit = 'G';
      break;
    case 'SizeUnit.KG':
      unit = 'KG';
      break;
    case 'SizeUnit.L':
      unit = 'L';
      break;
    case 'SizeUnit.LB':
      unit = 'LB';
      break;
    case 'SizeUnit.ML':
      unit = 'ML';
      break;
    case 'SizeUnit.OZ':
      unit = 'OZ';
      break;
    case 'SizeUnit.PK':
      unit = 'PK';
      break;
    default:
      unit = '';
      break;
  }

  return `${size.number} ${unit}`;
};

const ppFullSize = (size: Size | number) => {
  if (typeof size !== 'number' && size.quantity > 1) {
    return `${size.quantity} x ${ppSize(size)}`;
  }
  return ppSize(size);
};

const ppPrice = (price: number) => {
  if (price === undefined) return '';
  return PriceFormatter.format(price);
};

const ppRoundTo2Decimal = (value: number) => {
  return value.toFixed(2);
};

const roundTo5Cents = (value: number) => {
  return _.round(value * 20) / 20;
};

const fillZero = (number: number) => {
  return String('00' + number).slice(-2);
};

const fillZerosBarcode = (barcode: string) => {
  return _.padStart(barcode, 10, '0');
};

const ppTDate = (date: ITDate, format?: string) => {
  return convertTDateToMoment(date).format(format);
};

const ppDate = (date: Date, format?: string) => {
  return convertDateToMoment(date).format(format);
};

const ppMomentDateTime = (moment: Moment, format?: string) => {
  if (moment) {
    if (!format) {
      return moment.format('dddd, MMMM Do YYYY, h:mm:ss a');
    } else {
      return moment.format(format);
    }
  } else {
    return '';
  }
};

const existSpacer = (string: string) => {
  if (string) {
    return ` ${string}`;
  }
  return '';
};

const ppAddress = (address?: Address) => {
  if (address) {
    return `${address.street ? address.street : address.address}${existSpacer(
      address.city
    )}${existSpacer(address.province)}${existSpacer(address.postalcode)}`;
  }
  return '';
};

const ppPurchaseOrderIndex = (index?: number) => {
  if (index === undefined || index < 0) {
    return '';
  }
  return '#PO-' + _.padStart(_.toString(index), 4, '0');
};

const convertTDateToMoment = (date: ITDate) => {
  return moment(`${date.year}-${fillZero(date.month)}-${fillZero(date.day)}`);
};

const convertDateToMoment = (date: Date) => {
  return moment(`${date.year}-${fillZero(date.month)}-${fillZero(date.day)}`);
};

const emailRegex = /\S+@\S+\.\S+/;

// 0YYDDD
const getJulianDate = (moment: Moment) => {
  return (
    '0' +
    moment.format('YY') +
    _.padStart(_.toString(moment.dayOfYear()), 3, '0')
  );
};

// 0BBBTTTTT
const getInstitutionalId = (bank: string, branch: string) => {
  if (bank.length > 3) {
    throw new Error('Bank number cannot be greater than 3 characters');
  }
  if (branch.length > 5) {
    throw new Error('Branch number cannot be greater than 5 characters');
  }

  return '0' + _.padStart(bank, 3, '0') + _.padStart(branch, 5, '0');
};

export {
  ppSize,
  ppPrice,
  ppFullSize,
  ppRoundTo2Decimal,
  ppAmount,
  ppTDate,
  ppDate,
  ppMomentDateTime,
  ppAddress,
  ppPurchaseOrderIndex,
  convertTDateToMoment,
  convertDateToMoment,
  roundTo5Cents,
  fillZerosBarcode,
  ppPaymentType,
  emailRegex,
  existSpacer,
  getJulianDate,
  getInstitutionalId,
};
