import React from "react";

const defaultFillColor = "#2F80ED";

interface LogoProps {
  color?: string;
  style?: React.CSSProperties;
}

const PosyLogo = ({ color, style }: LogoProps) => (
  <svg
    width="45"
    height="28"
    viewBox="0 0 164 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <rect
      x="143.007"
      y="2.62589"
      width="6.99301"
      height="33.5664"
      fill={color ?? defaultFillColor}
    />
    <rect
      x="118.182"
      y="2.62589"
      width="6.99301"
      height="12.2378"
      fill={color ?? defaultFillColor}
    />
    <rect
      y="17.4825"
      width="6.99301"
      height="34.965"
      fill={color ?? defaultFillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4825 34.965C27.1378 34.965 34.965 27.1378 34.965 17.4825C34.965 7.82719 27.1378 0 17.4825 0C7.82719 0 0 7.82719 0 17.4825C0 27.1378 7.82719 34.965 17.4825 34.965ZM17.4825 27.972C23.2757 27.972 27.972 23.2757 27.972 17.4825C27.972 11.6893 23.2757 6.99301 17.4825 6.99301C11.6893 6.99301 6.99301 11.6893 6.99301 17.4825C6.99301 23.2757 11.6893 27.972 17.4825 27.972Z"
      fill={color ?? defaultFillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.7413 36.014C68.3966 36.014 76.2238 28.1868 76.2238 18.5315C76.2238 8.87614 68.3966 1.04895 58.7413 1.04895C49.0859 1.04895 41.2587 8.87614 41.2587 18.5315C41.2587 28.1868 49.0859 36.014 58.7413 36.014ZM58.7413 29.021C64.5345 29.021 69.2308 24.3247 69.2308 18.5315C69.2308 12.7383 64.5345 8.04196 58.7413 8.04196C52.9481 8.04196 48.2518 12.7383 48.2518 18.5315C48.2518 24.3247 52.9481 29.021 58.7413 29.021Z"
      fill={color ?? defaultFillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150 14.514C150 24.1693 143.571 31.9965 133.916 31.9965C124.261 31.9965 118.182 24.1693 118.182 14.514H125.175C125.175 20.3072 128.123 25.0035 133.916 25.0035C139.709 25.0035 143.007 20.3072 143.007 14.514H150Z"
      fill={color ?? defaultFillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150 35.493C150 45.1483 143.222 52.9755 133.566 52.9755C126.674 52.9755 121.247 48.9867 118.706 43.1915L125 40.5594C126.373 43.7928 129.261 45.9825 133.217 45.9825C139.01 45.9825 143.007 41.2862 143.007 35.493H150Z"
      fill={color ?? defaultFillColor}
    />
    <path
      d="M97.0975 38.007C105.122 38.007 111.363 34.5315 111.363 27.042C111.363 20.5035 106.43 18.4545 100.01 16.6713L95.1359 15.3636C90.4401 14.1748 90.0835 12.7867 90.0835 11.6573C90.0835 11.9441 90.0835 7.35665 96.5031 7.35665C100.307 7.35665 102.863 9.79371 102.863 13.1818H110.056C110.056 5.51399 104.171 0.877625 96.6814 0.877625C89.1324 0.877625 82.7723 4.52448 82.7723 11.7762C82.7723 15.3427 84.3178 19.5839 93.115 21.8427L98.2268 23.2692C101.674 24.2203 104.111 24.486 104.111 27.3392C104.111 30.549 101.437 31.528 97.2758 31.528C92.7583 31.528 89.7268 28.6154 89.6674 25.465H82.4751C82.4751 32.3601 88.3597 38.007 97.0975 38.007Z"
      fill={color ?? defaultFillColor}
    />
  </svg>
);

const PosySingleLogo = () => (
  <svg
    width="2rem"
    height="2rem"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6C0 2.68629 2.68629 0 6 0H114C117.314 0 120 2.68629 120 6V114C120 117.314 117.314 120 114 120H6C2.68629 120 0 117.314 0 114V6Z"
      fill="#2F80ED"
    />
    <rect x="27" y="53.3333" width="13.3333" height="66.6667" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.3333 86.6667C78.7428 86.6667 93.6667 71.7428 93.6667 53.3333C93.6667 34.9238 78.7428 20 60.3333 20C41.9238 20 27 34.9238 27 53.3333C27 71.7428 41.9238 86.6667 60.3333 86.6667ZM60.3333 73.3333C71.379 73.3333 80.3333 64.379 80.3333 53.3333C80.3333 42.2876 71.379 33.3333 60.3333 33.3333C49.2876 33.3333 40.3333 42.2876 40.3333 53.3333C40.3333 64.379 49.2876 73.3333 60.3333 73.3333Z"
      fill="white"
    />
  </svg>
);

const PosySingleLogo2 = () => (
  <svg
    width="2rem"
    height="2rem"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6C0 2.68629 2.68629 0 6 0H114C117.314 0 120 2.68629 120 6V114C120 117.314 117.314 120 114 120H6C2.68629 120 0 117.314 0 114V6Z"
      fill="#2F80ED"
    />
    <rect x="30" y="60.9999" width="12" height="60" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60 91C76.5685 91 90 77.5685 90 61C90 44.4315 76.5685 31 60 31C43.4315 31 30 44.4315 30 61C30 77.5685 43.4315 91 60 91ZM59.9998 79C69.9409 79 77.9998 70.9411 77.9998 61C77.9998 51.0589 69.9409 43 59.9998 43C50.0587 43 41.9998 51.0589 41.9998 61C41.9998 70.9411 50.0587 79 59.9998 79Z"
      fill="white"
    />
  </svg>
);

const PosySingleLogo3 = ({ style }: LogoProps) => (
  <svg
    width="188"
    height="192"
    viewBox="0 0 188 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <circle cx="94" cy="94" r="94" fill="#2F80ED" />
    <g filter="url(#filter0_d)">
      <path
        d="M95.9501 39.0723C95.3321 38.718 94.5723 38.7192 93.9554 39.0753L52.6471 62.9247C52.0302 63.2809 51.6493 63.9383 51.6472 64.6507L51.5035 112.076C51.5013 112.795 51.8851 113.46 52.5088 113.817L93.6524 137.406C94.2704 137.76 95.0302 137.759 95.6471 137.403L136.955 113.554C137.572 113.197 137.953 112.54 137.955 111.828L138.099 64.4019C138.101 63.683 137.717 63.0183 137.094 62.6607L95.9501 39.0723ZM132.116 108.48C132.114 109.192 131.733 109.85 131.116 110.206L95.6674 130.672C95.0505 131.029 94.2907 131.03 93.6727 130.675L58.3681 110.435C57.7445 110.077 57.3607 109.412 57.3629 108.693L57.4861 67.9983C57.4882 67.2859 57.8691 66.6285 58.486 66.2723L93.9351 45.8058C94.552 45.4496 95.3118 45.4485 95.9298 45.8028L131.234 66.0437C131.858 66.4012 132.242 67.0659 132.24 67.7848L132.116 108.48Z"
        fill="white"
      />
      <path
        d="M95.9501 39.0723C95.3321 38.718 94.5723 38.7192 93.9554 39.0753L52.6471 62.9247C52.0302 63.2809 51.6493 63.9383 51.6472 64.6507L51.5035 112.076C51.5013 112.795 51.8851 113.46 52.5088 113.817L93.6524 137.406C94.2704 137.76 95.0302 137.759 95.6471 137.403L136.955 113.554C137.572 113.197 137.953 112.54 137.955 111.828L138.099 64.4019C138.101 63.683 137.717 63.0183 137.094 62.6607L95.9501 39.0723ZM132.116 108.48C132.114 109.192 131.733 109.85 131.116 110.206L95.6674 130.672C95.0505 131.029 94.2907 131.03 93.6727 130.675L58.3681 110.435C57.7445 110.077 57.3607 109.412 57.3629 108.693L57.4861 67.9983C57.4882 67.2859 57.8691 66.6285 58.486 66.2723L93.9351 45.8058C94.552 45.4496 95.3118 45.4485 95.9298 45.8028L131.234 66.0437C131.858 66.4012 132.242 67.0659 132.24 67.7848L132.116 108.48Z"
        stroke="white"
        strokeWidth="14"
      />
    </g>
    <g filter="url(#filter1_d)">
      <rect x="59" y="111" width="5" height="10" fill="#C4C4C4" />
    </g>
    <g filter="url(#filter2_d)">
      <path
        d="M44.5 83.5H64.1875L64.5 183.5L54 179.5L44.5 174V83.5Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="16.7092"
        y="16.5945"
        width="156.184"
        height="151.289"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d"
        x="27"
        y="83"
        width="69"
        height="74"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          radius="8"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d"
        x="40.5"
        y="83.5"
        width="28"
        height="108"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const PosySingleLogo4 = () => (
  <svg
    width="123"
    height="154"
    viewBox="0 0 123 154"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M69.3334 106.667C98.7886 106.667 122.667 82.7886 122.667 53.3334C122.667 23.8782 98.7886 0 69.3334 0C39.8782 0 16 23.8782 16 53.3334C16 82.7886 39.8782 106.667 69.3334 106.667ZM69 85.0001C86.6732 85.0001 101 70.6732 101 53C101 35.3269 86.6732 21 69 21C51.3269 21 37 35.3269 37 53C37 70.6732 51.3269 85.0001 69 85.0001Z"
      fill="#2F80ED"
    />
    <g filter="url(#filter0_d)">
      <rect x="32" y="72" width="5" height="16" fill="#C4C4C4" />
    </g>
    <path d="M16 53.3333H37L37.3334 154H16V53.3333Z" fill="#2F80ED" />
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="44"
        width="69"
        height="80"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          radius="8"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export {
  PosyLogo,
  PosySingleLogo,
  PosySingleLogo2,
  PosySingleLogo3,
  PosySingleLogo4,
};
