import React, { useEffect, useState, useContext } from "react";
import { StreamProducts } from "../services/ProductService";
import { StreamDepartments } from "../services/DepartmentService";
import { StreamGroups } from "../services/GroupService";
import { StreamFees } from "../services/FeeService";
import { AuthContext } from "../firebase/Auth";
import { notification } from "antd";
import _ from "lodash";
import { Group } from "../services/Group";
import { Product } from "../services/Product";
import { Department } from "../services/Department";
import { Fee } from "../services/Fee";

interface PosProviderProps {
  children: React.ReactNode;
}

interface PosContextProps {
  products: Product[];
  isProductsLoading: boolean;
  groups: Group[];
  departments: Department[];
  fees: Fee[];
}

const PosContext = React.createContext<PosContextProps>({
  products: [],
  isProductsLoading: true,
  groups: [],
  departments: [],
  fees: [],
});

const PosProvider: React.FC<PosProviderProps> = ({ children }) => {
  const { currentCompany, currentStore } = useContext(AuthContext);
  const [products, setProducts] = useState<Product[]>([]);
  const [isProductsLoading, setIsProductsLoading] = useState(true);
  const [groups, setGroups] = useState<Group[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [fees, setFees] = useState<Fee[]>([]);

  const [fbProducts, setFbProducts] = useState<Product[]>([]);
  const [isFbProductsLoading, setIsFbProductsLoading] = useState(true);
  const [fbGroups, setFbGroups] = useState<Group[]>([]);
  const [fbDepartments, setFbDepartments] = useState<Department[]>([]);
  const [fbFees, setFbFees] = useState<Fee[]>([]);

  useEffect(() => {
    if (!currentCompany || !currentStore) return;
    setIsFbProductsLoading(true);
    const unsubscribe = StreamProducts(currentCompany?.key, currentStore?.key, {
      /**
       * Throttling products collection snapshot because CloudFunctions could potentially
       * trigger a lot of updates by creating a new version per product for bulk updates.
       */
      next: _.debounce(
        (snapshot) => {
          const products = snapshot.docs.map((snap: any) => {
            return new Product(snap);
          });
          setFbProducts(products);
          setIsFbProductsLoading(false);
        },
        1000,
        { leading: true, maxWait: 5000 }
      ),
      error: (error) => {
        notification["error"]({
          message: "Database Error",
          description: error.toString(),
        });
      },
    });
    return unsubscribe;
  }, [currentCompany, currentStore]);

  useEffect(() => {
    if (!currentCompany || !currentStore) return;
    const unsubscribe = StreamGroups(currentCompany?.key, currentStore?.key, {
      next: (snapshot) => {
        const groups = snapshot.docs.map((snap) => {
          return new Group(snap);
        });
        setFbGroups(groups);
      },
      error: (error) => {
        notification["error"]({
          message: "Database Error",
          description: error.toString(),
        });
      },
    });
    return unsubscribe;
  }, [currentCompany, currentStore]);

  useEffect(() => {
    if (!currentCompany || !currentStore) return;
    const unsubscribe = StreamDepartments(
      currentCompany?.key,
      currentStore?.key,
      {
        next: (snapshot) => {
          const departments = snapshot.docs.map((snap) => {
            return new Department(snap);
          });
          setFbDepartments(departments);
        },
        error: (error) => {
          notification["error"]({
            message: "Database Error",
            description: error.toString(),
          });
        },
      }
    );
    return unsubscribe;
  }, [currentCompany, currentStore]);

  useEffect(() => {
    if (!currentCompany || !currentStore) return;
    const unsubscribe = StreamFees(currentCompany?.key, currentStore?.key, {
      next: (snapshot) => {
        const fees = snapshot.docs.map((snap) => {
          return new Fee(snap);
        });
        setFbFees(fees);
      },
      error: (error) => {
        notification["error"]({
          message: "Database Error",
          description: error.toString(),
        });
      },
    });
    return unsubscribe;
  }, [currentCompany, currentStore]);

  useEffect(() => {
    setIsProductsLoading(true);
    const newProducts = [...fbProducts] as Product[];
    newProducts.forEach((product) => {
      product.groupObjects = _.compact(
        product.groups.map((groupRef) =>
          fbGroups.find((group) => group.key === groupRef.id)
        )
      );
      product.feeObjects = _.compact(
        product.fees.map((feeRef) =>
          fbFees.find((fee) => fee.key === feeRef.id)
        )
      );
      product.departmentObjects = _.compact([
        fbDepartments.find((department) =>
          department.products.some((pRef) => pRef.id === product.key)
        ),
      ]);
      product.grossMargin =
        ((product.price - product.casePrice / product.caseQuantity) /
          product.price) *
        100;
    });
    setProducts(newProducts);
    if (!isFbProductsLoading) {
      setIsProductsLoading(false);
    }
  }, [fbProducts, fbGroups, fbFees, fbDepartments, isFbProductsLoading]);

  useEffect(() => {
    const newGroups = [...fbGroups] as Group[];
    newGroups.forEach((group) => {
      group.productObjects = _.compact(
        products.filter((product) =>
          product.groups.some((groupRef) => groupRef.id === group.key)
        )
      );
    });
    setGroups(newGroups);
  }, [fbGroups, products]);

  useEffect(() => {
    const newDepartments = [...fbDepartments] as Department[];
    newDepartments.forEach((department) => {
      department.productObjects = _.compact(
        department.products.map((productRef) =>
          fbProducts.find((product) => product.key === productRef.id)
        )
      );
    });
    setDepartments(newDepartments);
  }, [fbDepartments, fbProducts, products]);

  useEffect(() => {
    const newFees = [...fbFees] as Fee[];
    setFees(newFees);
  }, [fbFees]);

  return (
    <PosContext.Provider
      value={{
        products,
        isProductsLoading,
        groups,
        departments,
        fees,
      }}
    >
      {children}
    </PosContext.Provider>
  );
};

export { PosContext, PosProvider };
