export interface IAddress {
  address?: string;
  city?: string;
  country?: string;
  postalcode?: string;
  province?: string;
  street?: string;
}

export class Address implements IAddress {
  readonly address: string;
  readonly city: string;
  readonly country: string;
  readonly postalcode: string;
  readonly province: string;
  readonly street: string;

  constructor(data?: IAddress) {
    this.address = data?.address ?? '';
    this.city = data?.city ?? '';
    this.country = data?.country ?? '';
    this.postalcode = data?.postalcode ?? '';
    this.province = data?.province ?? '';
    this.street = data?.street ?? '';
  }
}
