import { message, notification } from 'antd';
import {
  deleteDoc,
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  updateDoc,
} from 'firebase/firestore';
import { Amount } from './Cart';

interface Range {
  max: number;
  min: number;
}

export interface IFee {
  amount: Amount;
  applyOnContainerSize: boolean;
  applyOnCreate: boolean;
  custom: boolean;
  criscodes: number[];
  description: string;
  eco: boolean;
  deposit: boolean;
  range: Range;
  tax: boolean;
  title: string;
}

export class Fee implements IFee {
  amount: Amount;
  applyOnContainerSize: boolean;
  applyOnCreate: boolean;
  custom: boolean;
  criscodes: number[];
  description: string;
  eco: boolean;
  deposit: boolean;
  range: Range;
  tax: boolean;
  title: string;

  // Non-db columns
  reference: DocumentReference;
  key: string; // Uuid

  constructor(snapshot: QueryDocumentSnapshot<DocumentData>) {
    // Since overloading is not allowed in typescript, check to see if it is IFee vs DocumentSnapshot
    const document = snapshot.data ? snapshot.data() : snapshot;

    this.reference = snapshot.ref;
    this.key = snapshot.id;
    this.amount =
      document.amount ?? ({ number: 0, unit: 'AmountUnit.Dollar' } as Amount);
    this.applyOnContainerSize = document.applyOnContainerSize ?? false;
    this.applyOnCreate = document.applyOnCreate ?? false;
    this.custom = document.custom ?? false;
    this.criscodes = document.criscodes ?? [];
    this.description = document.description ?? '';
    this.eco = document.eco ?? false;
    this.deposit = document.deposit ?? false;
    this.range = document.range ?? ({ min: 0, max: 0 } as Range);
    this.tax = document.tax ?? false;
    this.title = document.title ?? '';
  }

  async update() {
    try {
      await updateDoc(this.reference, {
        amount: this.amount,
        applyOnContainerSize: this.applyOnContainerSize,
        applyOnCreate: this.applyOnCreate,
        custom: this.custom,
        criscodes: this.criscodes,
        description: this.description,
        eco: this.eco,
        deposit: this.deposit,
        range: this.range,
        tax: this.tax,
        title: this.title,
      });
      return true;
    } catch (error: any) {
      notification['error']({
        message: 'Database Error',
        description: error.toString(),
      });
      return false;
    }
  }

  async delete() {
    try {
      await deleteDoc(this.reference);
      message.success('Fee Deleted!');
      return true;
    } catch (error: any) {
      notification['error']({
        message: 'Database Error',
        description: error.toString(),
      });
      return false;
    }
  }

  asIFee() {
    const iFee: IFee = {
      amount: this.amount,
      applyOnContainerSize: this.applyOnContainerSize,
      applyOnCreate: this.applyOnCreate,
      custom: this.custom,
      criscodes: this.criscodes,
      description: this.description,
      eco: this.eco,
      deposit: this.deposit,
      range: this.range,
      tax: this.tax,
      title: this.title,
    };
    return iFee;
  }
}
