import { message, notification } from 'antd';
import {
  collection,
  doc,
  DocumentData,
  onSnapshot,
  QuerySnapshot,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { db } from '../firebase/Fb';
import { IProduct } from './Product';

const StreamProducts = (
  companyId: string,
  storeId: string,
  observer: {
    next?: ((snapshot: QuerySnapshot<DocumentData>) => void) | undefined;
    error?: ((error: Error) => void) | undefined;
    complete?: (() => void) | undefined;
  }
) => {
  if (companyId && storeId) {
    console.log('Requesting for Products.');
    return onSnapshot(
      collection(db, `companies/${companyId}/stores/${storeId}/products`),
      observer
    );
  }
};

const createProduct = async (
  companyId: string,
  storeId: string,
  product: IProduct
) => {
  try {
    const newProductRef = doc(
      collection(db, `companies/${companyId}/stores/${storeId}/products`)
    );
    await setDoc(newProductRef, {
      ...product,
      createdTime: serverTimestamp(),
      updatedTime: serverTimestamp(),
    });
    message.success('Product Created!');
    return newProductRef;
  } catch (error: any) {
    notification['error']({
      message: 'Database Error',
      description: error.toString(),
    });
  }
};

export { StreamProducts, createProduct };
