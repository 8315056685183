import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';
import { Company } from '../models/company';
import { Customer } from '../models/customer';
import { Department } from '../models/department';
import { Drawer } from '../models/drawer';
import { Employee } from '../models/employee';
import { Fee } from '../models/fee';
import { Group } from '../models/group';
import { Payroll } from '../models/payroll';
import { PayrollEmployee } from '../models/payroll-employee';
import { Product } from '../models/product';
import { PurchaseOrder } from '../models/purchase-order';
import { PurchaseOrderItem } from '../models/purchase-order-item';
import { Sale } from '../models/sale';
import { SalesOrder } from '../models/sales-order';
import { Supplier } from '../models/supplier';
import { Transfer } from '../models/transfer';
import { db } from './Fb';

export type CollectionRef<T> = CollectionReference<T>;
export type DocumentRef<T> = DocumentReference<T>;

export interface GenericRepository<T> {
  collection: (
    companyId: string,
    storeId: string,
    subCollectionId?: string
  ) => () => CollectionRef<T>;
  document: (
    companyId: string,
    storeId: string,
    subCollectionId?: string
  ) => (documenetId: string) => DocumentRef<T>;
}

interface MasterRepository {
  company: GenericRepository<Company>;
  employee: GenericRepository<Employee>;
  payroll: GenericRepository<Payroll>;
  payrollemployee: GenericRepository<PayrollEmployee>;
  drawer: GenericRepository<Drawer>;
  transfer: GenericRepository<Transfer>;
  product: GenericRepository<Product>;
  group: GenericRepository<Group>;
  fee: GenericRepository<Fee>;
  department: GenericRepository<Department>;
  salesorder: GenericRepository<SalesOrder>;
  purchaseorder: GenericRepository<PurchaseOrder>;
  purchaseorderitem: GenericRepository<PurchaseOrderItem>;
  customer: GenericRepository<Customer>;
  supplier: GenericRepository<Supplier>;
  sale: GenericRepository<Sale>;
}

interface ToFirebase {
  toFirestore: () => any;
}

const converter = <T extends ToFirebase>(Type: {
  new (id: string, data: T): T;
}) => ({
  toFirestore: (data: T) => {
    return data.toFirestore();
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) => {
    const data = snapshot.data(options) as T;
    const obj = new Type(snapshot.id, data);
    return obj;
  },
});

const collectionConverter = <T extends ToFirebase>(
  collectionPath: string,
  Type: { new (id: string, data: T): T }
) => collection(db, collectionPath).withConverter(converter<T>(Type));

const documentConverter = <T extends ToFirebase>(
  documentPath: string,
  Type: { new (id: string, data: T): T }
) => {
  return doc(db, documentPath).withConverter(converter<T>(Type));
};

const masterRepository: MasterRepository = {
  company: {
    collection: () => () => collectionConverter<Company>('companies', Company),
    document: () => (companyId: string) =>
      documentConverter<Company>(`companies/${companyId}`, Company),
  },
  employee: {
    collection: (companyId: string) => () =>
      collectionConverter<Employee>(
        `companies/${companyId}/employees`,
        Employee
      ),
    document: (companyId: string) => (employeeId: string) =>
      documentConverter<Employee>(
        `companies/${companyId}/employees/${employeeId}`,
        Employee
      ),
  },
  payroll: {
    collection: (companyId: string) => () =>
      collectionConverter<Payroll>(`companies/${companyId}/payrolls`, Payroll),
    document: (companyId: string) => (payrollId: string) =>
      documentConverter<Payroll>(
        `companies/${companyId}/payrolls/${payrollId}`,
        Payroll
      ),
  },
  payrollemployee: {
    collection:
      (companyId: string, storeId: string, payrollId?: string) => () =>
        collectionConverter<PayrollEmployee>(
          `companies/${companyId}/payrolls/${payrollId}/payrollemployees`,
          PayrollEmployee
        ),
    document:
      (companyId: string, storeId: string, payrollId?: string) =>
      (payrollEmployeeId: string) =>
        documentConverter<PayrollEmployee>(
          `companies/${companyId}/payrolls/${payrollId}/payrollemployees/${payrollEmployeeId}`,
          PayrollEmployee
        ),
  },
  drawer: {
    collection: (companyId: string, storeId: string) => () =>
      collectionConverter<Drawer>(
        `companies/${companyId}/stores/${storeId}/drawers`,
        Drawer
      ),
    document: (companyId: string, storeId: string) => (drawerId: string) =>
      documentConverter<Drawer>(
        `companies/${companyId}/stores/${storeId}/drawers/${drawerId}`,
        Drawer
      ),
  },
  transfer: {
    collection:
      (companyId: string, storeId: string, subCollectionId?: string) => () =>
        collectionConverter<Transfer>(
          repositoryPath.transfers(companyId, storeId, subCollectionId!),
          Transfer
        ),
    document:
      (companyId: string, storeId: string, subCollectionId?: string) =>
      (purchaseOrderItemId: string) =>
        documentConverter<Transfer>(
          repositoryPath.transfer(
            companyId,
            storeId,
            subCollectionId!,
            purchaseOrderItemId
          ),
          Transfer
        ),
  },
  product: {
    collection: (companyId: string, storeId: string) => () =>
      collectionConverter<Product>(
        `companies/${companyId}/stores/${storeId}/products`,
        Product
      ),
    document: (companyId: string, storeId: string) => (productId: string) =>
      documentConverter<Product>(
        `companies/${companyId}/stores/${storeId}/products/${productId}`,
        Product
      ),
  },
  group: {
    collection: (companyId: string, storeId: string) => () =>
      collectionConverter<Group>(
        `companies/${companyId}/stores/${storeId}/groups`,
        Group
      ),
    document: (companyId: string, storeId: string) => (groupId: string) =>
      documentConverter<Group>(
        `companies/${companyId}/stores/${storeId}/groups/${groupId}`,
        Group
      ),
  },
  fee: {
    collection: (companyId: string, storeId: string) => () =>
      collectionConverter<Fee>(
        `companies/${companyId}/stores/${storeId}/fees`,
        Fee
      ),
    document: (companyId: string, storeId: string) => (feeId: string) =>
      documentConverter<Fee>(
        `companies/${companyId}/stores/${storeId}/fees/${feeId}`,
        Fee
      ),
  },
  department: {
    collection: (companyId: string, storeId: string) => () =>
      collectionConverter<Department>(
        `companies/${companyId}/stores/${storeId}/departments`,
        Department
      ),
    document: (companyId: string, storeId: string) => (departmentId: string) =>
      documentConverter<Department>(
        `companies/${companyId}/stores/${storeId}/departments/${departmentId}`,
        Department
      ),
  },
  salesorder: {
    collection: (companyId: string, storeId: string) => () =>
      collectionConverter<SalesOrder>(
        `companies/${companyId}/stores/${storeId}/salesorders`,
        SalesOrder
      ),
    document: (companyId: string, storeId: string) => (salesOrderId: string) =>
      documentConverter<SalesOrder>(
        `companies/${companyId}/stores/${storeId}/salesorders/${salesOrderId}`,
        SalesOrder
      ),
  },
  purchaseorder: {
    collection: (companyId: string, storeId: string) => () =>
      collectionConverter<PurchaseOrder>(
        `companies/${companyId}/stores/${storeId}/purchaseorders`,
        PurchaseOrder
      ),
    document:
      (companyId: string, storeId: string) => (purchaseOrderId: string) =>
        documentConverter<PurchaseOrder>(
          `companies/${companyId}/stores/${storeId}/purchaseorders/${purchaseOrderId}`,
          PurchaseOrder
        ),
  },
  purchaseorderitem: {
    collection:
      (companyId: string, storeId: string, subCollectionId?: string) => () =>
        collectionConverter<PurchaseOrderItem>(
          repositoryPath.purchaseOrderItems(
            companyId,
            storeId,
            subCollectionId!
          ),
          PurchaseOrderItem
        ),
    document:
      (companyId: string, storeId: string, subCollectionId?: string) =>
      (purchaseOrderItemId: string) =>
        documentConverter<PurchaseOrderItem>(
          repositoryPath.purchaseOrderItem(
            companyId,
            storeId,
            subCollectionId!,
            purchaseOrderItemId
          ),
          PurchaseOrderItem
        ),
  },
  customer: {
    collection: (companyId: string, storeId: string) => () =>
      collectionConverter<Customer>(
        `companies/${companyId}/stores/${storeId}/customers`,
        Customer
      ),
    document: (companyId: string, storeId: string) => (customerId: string) =>
      documentConverter<Customer>(
        `companies/${companyId}/stores/${storeId}/customers/${customerId}`,
        Customer
      ),
  },
  supplier: {
    collection: (companyId: string, storeId: string) => () =>
      collectionConverter<Supplier>(
        `companies/${companyId}/stores/${storeId}/suppliers`,
        Supplier
      ),
    document: (companyId: string, storeId: string) => (supplierId: string) =>
      documentConverter<Supplier>(
        `companies/${companyId}/stores/${storeId}/suppliers/${supplierId}`,
        Supplier
      ),
  },
  sale: {
    collection: (companyId: string, storeId: string) => () =>
      collectionConverter<Sale>(
        `companies/${companyId}/stores/${storeId}/sales`,
        Sale
      ),
    document: (companyId: string, storeId: string) => (saleId: string) =>
      documentConverter<Sale>(
        `companies/${companyId}/stores/${storeId}/sales/${saleId}`,
        Sale
      ),
  },
};

const repositoryPath = {
  purchaseOrderItems: (
    companyId: string,
    storeId: string,
    purchaseOrderId: string
  ) => {
    return `companies/${companyId}/stores/${storeId}/purchaseorders/${purchaseOrderId}/purchaseorderitems`;
  },
  purchaseOrderItem: (
    companyId: string,
    storeId: string,
    purchaseOrderId: string,
    purchaseOrderItemId: string
  ) => {
    return `companies/${companyId}/stores/${storeId}/purchaseorders/${purchaseOrderId}/purchaseorderitems/${purchaseOrderItemId}`;
  },
  transfers: (companyId: string, storeId: string, drawerId: string) => {
    return `companies/${companyId}/stores/${storeId}/drawers/${drawerId}/transfers`;
  },
  transfer: (
    companyId: string,
    storeId: string,
    drawerId: string,
    transferId: string
  ) => {
    return `companies/${companyId}/stores/${storeId}/drawers/${drawerId}/transfers/${transferId}`;
  },
};

export { masterRepository, repositoryPath, converter };
