const alertUserForUnsaveChanges = (ev: BeforeUnloadEvent) => {
  ev.preventDefault();
  ev.returnValue = "";
};

const addBeforeUnloadDirtyDialog = () => {
  window.addEventListener("beforeunload", alertUserForUnsaveChanges);
};

const removeBeforeUnloadDirtyDialog = () => {
  window.removeEventListener("beforeunload", alertUserForUnsaveChanges);
};

export { addBeforeUnloadDirtyDialog, removeBeforeUnloadDirtyDialog };
