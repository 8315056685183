import { IDepartment } from './Department';
import { Fee, IFee } from './Fee';
import { IGroup } from './Group';
import { IProduct, Product } from './Product';

export interface Amount {
  number: number;
  unit: 'AmountUnit.Percentage' | 'AmountUnit.Dollar' | 'AmountUnit.Quantity';
}

const calculateAmount = (amount: Amount, price: number, quantity: number) => {
  if (amount.unit === 'AmountUnit.Dollar') {
    return amount.number * quantity;
  } else if (amount.unit === 'AmountUnit.Percentage') {
    return amount.number * price;
  } else {
    return 0;
  }
};

export interface ICartItem {
  departments: IDepartment[];
  groups: IGroup[];
  discounts: Amount[];
  fees: IFee[];
  product?: IProduct;
  quantity: number;
  returnQuantity: number;
}

export class CartItem implements ICartItem {
  departments: IDepartment[];
  groups: IGroup[];
  discounts: Amount[];
  fees: Fee[];
  product?: Product;
  quantity: number;
  returnQuantity: number;

  constructor(data: ICartItem) {
    this.departments = data.departments ?? [];
    this.groups = data.groups ?? [];
    this.discounts =
      data.discounts ?? ({ number: 0, unit: 'AmountUnit.Dollar' } as Amount);
    this.fees = data.fees.map((fee) => new Fee(fee as any)) ?? [];
    this.product = data.product ? new Product(data.product as any) : undefined;
    this.quantity = data.quantity ?? 1;
    this.returnQuantity = data.returnQuantity ?? 0;
  }

  calculateSubtotal() {
    if (this.product) {
      const productPrice = this.product.price;
      return (
        this.product.price * this.quantity -
        this.discounts.reduce((prev, discount) => {
          if (discount.unit === 'AmountUnit.Percentage') {
            return productPrice * discount.number * this.quantity;
          } else if (discount.unit === 'AmountUnit.Dollar') {
            return prev + discount.number;
          }
          throw new Error('Unsupported discount type.');
        }, 0)
      );
    }
    return 0;
  }

  calculateFees(fee?: 'eco' | 'tax' | 'deposit' | 'custom') {
    return this.fees.reduce((prevFeeAcc, currFee) => {
      if (fee === 'custom') {
        if (!currFee['eco'] && !currFee['tax'] && !currFee['deposit']) {
          return (
            prevFeeAcc +
            calculateAmount(
              currFee.amount,
              this.calculateSubtotal(),
              this.quantity
            )
          );
        }
        return prevFeeAcc;
      } else if (!fee || currFee[fee]) {
        let sizeQuantity = 1;
        // Used for 6pk 12pk etc where we have multiple cans.
        if (
          (currFee.deposit || currFee.eco) &&
          this.product!.size.quantity > 1
        ) {
          sizeQuantity = this.product!.size.quantity;
          console.log('multiple packs detected.');
        }

        return (
          prevFeeAcc +
          calculateAmount(
            currFee.amount,
            this.calculateSubtotal(),
            this.quantity * sizeQuantity
          )
        );
      }
      return prevFeeAcc;
    }, 0);
  }

  calculateTotal() {
    return this.calculateSubtotal() + this.calculateFees();
  }

  asICartItem() {
    const iCartItem: ICartItem = {
      departments: [], // Come back later
      groups: [], // Come back later
      discounts: this.discounts,
      fees: this.fees.map((fee) => fee.asIFee()),
      product: this.product?.asIProduct(),
      quantity: this.quantity,
      returnQuantity: this.returnQuantity,
    };
    return iCartItem;
  }
}

export interface ICart {
  cartItems: ICartItem[];
  discounts: Amount[];
}

export class Cart implements ICart {
  cartItems: CartItem[];
  discounts: Amount[];

  constructor(data: ICart) {
    this.cartItems =
      data.cartItems && !!data.cartItems.length
        ? data.cartItems.map((item) => new CartItem(item))
        : [];
    this.discounts = data.discounts ?? [];
  }

  calculateSubtotal() {
    if (this.cartItems) {
      return this.cartItems.reduce(
        (prev, curr) => prev + curr.calculateSubtotal(),
        0
      );
    }
    return 0;
  }

  calculateFees(fee?: 'eco' | 'tax' | 'deposit' | 'custom') {
    if (this.cartItems) {
      return this.cartItems.reduce((prevCartItemAcc, currCartItem) => {
        return prevCartItemAcc + currCartItem.calculateFees(fee);
      }, 0);
    }
    return 0;
  }

  calculateTotal() {
    if (this.cartItems) {
      return this.calculateSubtotal() + this.calculateFees();
    }
    return 0;
  }

  getTaxes() {
    const hash = new Map<string, number>();
    if (this.cartItems) {
      this.cartItems.forEach((item) => {
        item.fees.forEach((fee) => {
          if (fee.tax) {
            if (hash.has(fee.title)) {
              hash.set(
                fee.title,
                (hash.get(fee.title) ?? 0) +
                  calculateAmount(
                    fee.amount,
                    item.calculateSubtotal(),
                    item.quantity
                  )
              );
            } else {
              hash.set(
                fee.title,
                calculateAmount(
                  fee.amount,
                  item.calculateSubtotal(),
                  item.quantity
                )
              );
            }
          }
        });
      });
    }

    const taxes = [];
    // @ts-ignore
    for (const [key, value] of hash.entries()) {
      taxes.push({
        title: key as string,
        amount: value as number,
      });
    }
    return taxes;
  }

  // Storing into Firestore requires object to be PURE JS objects.
  asICart() {
    const iCart: ICart = {
      cartItems: this.cartItems.map((cartItem) => cartItem.asICartItem()),
      discounts: this.discounts,
    };
    return iCart;
  }
}
