import { Sale } from '../../../models/sale';
import { Store } from '../../../services/CompanyService';
import { ppPaymentType, ppPrice } from '../../../utils/Formatter';
const QRCode = require('qrcode.react');
const Barcode = require('react-barcode');

const Receipt = ({
  store,
  sale,
  fullHeight,
}: {
  store: Store;
  sale: Sale;
  fullHeight?: boolean;
}) => {
  return (
    <div
      id='print-content'
      style={{ height: fullHeight ? undefined : '350px', overflow: 'auto' }}
    >
      <div style={{ margin: 8, fontSize: '.8rem' }}>
        {sale.voided && (
          <p
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '2rem',
              marginBottom: 0,
            }}
          >
            VOID
          </p>
        )}
        <p
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '1rem',
            marginBottom: 0,
          }}
        >
          {store.name}
        </p>
        <p style={{ textAlign: 'center' }}>
          {store.address.address}
          <br />
          {`${store.address.city}, ${store.address.province} ${store.address.postalcode}`}
          <br />
          {store.phone}
          <br />
          {store.businessnumber}
          <br />
          {sale.getCreatedTime()}
        </p>
        <table style={{ borderCollapse: 'collapse', fontSize: '.8rem' }}>
          <tbody style={{ borderBottom: 'solid 1px' }}>
            <tr key='description-area'>
              <th style={{ textAlign: 'center' }}>Qty</th>
              <th style={{ textAlign: 'left', width: '100%' }}>Description</th>
              <th style={{ textAlign: 'right' }}>Amount</th>
            </tr>
          </tbody>
          <tbody style={{ borderBottom: 'solid 1px' }}>
            {sale.cart.cartItems.map((item, index) => {
              return (
                <>
                  <tr key={`item-${index}-1`}>
                    <td style={{ textAlign: 'center' }}>{item.quantity}</td>
                    <td>{item.product.title}</td>
                    <td style={{ textAlign: 'right' }}>
                      {ppPrice(
                        sale.cart.feesInclusive
                          ? item.calculateTotal()
                          : item.calculateSubtotal()
                      )}
                      {item.fees.some((fee) => fee.tax) && 'T'}
                    </td>
                  </tr>
                  <tr key={`item-${index}-2`}>
                    <td></td>
                    <td>{`${item.product.sku} @ ${ppPrice(
                      (sale.cart.feesInclusive
                        ? item.calculateTotal()
                        : item.calculateSubtotal()) / item.quantity
                    )}`}</td>
                    <td
                      style={{
                        textAlign: 'right',
                        textDecoration: 'line-through',
                        fontStyle: 'italic',
                      }}
                    >
                      {item.hasDiscount() ? (
                        <>
                          {ppPrice(item.calculateTotalWithoutDiscount())}
                          {item.fees.some((fee) => fee.tax) && 'T'}
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
          <tbody style={{ borderBottom: 'solid 1px' }}>
            {!sale.cart.feesInclusive && (
              <tr key='subtotal-0'>
                <td style={{ textAlign: 'center' }}></td>
                <td style={{ textAlign: 'right', width: '100%' }}>Subtotal</td>
                <td style={{ textAlign: 'right' }}>
                  {ppPrice(sale.cart.calculateSubtotal())}
                </td>
              </tr>
            )}
            {sale.cart.calculateFees('deposit') > 0 && (
              <tr key='deposit-0'>
                <td style={{ textAlign: 'center' }}></td>
                <td style={{ textAlign: 'right', width: '100%' }}>
                  Deposit {sale.cart.feesInclusive && 'included'}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {ppPrice(sale.cart.calculateFees('deposit'))}
                </td>
              </tr>
            )}
            {sale.cart.calculateFees('eco') > 0 && (
              <tr key='eco-0'>
                <td style={{ textAlign: 'center' }}></td>
                <td style={{ textAlign: 'right', width: '100%' }}>
                  Eco-fee {sale.cart.feesInclusive && 'included'}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {ppPrice(sale.cart.calculateFees('eco'))}
                </td>
              </tr>
            )}
            {sale.cart.calculateFees('custom') > 0 && (
              <tr key='custom-0'>
                <td style={{ textAlign: 'center' }}></td>
                <td style={{ textAlign: 'right', width: '100%' }}>
                  Fees {sale.cart.feesInclusive && 'included'}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {ppPrice(sale.cart.calculateFees('custom'))}
                </td>
              </tr>
            )}
            {sale.cart.getTaxes().map((tax, index) => {
              return (
                <tr key={`tax-${index}`}>
                  <td style={{ textAlign: 'center' }}></td>
                  <td style={{ textAlign: 'right', width: '100%' }}>
                    {tax.title} {sale.cart.feesInclusive && 'included'}
                  </td>
                  <td style={{ textAlign: 'right' }}>{ppPrice(tax.amount)}</td>
                </tr>
              );
            })}
          </tbody>
          <tbody style={{ borderBottom: 'double 4px' }}>
            <tr key='total-area'>
              <td style={{ textAlign: 'center' }}></td>
              <td
                style={{
                  textAlign: 'right',
                  width: '100%',
                  fontWeight: 'bold',
                }}
              >
                Total
              </td>
              <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                {ppPrice(sale.cart.calculateTotal())}
              </td>
            </tr>
          </tbody>
          <tbody>
            {sale.payments.map((payment, index) => {
              return (
                <>
                  <tr key={`payment-${index}-1`}>
                    <td style={{ textAlign: 'center' }}></td>
                    <td
                      style={{
                        textAlign: 'right',
                        width: '100%',
                        fontStyle: 'italic',
                      }}
                    >
                      {ppPaymentType(payment.type)}
                    </td>
                    <td style={{ textAlign: 'right', fontStyle: 'italic' }}>
                      {ppPrice(payment.totalPayed)}
                    </td>
                  </tr>
                  {payment.totalCashReturned > 0 && (
                    <tr key={`payment-${index}-2`}>
                      <td style={{ textAlign: 'center' }}></td>
                      <td
                        style={{
                          textAlign: 'right',
                          width: '100%',
                          fontStyle: 'italic',
                        }}
                      >
                        Change
                      </td>
                      <td style={{ textAlign: 'right', fontStyle: 'italic' }}>
                        {ppPrice(payment.totalCashReturned)}
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>

        {sale.voided && (
          <p
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '2rem',
              marginBottom: 0,
            }}
          >
            VOID
          </p>
        )}
        {sale.cart.calculateDiscounts() > 0 && (
          <p
            style={{
              textAlign: 'center',
              fontSize: '0.8rem',
              marginBottom: 0,
              marginTop: 16,
            }}
          >
            You saved {ppPrice(sale.cart.calculateDiscounts())}
          </p>
        )}

        <p style={{ textAlign: 'center', paddingTop: 16 }}>
          <Barcode
            value={sale.getTransactionTimestampId()}
            height={25}
            width={1.5}
            fontSize={10}
          />
        </p>
        <p
          style={{ textAlign: 'center', fontSize: '.6rem' }}
        >{`Reference: ${sale.key}`}</p>
      </div>
    </div>
  );
};

export { Receipt };
