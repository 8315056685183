import { Button, Input, InputNumber, List, Modal, Radio, Row } from 'antd';
import {
  collection,
  doc,
  increment,
  serverTimestamp,
  writeBatch,
} from 'firebase/firestore';
import { useState } from 'react';
import { masterRepository, repositoryPath } from '../../../../firebase/db';
import { db } from '../../../../firebase/Fb';
import { Drawer } from '../../../../models/drawer';
import { ppPrice } from '../../../../utils/Formatter';
import { DollarInputNumberFormatter } from '../../../common/detail/cards/card-styles';
import { Messages, Notifications } from '../../../notification/Messages';

const CashInOut = ({
  companyId,
  storeId,
  drawer,
}: {
  companyId: string;
  storeId: string;
  drawer: Drawer;
}) => {
  const [amount, setAmount] = useState<number>();
  const [reason, setReason] = useState('');
  const [type, setType] = useState<'ReasonType.transfer' | 'ReasonType.payout'>(
    'ReasonType.transfer'
  );

  const createTransferAndUpdateDrawer = async (
    value: number,
    reason: string
  ) => {
    try {
      const batch = writeBatch(db);

      // Update drawer transfer cash.
      const drawerRepository = masterRepository.drawer.document(
        companyId,
        storeId
      );
      const drawerDocument = drawerRepository(drawer.key);
      batch.update(drawerDocument, {
        transferCash: increment(value),
      });

      // Create transfer.
      const transferDocument = doc(
        collection(db, repositoryPath.transfers(companyId, storeId, drawer.key))
      );

      batch.set(transferDocument, {
        reason: reason,
        type: type,
        value: value,
        createdTime: serverTimestamp(),
      });

      await batch.commit();
      Messages.saved();
      Modal.destroyAll();
    } catch (error) {
      Notifications.error(error);
    }
  };

  return (
    <>
      <List>
        <List.Item>
          <List.Item.Meta title='Type' />
          <Radio.Group value={type} onChange={(e) => setType(e.target.value)}>
            <Radio.Button value='ReasonType.transfer'>Transfer</Radio.Button>
            <Radio.Button value='ReasonType.payout'>Payout</Radio.Button>
          </Radio.Group>
        </List.Item>
        <List.Item>
          <List.Item.Meta title='Cash amount' />
          <InputNumber
            style={{ width: 160 }}
            precision={2}
            formatter={DollarInputNumberFormatter}
            value={amount}
            onChange={(value) => {
              setAmount(value);
            }}
            min={0}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta title='Reason' />
          <Input
            style={{ width: 160 }}
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
            }}
          />
        </List.Item>
      </List>
      <Row wrap={false} justify='space-between' style={{ marginTop: 8 }}>
        <Button
          disabled={!amount}
          style={{ width: 160 }}
          type='primary'
          danger
          onClick={() => {
            createTransferAndUpdateDrawer(amount! * -1, reason);
          }}
        >
          Cash Out {amount && ppPrice(-1 * amount)}
        </Button>
        <div style={{ width: 32 }} />
        <Button
          disabled={!amount}
          style={{ width: 160 }}
          type='primary'
          onClick={() => {
            createTransferAndUpdateDrawer(amount!, reason);
          }}
        >
          Cash In {amount && ppPrice(amount)}
        </Button>
      </Row>
    </>
  );
};

const CashInOutDialog = (
  companyId: string,
  storeId: string,
  drawer?: Drawer
) => {
  if (drawer) {
    Modal.success({
      centered: true,
      closable: true,
      maskClosable: false,
      title: 'Cash In/Out',
      okText: null,
      icon: null,
      okButtonProps: {
        style: {
          display: 'none',
        },
      },
      content: (
        <CashInOut companyId={companyId} storeId={storeId} drawer={drawer} />
      ),
    });
  }
};

export { CashInOutDialog };
