import { DocumentReference, Timestamp } from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import { ppMomentDateTime } from '../utils/Formatter';
import { DocumentModel, FirestoreTimestamp } from './utils/document-model';
import {
  documentReference2String,
  string2DocumentReference,
} from './utils/document-reference-serializer';

interface IPurchaseOrder {
  caseQuantity?: number;
  completed?: boolean;
  index?: number;
  ionumber?: string;
  refnumber?: string;
  supplier?: DocumentReference | string;
  totalAccepted?: number;
  totalCanceled?: number;
  totalCost?: number; // Net cost = price + deposit
  totalTaxes?: number;
  totalFinalCost?: number; // Final cost = net cost + gst
  totalQuantity?: number;
  totalRejected?: number;
  tracknumber?: string;
  createdTime?: FirestoreTimestamp;
  updatedTime?: FirestoreTimestamp;
}

export class PurchaseOrder
  extends DocumentModel<IPurchaseOrder>
  implements IPurchaseOrder
{
  readonly caseQuantity: number;
  readonly completed: boolean;
  readonly index: number;
  readonly ionumber: string;
  readonly refnumber: string;
  readonly supplier: string;
  readonly totalAccepted: number;
  readonly totalCanceled: number;
  readonly totalCost: number;
  readonly totalTaxes: number;
  readonly totalFinalCost: number;
  readonly totalQuantity: number;
  readonly totalRejected: number;
  readonly tracknumber: string;
  readonly createdTime?: FirestoreTimestamp;
  readonly updatedTime?: FirestoreTimestamp;

  constructor(id: string, data: IPurchaseOrder) {
    super(id, data);
    this.caseQuantity = data.caseQuantity ?? 1;
    this.completed = data.completed ?? false;
    this.index = data.index ?? -1;
    this.ionumber = data.ionumber ?? '';
    this.refnumber = data.refnumber ?? '';
    this.supplier = documentReference2String(data.supplier);
    this.totalAccepted = data.totalAccepted ?? 0;
    this.totalCanceled = data.totalCanceled ?? 0;
    this.totalCost = data.totalCost ?? 0;
    this.totalTaxes = data.totalTaxes ?? 0;
    this.totalFinalCost = data.totalFinalCost ?? 0;
    this.totalQuantity = data.totalQuantity ?? 0;
    this.totalRejected = data.totalRejected ?? 0;
    this.tracknumber = data.tracknumber ?? '';
    this.createdTime = data.createdTime;
    this.updatedTime = data.updatedTime;
  }

  toFirestore() {
    const iPurchaseOrder = super.toFirestore();

    if (this._modifiedProperties.supplier) {
      iPurchaseOrder.supplier = string2DocumentReference(this.supplier);
    }

    return iPurchaseOrder;
  }

  getCreatedTime() {
    try {
      return ppMomentDateTime(
        moment((this.createdTime as Timestamp).toDate()),
        'MMM D YYYY, h:mm:ss A'
      );
    } catch (error) {
      return ppMomentDateTime(moment(), 'MMM Do YYYY, h:mm:ss A');
    }
  }

  getPONumber() {
    if (this.index === undefined || this.index < 0) {
      return '';
    }
    return '#PO-' + _.padStart(_.toString(this.index), 4, '0');
  }
}
