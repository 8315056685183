import { Sale } from "../services/Sale";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../firebase/Auth";
import { StreamSales } from "../services/SaleService";
import { notification } from "antd";
import { DateTime } from "luxon";
import {
  StreamDrawers,
  StreamDrawerTransfers,
} from "../services/DrawerService";
import { Drawer, DrawerTransfer } from "../services/Drawer";

interface ReportingProviderProps {
  children: React.ReactNode;
}

interface ReportingContextProps {
  sales: Sale[];
  drawers: Drawer[];
  drawerTransfers: DrawerTransfer[];
  startDateTime: DateTime | undefined;
  endDateTime: DateTime | undefined;
  setStartDateTime: React.Dispatch<React.SetStateAction<DateTime>>;
  setEndDateTime: React.Dispatch<React.SetStateAction<DateTime | undefined>>;
}

const ReportingContext = React.createContext<ReportingContextProps>({
  sales: [],
  drawers: [],
  drawerTransfers: [],
  startDateTime: undefined,
  endDateTime: undefined,
  setStartDateTime: () => {},
  setEndDateTime: () => {},
});

const ReportingProvider: React.FC<ReportingProviderProps> = ({ children }) => {
  const { currentCompany, currentStore } = useContext(AuthContext);
  const [sales, setSales] = useState<Sale[]>([]);
  const [drawers, setDrawers] = useState<Drawer[]>([]);
  const [drawerTransfers, setDrawerTransfers] = useState<DrawerTransfer[]>([]);

  // By default, the time is set to Today.
  const [startDateTime, setStartDateTime] = useState<DateTime>(
    DateTime.local().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
  );
  const [endDateTime, setEndDateTime] = useState<DateTime>();

  useEffect(() => {
    if (!currentCompany || !currentStore) return;
    const unsubscribe = StreamSales(
      currentCompany.key,
      currentStore.key,
      startDateTime,
      endDateTime,
      {
        next: (snapshot) => {
          const sales = snapshot.docs.map((snap: any) => {
            return new Sale(snap);
          });
          setSales(sales.filter((sale) => !sale.voided));
        },
        error: (error) => {
          notification["error"]({
            message: "Database Error",
            description: error.toString(),
          });
        },
      }
    );

    return unsubscribe;
  }, [currentCompany, currentStore, startDateTime, endDateTime]);

  useEffect(() => {
    if (!currentCompany || !currentStore) return;

    const unsubscribe = StreamDrawers(
      currentCompany.key,
      currentStore.key,
      startDateTime,
      endDateTime,
      {
        next: (snapshot) => {
          const drawers = snapshot.docs.map((snap: any) => {
            return new Drawer(snap);
          });
          setDrawers(drawers);
        },
        error: (error) => {
          notification["error"]({
            message: "Database Error",
            description: error.toString(),
          });
        },
      }
    );

    return unsubscribe;
  }, [currentCompany, currentStore, startDateTime, endDateTime]);

  useEffect(() => {
    if (!currentCompany || !currentStore) return;

    const unsubscribe = StreamDrawerTransfers(
      currentCompany.key,
      currentStore.key,
      startDateTime,
      endDateTime,
      {
        next: (snapshot) => {
          const drawerTransfers = snapshot.docs.map((snap: any) => {
            return new DrawerTransfer(snap);
          });
          setDrawerTransfers(drawerTransfers);
        },
        error: (error) => {
          notification["error"]({
            message: "Database Error",
            description: error.toString(),
          });
        },
      }
    );

    return unsubscribe;
  }, [currentCompany, currentStore, startDateTime, endDateTime]);

  return (
    <ReportingContext.Provider
      value={{
        sales,
        drawers,
        drawerTransfers,
        startDateTime,
        endDateTime,
        setStartDateTime,
        setEndDateTime,
      }}
    >
      {children}
    </ReportingContext.Provider>
  );
};

export { ReportingProvider, ReportingContext };
