import { Button, Input, List, Space } from 'antd';
import { ModalStaticFunctions } from 'antd/lib/modal/confirm';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Cart } from '../../../models/cart';
import { Customer } from '../../../models/customer';
import { CustomerContext } from '../../../providers/base/CustomerProvider';

const SearchCustomer = ({
  destroyModal,
  setCustomer,
  setCart,
}: {
  destroyModal: () => void;
  setCustomer: React.Dispatch<React.SetStateAction<Customer | undefined>>;
  setCart: React.Dispatch<React.SetStateAction<Cart>>;
}) => {
  const { snapshots: customerSnapshots } = useContext(CustomerContext);
  const customers = customerSnapshots.map((snap) => snap.data());
  const [filteredCustomers, setFilteredCustomers] = useState(customers);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (filter === '') {
      setFilteredCustomers(customers);
    } else {
      const newList = customers.filter((customer) => {
        return (
          customer.phone.toLowerCase().includes(filter.toLowerCase()) ||
          customer
            .getBusinessName()
            .toLowerCase()
            .includes(filter.toLowerCase())
        );
      });
      setFilteredCustomers(newList);
    }
  }, [filter]);

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Input
        onPressEnter={(e) => {
          //@ts-ignore
          setFilter(e.target.value);
        }}
        placeholder='Search by phone, business name, or name'
      />
      <List
        style={{ height: 400, overflow: 'auto' }}
        dataSource={filteredCustomers}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={item.getBusinessName()}
              description={`${item.phone}${
                item.loyalty ? ` - ${item.points} points` : ''
              }`}
            />
            <Button
              type='primary'
              onClick={() => {
                setCustomer(item);
                setCart((prev) => {
                  const newCart = _.cloneDeep(prev);
                  (newCart.customerId as string) = item.key;
                  return newCart;
                });
                destroyModal();
              }}
            >
              Select
            </Button>
          </List.Item>
        )}
      />
    </Space>
  );
};

const SearchCustomerDialog = (
  modal: Omit<ModalStaticFunctions, 'warn'>,
  setCustomer: React.Dispatch<React.SetStateAction<Customer | undefined>>,
  setCart: React.Dispatch<React.SetStateAction<Cart>>
) => {
  const { destroy, update } = modal.success({});

  update({
    centered: true,
    closable: true,
    maskClosable: false,
    title: 'Select Customer',
    okText: null,
    icon: null,
    okButtonProps: {
      style: {
        display: 'none',
      },
    },
    content: (
      <SearchCustomer
        destroyModal={destroy}
        setCustomer={setCustomer}
        setCart={setCart}
      />
    ),
  });
};

export { SearchCustomerDialog };
