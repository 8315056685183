import { QueryDocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { CartItem } from '../../models/cart-item';
import { Fee } from '../../models/fee';
import { Product } from '../../models/product';
import { ppPrice } from '../../utils/Formatter';

const LabelTemplate = ({
  hidden,
  width,
  height,
  products,
  feeSnapshots,
  includeTaxes,
  includeSKU,
  includeBarcode,
  includeSize,
  truncateTitle,
}: {
  hidden?: boolean;
  width: string;
  height: string;
  products: Product[];
  feeSnapshots: QueryDocumentSnapshot<Fee>[];
  includeTaxes: boolean;
  includeSKU: boolean;
  includeBarcode: boolean;
  includeSize: boolean;
  truncateTitle: boolean;
}) => {
  return (
    <>
      <div id='print-label-content' style={{ display: hidden ? 'none' : '' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {products.map((product, index) => {
            const fees = feeSnapshots
              .filter((snap) => product.fees.includes(snap.ref.path))
              .map((snap) => snap.data());
            const item = new CartItem({
              product: product,
              departments: [],
              discounts: [],
              groups: [],
              fees: fees,
              quantity: 1,
              returnQuantity: 0,
            });

            const price = includeTaxes
              ? _.round(item.calculateTotal(), 2)
              : product.price;
            const sPrice = ppPrice(price);
            const [dollar, cent] = sPrice.substring(1).split('.');

            const hasGst = fees.some((fee) => fee.tax && fee.title === 'GST');
            const hasPst = fees.some((fee) => fee.tax && fee.title === 'PST');
            const hasDeposit = fees.some((fee) => fee.deposit);
            const hasEco = fees.some((fee) => fee.eco);
            const doubleLineDescriptionArea = height === '1.25';
            return (
              <div
                key={index}
                className='label-box'
                style={{
                  backgroundColor: 'white',
                  padding: 6,
                  borderStyle: 'solid',
                  borderWidth: 'thin',
                  width: `${width}in`,
                  height: `${height}in`,
                  lineHeight: 1.5,
                  boxSizing: 'border-box',
                  fontFamily: 'Arial, Helvetica, sans-serif',
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                      WebkitLineClamp: doubleLineDescriptionArea ? 2 : 1,
                      WebkitBoxOrient: 'vertical',
                      display: '-webkit-box',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal',
                      wordBreak: truncateTitle ? 'break-all' : undefined,
                      margin: 0,
                    }}
                  >
                    {product.title}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      flex: '1 1 auto',
                      overflow: 'hidden',
                    }}
                  >
                    <p
                      style={{
                        WebkitBoxOrient: 'vertical',
                        display: includeBarcode ? '-webkit-box' : 'none',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        wordBreak: 'break-all',
                        margin: 0,
                        fontSize: '12px',
                      }}
                    >
                      {product.barcode}
                    </p>
                    <p
                      style={{
                        WebkitBoxOrient: 'vertical',
                        display: includeTaxes ? 'none' : '-webkit-box',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        wordBreak: 'break-all',
                        margin: 0,
                        fontSize: '12px',
                      }}
                    >
                      {product.title === 'Sample Product Name' ? (
                        <>
                          <span>+gst</span>
                          <span style={{ marginLeft: 4 }}>+pst</span>
                          <span style={{ marginLeft: 4 }}>+deposit</span>
                          <span style={{ marginLeft: 4 }}>+eco</span>
                        </>
                      ) : (
                        <>
                          {fees
                            .filter((fee) => fee.tax)
                            .map((fee, index) => (
                              <span style={{ marginLeft: index === 0 ? 0 : 4 }}>
                                +{fee.title.toLowerCase()}
                              </span>
                            ))}
                          <span
                            style={{
                              marginLeft: 4,
                              display: hasDeposit ? '' : 'none',
                            }}
                          >
                            +deposit
                          </span>
                          <span
                            style={{
                              marginLeft: 4,
                              display: hasEco ? '' : 'none',
                            }}
                          >
                            +eco
                          </span>
                        </>
                      )}
                    </p>
                    <p
                      style={{
                        textAlign: 'left',
                        WebkitBoxOrient: 'vertical',
                        display: includeSize ? '-webkit-box' : 'none',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        wordBreak: 'break-all',
                        margin: 0,
                      }}
                    >
                      {product.size.ppFullSize()}
                    </p>
                  </div>
                  <div
                    style={{
                      width: '120px',
                      minWidth: '120px',
                      overflow: 'hidden',
                      textAlign: 'right',
                    }}
                  >
                    <p
                      style={{
                        WebkitBoxOrient: 'vertical',
                        display: includeSKU ? '-webkit-box' : 'none',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        wordBreak: 'break-all',
                        margin: 0,
                        fontSize: '12px',
                      }}
                    >
                      {product.sku}
                    </p>
                    <p
                      style={{
                        WebkitBoxOrient: 'vertical',
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        wordBreak: 'break-all',
                        margin: 0,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 40,
                          fontWeight: 'bold',
                          lineHeight: 1,
                        }}
                      >
                        {dollar}
                      </span>
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                          lineHeight: 1,
                        }}
                      >
                        .{cent}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <iframe
        style={{ display: 'none' }}
        id='print-label-iframe'
        width='100%'
        height='100%'
      />
    </>
  );
};

export { LabelTemplate };
