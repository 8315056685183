import { Table, Tag, Typography } from 'antd';
import { useContext, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Address } from '../../../models/address';
import { CompanyContext } from '../../../providers/base/CompanyProvider';
import { ppAddress } from '../../../utils/Formatter';
import { HighlighterStyle } from '../../common/detail/cards/card-styles';
const { Text } = Typography;

const TenantTable = () => {
  const [stringFilter, setStringFilter] = useState('');
  const { snapshots: companySnapshots, isLoading } = useContext(CompanyContext);
  const companies = companySnapshots.map((snap) => snap.data());
  return (
    <Table
      sticky
      bordered
      size='small'
      scroll={{ x: 'max-content' }}
      loading={isLoading}
      dataSource={companies}
      columns={[
        {
          width: 64,
          align: 'center',
          title: '#',
          key: 'index',
          render: (text, record, index) => index + 1,
        },
        {
          title: 'Business name',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true,
          render: (title: string, record) => (
            <Highlighter
              highlightStyle={HighlighterStyle}
              searchWords={[stringFilter]}
              textToHighlight={title}
            />
          ),
        },
        {
          title: 'Operating name',
          dataIndex: 'operatingName',
          key: 'operatingName',
          ellipsis: true,
          render: (title: string, record) => (
            <Highlighter
              highlightStyle={HighlighterStyle}
              searchWords={[stringFilter]}
              textToHighlight={title}
            />
          ),
        },
        {
          title: 'Licenses',
          dataIndex: 'configurations',
          key: 'configurations',
          ellipsis: true,
          render: (configurations: Map<string, string>, record) => {
            if (
              Array.from(configurations?.keys()).length === 0 &&
              !record.trial
            ) {
              return <Tag color='red'>Expired</Tag>;
            }
            return (
              <>
                {[
                  ...Array.from(configurations?.keys() ?? []),
                  ...(record.trial ? ['trial'] : []),
                ]
                  .sort()
                  .map((license) => {
                    if (license === 'trial')
                      return <Tag color='blue'>Trial</Tag>;
                    if (license === 'enable_payroll')
                      return <Tag color='purple'>Payroll</Tag>;
                    if (license === 'enable_t4')
                      return <Tag color='purple'>T4</Tag>;
                    if (license === 'enable_payroll_eft')
                      return <Tag color='purple'>EFT</Tag>;
                    if (license === 'enable_pos')
                      return <Tag color='green'>Point of sale</Tag>;
                    if (license === 'enable_web_pos')
                      return <Tag color='green'>Point of sale - Advanced</Tag>;
                    return <Tag>Unknown license</Tag>;
                  })}
              </>
            );
          },
        },
        {
          title: 'Payment type',
          dataIndex: 'paymentType',
          key: 'paymentType',
          width: 128,
          render: (paymentType: string) => {
            if (paymentType) {
              return <Tag color='blue'>{paymentType}</Tag>;
            } else {
              return <Tag color='red'>None</Tag>;
            }
          },
        },
        {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
          render: (address: Address) => (
            <Highlighter
              highlightStyle={HighlighterStyle}
              searchWords={[stringFilter]}
              textToHighlight={ppAddress(address)}
            />
          ),
        },
        {
          title: 'Payroll account number',
          dataIndex: 'payrollnumber',
          key: 'payrollnumber',
          ellipsis: true,
          render: (id: string) => (
            <Highlighter
              highlightStyle={HighlighterStyle}
              searchWords={[stringFilter]}
              textToHighlight={id}
            />
          ),
        },
        {
          title: 'Owner',
          key: 'owner',
          ellipsis: true,
          render: (value, record) => {
            return <Text>{`${record.firstname} ${record.lastname}`}</Text>;
          },
        },
        {
          title: 'Phone',
          key: 'phone',
          dataIndex: 'phone',
          ellipsis: true,
          render: (value) => {
            return <Text>{value}</Text>;
          },
        },
        {
          title: 'Cid',
          key: 'cid',
          dataIndex: 'key',
          render: (value) => {
            return <Text>{value}</Text>;
          },
        },
      ]}
      pagination={{ defaultPageSize: 99999 }}
    />
  );
};

export { TenantTable };
