import { notification } from 'antd';
import { DocumentSnapshot, onSnapshot } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../firebase/Auth';
import { masterRepository } from '../../firebase/db';
import { Company } from '../../models/company';

interface CurrentCompanyContextProps {
  company: Company | undefined;
  snapshot: DocumentSnapshot<Company> | undefined;
}

interface CurrentCompanyProviderProps {
  children: React.ReactNode;
}

const CurrentCompanyContext = React.createContext<CurrentCompanyContextProps>({
  company: undefined,
  snapshot: undefined,
});

const CurrentCompanyProvider: React.FC<CurrentCompanyProviderProps> = ({
  children,
}) => {
  const { currentCompany } = useContext(AuthContext);
  const [company, setCompany] = useState<Company>();
  const [snapshot, setSnapshot] = useState<DocumentSnapshot<Company>>();

  useEffect(() => {
    let unsubscribe = () => {};
    const id = currentCompany?.key;
    if (id) {
      const companyRepository = masterRepository.company.document(
        'no-company',
        'no-store'
      );
      unsubscribe = onSnapshot(companyRepository(id), {
        next: (snapshot) => {
          const company = snapshot.data();
          if (company) {
            console.warn(
              `[Current company provider] Snapshot listener obtained 1 document.`
            );
            setSnapshot(snapshot);
            setCompany(company);
          } else {
            console.warn(
              `[Current company provider] Snapshot listener failed to obtained 1 document.`
            );
            setSnapshot(undefined);
            setCompany(undefined);
          }
        },
        error: (error) => {
          notification['error']({
            message: 'Database Error',
            description: error.toString(),
          });
        },
      });
    } else {
      setCompany(undefined);
      setSnapshot(undefined);
    }

    return unsubscribe;
  }, [currentCompany]);

  return (
    <CurrentCompanyContext.Provider
      value={{
        company: company,
        snapshot: snapshot,
      }}
    >
      {children}
    </CurrentCompanyContext.Provider>
  );
};

export { CurrentCompanyProvider, CurrentCompanyContext };
