import { Row, Space, Tag } from 'antd';
import Search from 'antd/lib/input/Search';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { ProductContext } from '../../../providers/base/ProductProvider';
import { WhereClauseSearch } from '../../../providers/pagination/generics/GenericPaginationContextProvider';
import { SalesContext } from '../../../providers/pagination/SalesProvider';
import { FilterByButton } from '../product/ProductFilter';

const TransactionFilter = () => {
  const { snapshots: productSnapshots, isLoading } = useContext(ProductContext);
  const { stringFilter, setStringFilter, setFilterBy } =
    useContext(SalesContext);
  const [productFilters, setProductFilters] = useState<Set<string>>(new Set());

  useEffect(() => {
    const filters: WhereClauseSearch[] = [];

    if (stringFilter) {
      const reverseStringFilter = stringFilter.split('').reverse().join('');
      filters.push({
        fieldPath: 'transactionIdReverse',
        opStr: '>=',
        value: reverseStringFilter,
        isSearch: true,
      });
      filters.push({
        fieldPath: 'transactionIdReverse',
        opStr: '<=',
        value: reverseStringFilter + '\uf8ff',
        isSearch: true,
      });
    }

    if (productFilters.size) {
      const barcodes = _.flatMap(
        productSnapshots
          .filter((snap) =>
            Array.from(productFilters).some(
              (productRef) => productRef === snap.ref.id
            )
          )
          .map((snap) => [snap.data().barcode, ...snap.data().barcodes]),
        (item) => item
      );
      filters.push({
        fieldPath: 'barcodes',
        opStr: 'array-contains-any',
        value: barcodes,
      });
      console.log(barcodes);
    }

    setFilterBy(filters);
  }, [stringFilter, productFilters, setFilterBy, productSnapshots]);

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Row justify='space-between'>
        <Search
          allowClear
          style={{ maxWidth: 300 }}
          placeholder='Transaction number ends with'
          onSearch={(value) => {
            setStringFilter(_.trim(value));
          }}
        />
        <FilterByButton
          options={productSnapshots.map((snap) => {
            return {
              value: snap.ref.id,
              label: snap.data().title,
              search: [
                snap.data().barcode,
                ...snap.data().barcodes,
                snap.data().sku,
                ...snap.data().skus,
              ],
            };
          })}
          selected={productFilters}
          setSelected={setProductFilters}
          buttonName={'Products in'}
          loading={isLoading}
        />
      </Row>
      <Row>
        {stringFilter && (
          <Tag closable onClose={(e) => setStringFilter('')}>
            Transaction: {stringFilter}
          </Tag>
        )}
        {Array.from(productFilters).map((id) => (
          <Tag
            key={id}
            color='blue'
            closable
            onClose={() => {
              const newFilters = _.cloneDeep(productFilters);
              newFilters.delete(id);
              setProductFilters(newFilters);
            }}
          >
            Product:{' '}
            {productSnapshots.find((snap) => snap.id === id)?.data().title}
          </Tag>
        ))}
      </Row>
    </Space>
  );
};

export { TransactionFilter };
