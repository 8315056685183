import {
  CalculatorOutlined,
  CloseCircleOutlined,
  ContactsOutlined,
  DeliveredProcedureOutlined,
  FileSearchOutlined,
  LinkOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { Menu, Modal } from 'antd';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../../firebase/Auth';
import { Cart } from '../../../../models/cart';
import { Customer } from '../../../../models/customer';
import { Drawer } from '../../../../models/drawer';
import { DrawerContext } from '../../../../providers/base/DrawerProvider';
import { SearchCustomerDialog } from '../../customer/SearchCustomerDialog';
import { SearchProductDialog } from '../../product/SearchProductDialog';
import { CashInOutDialog } from '../drawer/CashInOutDialog';
import { CloseDrawerDialog } from '../drawer/CloseDrawerDialog';
import { DiscountAllDialog } from './DiscountAllDialog';

const CartMenu = ({
  drawer,
  cart,
  setCart,
  setCustomer,
}: {
  drawer?: Drawer;
  cart: Cart;
  setCart: React.Dispatch<React.SetStateAction<Cart>>;
  setCustomer: React.Dispatch<React.SetStateAction<Customer | undefined>>;
}) => {
  const { currentCompany, currentStore } = useContext(AuthContext);
  const { repository: drawerRepository } = useContext(DrawerContext);
  const history = useHistory();
  const [modal, contextHolder] = Modal.useModal();
  const displayUrl = `/display?c=${currentCompany!.key}&s=${
    currentStore!.key
  }&d=${drawer?.key}`;

  return (
    <Menu>
      {contextHolder}
      <Menu.ItemGroup title='Register'>
        <Menu.Item key='1' icon={<LinkOutlined />} disabled={!drawer}>
          <div
            onClick={() => {
              if (drawer) {
                window.open(
                  displayUrl,
                  '_blank',
                  'width=600, height=600, location=no, addressbar=no'
                );
              }
            }}
          >
            Open Customer Display
          </div>
        </Menu.Item>
        <Menu.Item
          key='2'
          style={{ display: drawer ? '' : 'none' }}
          icon={<CloseCircleOutlined />}
          onClick={() => CloseDrawerDialog(history, drawer, drawerRepository)}
        >
          Close Register
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.ItemGroup title='Drawer'>
        <Menu.Item key='3' icon={<DeliveredProcedureOutlined />} disabled>
          Open Cash Drawer
        </Menu.Item>
        <Menu.Item
          key='4'
          icon={<CalculatorOutlined />}
          style={{ display: drawer ? '' : 'none' }}
          onClick={() =>
            CashInOutDialog(currentCompany!.key, currentStore!.key, drawer)
          }
        >
          Cash In/Out
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.ItemGroup title='Customer'>
        <Menu.Item
          key='7'
          icon={<ContactsOutlined />}
          onClick={() => {
            SearchCustomerDialog(modal, setCustomer, setCart);
          }}
        >
          Select Customer
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.ItemGroup title='Cart'>
        <Menu.Item
          key='6'
          icon={<FileSearchOutlined />}
          onClick={() => SearchProductDialog(modal, setCart)}
        >
          Search Inventory
        </Menu.Item>
        <Menu.Item
          key='8'
          icon={<TagOutlined />}
          onClick={() =>
            DiscountAllDialog(setCart, currentStore?.maxDiscount ?? 100)
          }
          disabled={cart.cartItems.length <= 0}
        >
          Apply Discount (All)
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
};

export { CartMenu };
