import { DownOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Descriptions,
  Divider,
  Dropdown,
  Layout,
  Menu,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Product } from '../../models/product';
import { Size } from '../../models/size';
import { FeeContext } from '../../providers/base/FeeProvider';
import { ProductContext } from '../../providers/base/ProductProvider';
import { ProductStatusFilter, Sort } from '../common/product/ProductFilter';
import { useProductFilterSort } from '../common/product/use/useProductFilterSort';
import './label.scss';
import { LabelColumn } from './LabelColumn';
import { LabelTemplate } from './LabelTemplate';
const { Text, Title } = Typography;

type SortName = 'title' | 'updatedTime' | 'quantity' | 'price';
type SortOrder = 'asc' | 'desc';

const Labels = () => {
  const { snapshots: productSnapshots, isLoading: isProductLoading } =
    useContext(ProductContext);
  const { snapshots: feeSnapshots, isLoading: isFeesLoading } =
    useContext(FeeContext);
  const [selectedTableKeys, setSelectedTableKeys] = useState<React.Key[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [statusFilter, setStatusFilter] = useState<ProductStatusFilter>('all');
  const [stringFilter, setStringFilter] = useState('');
  const [groupFilter, setGroupFilter] = useState<Set<string>>(new Set());
  const [departmentFilter, setDepartmentFilter] = useState<Set<string>>(
    new Set()
  );
  const [taxFilter, setTaxFilter] = useState<Set<string>>(new Set());
  const [sort, setSort] = useState<Sort>({
    name: 'updatedTime',
    order: 'desc',
  });

  const filteredProducts = useProductFilterSort(
    productSnapshots,
    statusFilter,
    stringFilter,
    groupFilter,
    departmentFilter,
    taxFilter,
    sort
  );
  const [cookies, setCookie] = useCookies([
    'label-width',
    'label-height',
    'label-include-taxes',
    'label-show-sku',
    'label-show-size',
    'label-show-barcode',
    'label-truncate-title',
  ]);

  useEffect(() => {
    const prodSnaps = productSnapshots.filter((snapshot) =>
      selectedTableKeys.some((key) => key === snapshot.ref.id)
    );
    setSelectedProducts(prodSnaps.map((snap) => snap.data()));
  }, [selectedTableKeys]);

  const [width, setWidth] = useState(cookies['label-width'] ?? '3.5');
  const [height, setHeight] = useState(cookies['label-height'] ?? '1.25');
  const [showSku, setShowSku] = useState(cookies['label-show-sku'] === 'true');
  const [showBarcode, setShowBarcode] = useState(
    cookies['label-show-barcode'] === 'true'
  );
  const [showSize, setShowSize] = useState(
    cookies['label-show-size'] === 'true'
  );
  const [includeTaxes, setIncludeTaxes] = useState(
    cookies['label-include-taxes'] === 'true'
  );
  const [truncateTitle, setTruncateTitle] = useState(
    cookies['label-truncate-title'] === 'true'
  );

  return (
    <Layout>
      <Content>
        <Row justify='space-between'>
          <Title level={3}>Shelf Labels</Title>
          <Button
            disabled={!selectedTableKeys.length}
            type='primary'
            onClick={onPrint}
          >
            Print labels ({selectedTableKeys.length})
          </Button>
        </Row>
        <Space direction='vertical'>
          <LabelTemplate
            hidden
            height={height}
            width={width}
            products={selectedProducts}
            feeSnapshots={feeSnapshots}
            includeTaxes={includeTaxes}
            includeSKU={showSku}
            includeBarcode={showBarcode}
            includeSize={showSize}
            truncateTitle={truncateTitle}
          />
          <Card>
            <Text strong>Configurations</Text>
            <Descriptions bordered style={{ marginTop: 24, marginBottom: 24 }}>
              <Descriptions.Item label='Size' span={3}>
                <Space>
                  <Text>Width</Text>
                  <Select
                    value={width}
                    style={{ width: 100 }}
                    onChange={(select) => {
                      setWidth(select);
                      setCookie('label-width', select, { maxAge: 200000000 });
                    }}
                  >
                    <Select.Option value='2'>2.0 in</Select.Option>
                    <Select.Option value='2.5'>2.5 in</Select.Option>
                    <Select.Option value='2.75'>2.75 in</Select.Option>
                    <Select.Option value='3'>3.0 in</Select.Option>
                    <Select.Option value='3.5'>3.5 in</Select.Option>
                  </Select>
                  <Text>Height</Text>
                  <Select
                    value={height}
                    style={{ width: 100 }}
                    onChange={(select) => {
                      setHeight(select);
                      setCookie('label-height', select, { maxAge: 200000000 });
                    }}
                  >
                    <Select.Option value='1'>1.0 in</Select.Option>
                    <Select.Option value='1.25'>1.25 in</Select.Option>
                  </Select>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label='Product name' span={3}>
                <Checkbox
                  checked={truncateTitle}
                  onChange={() => {
                    setTruncateTitle((prev) => {
                      setCookie('label-truncate-title', !prev, {
                        maxAge: 20000000,
                      });
                      return !prev;
                    });
                  }}
                >
                  Truncate product name (word break)
                </Checkbox>
              </Descriptions.Item>
              <Descriptions.Item label='Taxes & fees' span={3}>
                <Checkbox
                  checked={includeTaxes}
                  onChange={() => {
                    setIncludeTaxes((prev) => {
                      setCookie('label-include-taxes', !prev, {
                        maxAge: 200000000,
                      });
                      return !prev;
                    });
                  }}
                >
                  Include taxes and fees to price
                </Checkbox>
              </Descriptions.Item>
              <Descriptions.Item label='SKU' span={3}>
                <Checkbox
                  checked={showSku}
                  onChange={() => {
                    setShowSku((prev) => {
                      setCookie('label-show-sku', !prev, {
                        maxAge: 200000000,
                      });
                      return !prev;
                    });
                  }}
                >
                  Show SKU
                </Checkbox>
              </Descriptions.Item>
              <Descriptions.Item label='EAN/UPC' span={3}>
                <Checkbox
                  checked={showBarcode}
                  onChange={() => {
                    setShowBarcode((prev) => {
                      setCookie('label-show-barcode', !prev, {
                        maxAge: 200000000,
                      });
                      return !prev;
                    });
                  }}
                >
                  Show EAN/UPC
                </Checkbox>
              </Descriptions.Item>
              <Descriptions.Item label='Size' span={3}>
                <Checkbox
                  checked={showSize}
                  onChange={() => {
                    setShowSize((prev) => {
                      setCookie('label-show-size', !prev, {
                        maxAge: 200000000,
                      });
                      return !prev;
                    });
                  }}
                >
                  Show product size
                </Checkbox>
              </Descriptions.Item>
            </Descriptions>

            <Text strong>Sample</Text>
            <Row style={{ marginTop: 8, marginBottom: 24 }}>
              <LabelTemplate
                height={height}
                width={width}
                products={[
                  new Product('', {
                    barcode: '1234567890',
                    sku: '123456',
                    title: 'Sample Product Name',
                    size: new Size({
                      number: 500,
                      quantity: 4,
                      unit: 'SizeUnit.ML',
                    }),
                    price: 132.97,
                  }),
                ]}
                feeSnapshots={feeSnapshots}
                includeTaxes={includeTaxes}
                includeSKU={showSku}
                includeBarcode={showBarcode}
                includeSize={showSize}
                truncateTitle={truncateTitle}
              />
            </Row>
            <Space
              direction='vertical'
              style={{ marginTop: 24, width: '100%' }}
            >
              <Row justify='space-between'>
                <Text strong>Select products</Text>
                <Space>
                  {/* <Search
                    style={{ width: 300 }}
                    placeholder='Filter products with title or barcode or sku'
                    onSearch={(value) => {
                      setStringFilter(_.trim(value));
                    }}
                  /> */}
                  <Dropdown
                    placement='bottomRight'
                    trigger={['click']}
                    overlay={
                      <Menu
                        onClick={(menu) => {
                          const [name, order] = menu.key.split('-');
                          setSort({
                            name: name as SortName,
                            order: order as SortOrder,
                          });
                        }}
                        selectedKeys={[`${sort.name}-${sort.order}`]}
                      >
                        <Menu.Item key='title-asc'>Product title A-Z</Menu.Item>
                        <Menu.Item key='title-desc'>
                          Product title Z-A
                        </Menu.Item>
                        <Menu.Item key='quantity-asc'>Low inventory</Menu.Item>
                        <Menu.Item key='quantity-desc'>
                          High inventory
                        </Menu.Item>
                        <Menu.Item key='updatedTime-desc'>
                          Updated (newest first)
                        </Menu.Item>
                        <Menu.Item key='updatedTime-asc'>
                          Updated (oldest first)
                        </Menu.Item>
                        <Menu.Item key='price-asc'>Low retail price</Menu.Item>
                        <Menu.Item key='price-desc'>
                          High retail price
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button>
                      Sort <DownOutlined />
                    </Button>
                  </Dropdown>
                </Space>
              </Row>
            </Space>
            <Table
              size='small'
              bordered
              style={{ marginTop: 16 }}
              rowSelection={{
                selectedRowKeys: selectedTableKeys,
                onChange: (selectedKeys) => {
                  setSelectedTableKeys(selectedKeys);
                },
              }}
              loading={isProductLoading}
              dataSource={filteredProducts}
              columns={LabelColumn({
                stringFilter,
              })}
              pagination={{ defaultPageSize: 20 }}
            />
          </Card>
        </Space>
      </Content>
      <Footer style={{ padding: '24px 24px' }}>
        <Divider></Divider>
        <Row justify='end'>
          <Button
            disabled={!selectedTableKeys.length}
            type='primary'
            onClick={onPrint}
          >
            Print labels ({selectedTableKeys.length})
          </Button>
        </Row>
      </Footer>
    </Layout>
  );
};

const onPrint = () => {
  const content = document.getElementById('print-label-content')?.innerHTML;
  const printHtml = `<html>
        <head>
            <meta charset="utf-8">
            <title>Labels</title>
        </head>
        <body>
            ${content}
        </body>
    </html>`;

  // get the iframe
  let iFrame = document.getElementById('print-label-iframe') as any;

  // set the iFrame contents and print
  if (iFrame) {
    iFrame.contentDocument.open();
    iFrame.contentDocument.write(printHtml);
    iFrame.contentDocument.close();
    iFrame.focus();
    iFrame.contentWindow.print();
  }
};

export default Labels;
