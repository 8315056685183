import { Cart } from './cart';
import { DocumentModel, FirestoreTimestamp } from './utils/document-model';

export interface IDrawer {
  actualCash?: number;
  cart?: Cart;
  initialCash?: number;
  name?: string;
  reason?: string;
  salesCash?: number;
  salesCredit?: number;
  salesDebit?: number;
  salesCheque?: number;
  transferCash?: number;
  createdTime?: FirestoreTimestamp;
  updatedTime?: FirestoreTimestamp;
}

export class Drawer extends DocumentModel<IDrawer> implements IDrawer {
  readonly actualCash: number;
  readonly cart: Cart;
  readonly initialCash: number;
  readonly name: string;
  readonly reason: string;
  readonly salesCash: number;
  readonly salesCredit: number;
  readonly salesDebit: number;
  readonly salesCheque: number;
  readonly transferCash: number;
  readonly createdTime?: FirestoreTimestamp;
  readonly updatedTime?: FirestoreTimestamp;

  constructor(id: string, data: IDrawer) {
    super(id, data);
    this.actualCash = data.actualCash ?? -1; // -1 represents active drawer!
    this.cart = new Cart(data.cart);
    this.initialCash = data.initialCash ?? 0;
    this.name = data.name ?? '';
    this.reason = data.reason ?? '';
    this.salesCash = data.salesCash ?? 0;
    this.salesCredit = data.salesCredit ?? 0;
    this.salesDebit = data.salesDebit ?? 0;
    this.salesCheque = data.salesCheque ?? 0;
    this.transferCash = data.transferCash ?? 0;
    this.createdTime = data.createdTime;
    this.updatedTime = data.updatedTime;
  }

  toFirestore() {
    return super.toFirestore();
  }
}
