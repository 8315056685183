import { DocumentReference } from 'firebase/firestore';
import _ from 'lodash';
import { CustomerPrice, ICustomerPrice } from './customer-price';
import { ISize, Size } from './size';
import {
  DocumentModel,
  FirestoreTimestamp,
  roundTo2Decimal,
} from './utils/document-model';
import {
  documentReference2String,
  string2DocumentReference,
} from './utils/document-reference-serializer';

export interface IProduct {
  barcode?: string;
  barcodes?: string[];
  casePrice?: number;
  caseQuantity?: number;
  description?: string;
  disabled?: boolean;
  discountExcept?: boolean;
  favourite?: boolean;
  favouriteColor?: string;
  fees?: DocumentReference[] | string[];
  groups?: DocumentReference[] | string[];
  invoiceCostPerItem?: number;
  itemcode?: string;
  lastPurchaseOrder?: DocumentReference | string;
  loyaltyExcept?: boolean;
  memo?: string;
  negative?: boolean;
  price?: number;
  prices?: ICustomerPrice[];
  quantity?: number;
  size?: ISize;
  sku?: string;
  skus?: string[];
  title?: string;
  totalCost?: number;
  variable?: boolean;
  createdTime?: FirestoreTimestamp;
  lastPurchasedTime?: FirestoreTimestamp;
  lastSoldTime?: FirestoreTimestamp;
  updatedTime?: FirestoreTimestamp;
}

export class Product extends DocumentModel<IProduct> implements IProduct {
  readonly barcode: string;
  readonly barcodes: string[];
  readonly casePrice: number;
  readonly caseQuantity: number;
  readonly description: string;
  readonly disabled: boolean;
  readonly discountExcept: boolean;
  readonly favourite: boolean;
  readonly favouriteColor: string;
  readonly fees: string[];
  readonly groups: string[];
  readonly invoiceCostPerItem: number;
  readonly itemcode: string;
  readonly lastPurchaseOrder: string;
  readonly loyaltyExcept: boolean;
  readonly memo: string;
  readonly negative: boolean;
  readonly price: number;
  readonly prices: CustomerPrice[];
  readonly quantity: number;
  readonly size: Size;
  readonly sku: string;
  readonly skus: string[];
  readonly title: string;
  readonly totalCost: number;
  readonly variable: boolean;
  readonly createdTime?: FirestoreTimestamp;
  readonly lastPurchasedTime?: FirestoreTimestamp;
  readonly lastSoldTime?: FirestoreTimestamp;
  readonly updatedTime?: FirestoreTimestamp;

  constructor(id: string, data: IProduct) {
    super(id, data);
    this.barcode = data.barcode ?? '';
    this.barcodes = data.barcodes ?? [];
    this.casePrice = data.casePrice ?? 0;
    this.caseQuantity = data.caseQuantity ?? 1;
    this.description = data.description ?? '';
    this.disabled = data.disabled ?? false;
    this.discountExcept = data.discountExcept ?? false;
    this.favourite = data.favourite ?? false;
    this.favouriteColor = data.favouriteColor ?? '';
    this.fees = data.fees?.map(documentReference2String) ?? [];
    this.groups = data.groups?.map(documentReference2String) ?? [];
    this.invoiceCostPerItem = data.invoiceCostPerItem ?? 0;
    this.itemcode = data.itemcode ?? '';
    this.lastPurchaseOrder = documentReference2String(data.lastPurchaseOrder);
    this.loyaltyExcept = data.loyaltyExcept ?? false;
    this.memo = data.memo ?? '';
    this.negative = data.negative ?? false;
    this.price = data.price ?? 0;
    this.prices = data.prices?.map((price) => new CustomerPrice(price)) ?? [];
    this.quantity = data.quantity ?? 0;
    this.size = new Size(data.size);
    this.sku = data.sku ?? '';
    this.skus = data.skus ?? [];
    this.title = data.title ?? '';
    this.totalCost = roundTo2Decimal(data.totalCost ?? 0);
    this.variable = data.variable ?? false;
    this.createdTime = data.createdTime;
    this.lastPurchasedTime = data.lastPurchasedTime;
    this.lastSoldTime = data.lastSoldTime;
    this.updatedTime = data.updatedTime;
  }

  getFullTitle() {
    return `${this.title} - ${this.sku}`;
  }

  toFirestore() {
    const iProduct = super.toFirestore();

    // Convert path strings back into firestore reference objects.
    if (this._modifiedProperties.fees) {
      iProduct.fees = _.compact(this.fees.map(string2DocumentReference));
    }
    if (this._modifiedProperties.groups) {
      iProduct.groups = _.compact(this.groups.map(string2DocumentReference));
    }
    if (this._modifiedProperties.lastPurchaseOrder) {
      iProduct.lastPurchaseOrder = string2DocumentReference(
        this.lastPurchaseOrder
      );
    }
    if (this._modifiedProperties.prices) {
      iProduct.prices = this.prices.map((price) => price.toFirestore());
    }
    return iProduct;
  }
}
