import { message, notification } from 'antd';
import {
  deleteDoc,
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  updateDoc,
} from 'firebase/firestore';
import { Size } from '../utils/Formatter';
import { Amount } from './Cart';
import { Product } from './Product';

export interface Level {
  discount: Amount;
  price: number;
  quantity: number;
}

export interface IGroup {
  code: string;
  description: string;
  favourite: boolean;
  levels: Level[];
  mixmatch: boolean;
  price: number;
  size: Size;
  synchronization: boolean;
  synchronizationSize: boolean;
  title: string;
}

interface UpdateOptions {
  hideMessage?: boolean;
}

export class Group implements IGroup {
  code: string;
  description: string;
  favourite: boolean;
  levels: Level[];
  mixmatch: boolean;
  price: number;
  size: Size;
  synchronization: boolean;
  synchronizationSize: boolean;
  title: string;

  // Non-db columns
  reference: DocumentReference;
  key: string; // Uuid
  productObjects: Product[];

  constructor(snapshot: QueryDocumentSnapshot<DocumentData>) {
    const document = snapshot.data();

    this.code = document.code ?? '';
    this.description = document.description ?? '';
    this.favourite = document.favourite ?? false;
    this.levels = document.levels ?? [];
    this.mixmatch = document.mixmatch ?? false;
    this.price = document.price ?? 0;
    this.size = document.size ?? { number: 0, unit: '' };
    this.synchronization = document.synchronization ?? false;
    this.synchronizationSize = document.synchronizationSize ?? false;
    this.title = document.title ?? '';

    // Non-db columns
    this.reference = snapshot.ref;
    this.key = snapshot.id;
    this.productObjects = [];
  }

  async update(options?: UpdateOptions) {
    try {
      await updateDoc(this.reference, {
        code: this.code,
        description: this.description,
        favourite: this.favourite,
        levels: this.levels,
        mixmatch: this.mixmatch,
        price: this.price,
        size: this.size,
        synchronization: this.synchronization,
        synchronizationSize: this.synchronizationSize,
        title: this.title,
      });
      if (!options?.hideMessage) {
        message.success('Group Updated!');
      }
      return true;
    } catch (error: any) {
      notification['error']({
        message: 'Database Error',
        description: error.toString(),
      });
      return false;
    }
  }

  async delete() {
    try {
      await deleteDoc(this.reference);
      message.success('Group Deleted!');
      return true;
    } catch (error: any) {
      notification['error']({
        message: 'Database Error',
        description: error.toString(),
      });
      return false;
    }
  }
}
