import { PictureOutlined } from '@ant-design/icons';
import { Avatar, Badge, InputNumber, List, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import _ from 'lodash';
import { Cart } from '../../../models/cart';
import { ppPrice } from '../../../utils/Formatter';
import { cartItemDialog } from './CartItemDialog';

const CartList = ({
  cart,
  setCart,
  maxDiscount,
  viewOnly,
  summaryOnly,
  refund,
  hideSummary,
  emphasizeTotal,
}: {
  cart: Cart;
  setCart: React.Dispatch<React.SetStateAction<Cart>>;
  maxDiscount: number;
  viewOnly?: boolean;
  summaryOnly?: boolean;
  refund?: boolean;
  hideSummary?: boolean;
  emphasizeTotal?: boolean;
}) => {
  return (
    <>
      <List
        style={{
          overflow: 'auto',
          flex: 1,
          display: summaryOnly ? 'none' : '',
        }}
        dataSource={cart.cartItems}
        renderItem={(cartItem) => {
          return (
            <List.Item
              style={{ cursor: viewOnly ? '' : 'pointer' }}
              onClick={
                viewOnly
                  ? undefined
                  : () => {
                      cartItemDialog(
                        cartItem,
                        () => {
                          if (cartItem.quantity > 0) {
                            const newCart = _.cloneDeep(cart);
                            newCart.computeMixAndMatchDiscounts();
                            setCart(newCart);
                          } else {
                            cart.cartItems = _.without(
                              cart.cartItems,
                              cartItem
                            );
                            const newCart = _.cloneDeep(cart);
                            newCart.computeMixAndMatchDiscounts();
                            setCart(newCart);
                          }
                        },
                        maxDiscount
                      );
                    }
              }
            >
              <List.Item.Meta
                avatar={
                  refund ? (
                    <Space>
                      Qty:
                      <InputNumber
                        value={cartItem.quantity}
                        min={0}
                        max={cartItem.returnMaxQuantity}
                        precision={0}
                        onChange={(value) => {
                          if (value || value === 0) {
                            cartItem.quantity = value;
                            const newCart = _.cloneDeep(cart);
                            setCart(newCart);
                          }
                          console.log(value);
                        }}
                      />
                    </Space>
                  ) : (
                    <Badge count={cartItem.quantity}>
                      <Avatar
                        shape='square'
                        size='large'
                        icon={<PictureOutlined />}
                      />
                    </Badge>
                  )
                }
                title={cartItem.product.title}
              />
              <Space direction='vertical' align='end' size={0}>
                <Text strong>
                  {cart.feesInclusive
                    ? ppPrice(cartItem.calculateTotal())
                    : ppPrice(cartItem.calculateSubtotal())}
                </Text>
                {!!cartItem.discounts.length && (
                  <Text type='secondary' delete>
                    {cart.feesInclusive
                      ? ppPrice(cartItem.calculateTotalWithoutDiscount())
                      : ppPrice(cartItem.calculateSubtotalWithoutDiscount())}
                  </Text>
                )}
              </Space>
            </List.Item>
          );
        }}
      >
        <div id='last-cart-item'></div>
      </List>
      <List
        style={{
          display: hideSummary ? 'none' : '',
        }}
      >
        <List.Item
          style={{
            display: cart.feesInclusive ? 'none' : '',
          }}
        >
          <List.Item.Meta title='Subtotal' />
          <Space>
            <Text strong>{ppPrice(cart.calculateSubtotal() ?? 0)}</Text>
          </Space>
        </List.Item>
        <List.Item
          style={{
            display: (cart.calculateFees('deposit') ?? 0) > 0 ? '' : 'none',
          }}
        >
          <List.Item.Meta
            title={cart.feesInclusive ? 'Deposit included' : 'Deposit'}
          />
          <Space>
            <Text strong>{ppPrice(cart.calculateFees('deposit') ?? 0)}</Text>
          </Space>
        </List.Item>
        <List.Item
          style={{
            display: (cart.calculateFees('eco') ?? 0) > 0 ? '' : 'none',
          }}
        >
          <List.Item.Meta
            title={cart.feesInclusive ? 'Eco-fee included' : 'Eco-fee'}
          />
          <Space>
            <Text strong>{ppPrice(cart.calculateFees('eco') ?? 0)}</Text>
          </Space>
        </List.Item>
        <List.Item
          style={{
            display: (cart.calculateFees('custom') ?? 0) > 0 ? '' : 'none',
          }}
        >
          <List.Item.Meta
            title={cart.feesInclusive ? 'Fees included' : 'Fees'}
          />
          <Space>
            <Text strong>{ppPrice(cart.calculateFees('custom') ?? 0)}</Text>
          </Space>
        </List.Item>
        {cart.getTaxes().map((tax) => (
          <List.Item key={tax.title}>
            <List.Item.Meta
              title={cart.feesInclusive ? `${tax.title} included` : tax.title}
            />
            <Space>
              <Text strong>{ppPrice(tax.amount)}</Text>
            </Space>
          </List.Item>
        ))}
        <List.Item
          style={{
            display: viewOnly && cart.cartItems.length ? '' : 'none',
          }}
        >
          <List.Item.Meta
            title={
              <div style={{ ...(emphasizeTotal && { fontSize: 24 }) }}>
                Total
              </div>
            }
          />
          <Space>
            <Text strong style={{ ...(emphasizeTotal && { fontSize: 24 }) }}>
              {ppPrice(cart.calculateTotal() ?? 0)}
            </Text>
          </Space>
        </List.Item>
      </List>
    </>
  );
};

export { CartList };
