import { DownOutlined } from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Menu,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
} from 'antd';
import Search from 'antd/lib/input/Search';
import Link from 'antd/lib/typography/Link';
import { QueryDocumentSnapshot, writeBatch } from 'firebase/firestore';
import _ from 'lodash';
import { useContext } from 'react';
import { db } from '../../../firebase/Fb';
import { Product } from '../../../models/product';
import { DepartmentContext } from '../../../providers/base/DepartmentProvider';
import { FeeContext } from '../../../providers/base/FeeProvider';
import { GroupContext } from '../../../providers/base/GroupProvider';
import { ProductContext } from '../../../providers/base/ProductProvider';
import { Messages, Notifications } from '../../notification/Messages';

export type ProductStatusFilter = 'all' | 'active' | 'disabled';

type SortName = 'title' | 'updatedTime' | 'quantity' | 'price';
type SortOrder = 'asc' | 'desc';
export interface Sort {
  name: SortName;
  order: SortOrder;
}

const ProductFilter = ({
  selectedKeys,
  setSelectedTableKeys,
  statusFilter,
  setStatusFilter,
  stringFilter,
  setStringFilter,
  groupFilter,
  setGroupFilter,
  departmentFilter,
  setDepartmentFilter,
  taxFilter,
  setTaxFilter,
  sort,
  setSort,
}: {
  selectedKeys?: React.Key[];
  setSelectedTableKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
  statusFilter?: ProductStatusFilter;
  setStatusFilter?: React.Dispatch<React.SetStateAction<ProductStatusFilter>>;
  stringFilter: string;
  setStringFilter: React.Dispatch<React.SetStateAction<string>>;
  groupFilter: Set<string>;
  setGroupFilter: React.Dispatch<React.SetStateAction<Set<string>>>;
  departmentFilter: Set<string>;
  setDepartmentFilter: React.Dispatch<React.SetStateAction<Set<string>>>;
  taxFilter: Set<string>;
  setTaxFilter: React.Dispatch<React.SetStateAction<Set<string>>>;
  sort: Sort;
  setSort: React.Dispatch<React.SetStateAction<Sort>>;
}) => {
  const { snapshots: productSnapshots } = useContext(ProductContext);
  const { snapshots: groupSnapshots } = useContext(GroupContext);
  const { snapshots: departmentSnapshots } = useContext(DepartmentContext);
  const { snapshots: feeSnapshots } = useContext(FeeContext);

  return (
    <>
      {statusFilter && (
        <Tabs
          activeKey={statusFilter}
          onTabClick={(activeKey) => {
            switch (activeKey) {
              case 'active':
                setStatusFilter!('active');
                break;
              case 'disabled':
                setStatusFilter!('disabled');
                break;
              default:
                setStatusFilter!('all');
                break;
            }
          }}
          tabBarExtraContent={
            <Popconfirm
              title={`Are you sure you want to delete ${
                selectedKeys!.length
              } product(s)? This can't be undone.`}
              onConfirm={async () => {
                try {
                  await batchDeletePromise(selectedKeys!, productSnapshots);
                  Messages.deleted();
                  setSelectedTableKeys!([]);
                } catch (error) {
                  Notifications.error(error);
                }
              }}
              okText={`Delete ${selectedKeys!.length} product(s)`}
              okType='danger'
              cancelText='Cancel'
            >
              <Button hidden={!selectedKeys?.length} type='primary' danger>
                Delete
              </Button>
            </Popconfirm>
          }
        >
          <Tabs.TabPane tab='All' key='all'></Tabs.TabPane>
          <Tabs.TabPane tab='Active' key='active'></Tabs.TabPane>
          <Tabs.TabPane tab='Disabled' key='disabled'></Tabs.TabPane>
        </Tabs>
      )}
      <Space direction='vertical' style={{ width: '100%' }}>
        <Row justify='space-between'>
          <Space wrap>
            <Search
              style={{ width: 300 }}
              placeholder='Filter products with title or barcode or sku'
              onSearch={(value) => {
                setStringFilter(_.trim(value));
              }}
            />
          </Space>
          <Space wrap>
            <FilterByButton
              selected={groupFilter}
              setSelected={setGroupFilter}
              options={groupSnapshots.map((snapshot) => {
                const group = snapshot.data();
                return {
                  value: snapshot.ref.id,
                  label: group.title,
                };
              })}
              buttonName='Groups in'
              loading={false}
            />
            <FilterByButton
              selected={departmentFilter}
              setSelected={setDepartmentFilter}
              options={departmentSnapshots.map((snapshot) => {
                const department = snapshot.data();
                return {
                  value: snapshot.ref.id,
                  label: department.title,
                };
              })}
              buttonName='Departments in'
              loading={false}
            />
            <FilterByButton
              selected={taxFilter}
              setSelected={setTaxFilter}
              options={feeSnapshots.map((snapshot) => {
                const fee = snapshot.data();
                return {
                  value: snapshot.ref.id,
                  label: fee.title,
                };
              })}
              buttonName='Taxes & fees in'
              loading={false}
            />
            <Dropdown
              placement='bottomRight'
              trigger={['click']}
              overlay={
                <Menu
                  onClick={(menu) => {
                    const [name, order] = menu.key.split('-');
                    setSort({
                      name: name as SortName,
                      order: order as SortOrder,
                    });
                  }}
                  selectedKeys={[`${sort.name}-${sort.order}`]}
                >
                  <Menu.Item key='title-asc'>Product title A-Z</Menu.Item>
                  <Menu.Item key='title-desc'>Product title Z-A</Menu.Item>
                  <Menu.Item key='quantity-asc'>Low inventory</Menu.Item>
                  <Menu.Item key='quantity-desc'>High inventory</Menu.Item>
                  <Menu.Item key='updatedTime-desc'>
                    Updated (newest first)
                  </Menu.Item>
                  <Menu.Item key='updatedTime-asc'>
                    Updated (oldest first)
                  </Menu.Item>
                  <Menu.Item key='price-asc'>Low retail price</Menu.Item>
                  <Menu.Item key='price-desc'>High retail price</Menu.Item>
                </Menu>
              }
            >
              <Button>
                Sort <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        </Row>
        <Row>
          {stringFilter && (
            <Tag closable onClose={(e) => setStringFilter('')}>
              Filter: {stringFilter}
            </Tag>
          )}
          {Array.from(groupFilter).map((groupId) => (
            <Tag
              key={groupId}
              color='green'
              closable
              onClose={() => {
                const newGroupFilter = _.cloneDeep(groupFilter);
                newGroupFilter.delete(groupId);
                setGroupFilter(newGroupFilter);
              }}
            >
              Group:{' '}
              {
                groupSnapshots.find((group) => group.id === groupId)?.data()
                  .title
              }
            </Tag>
          ))}
          {Array.from(departmentFilter).map((departmentId) => (
            <Tag
              key={departmentId}
              color='purple'
              closable
              onClose={() => {
                const newDepartmentFilter = _.cloneDeep(departmentFilter);
                newDepartmentFilter.delete(departmentId);
                setDepartmentFilter(newDepartmentFilter);
              }}
            >
              Department:{' '}
              {
                departmentSnapshots
                  .find((department) => department.id === departmentId)
                  ?.data().title
              }
            </Tag>
          ))}
          {Array.from(taxFilter).map((taxId) => (
            <Tag
              key={taxId}
              color='blue'
              closable
              onClose={() => {
                const newTaxFilter = _.cloneDeep(taxFilter);
                newTaxFilter.delete(taxId);
                setTaxFilter(newTaxFilter);
              }}
            >
              Taxes & fees:{' '}
              {feeSnapshots.find((fee) => fee.id === taxId)?.data().title}
            </Tag>
          ))}
        </Row>
      </Space>
    </>
  );
};

const batchDeletePromise = async (
  selectedTableKeys: React.Key[],
  productSnapshots: QueryDocumentSnapshot<Product>[]
) => {
  const batch = writeBatch(db);
  const prodSnaps = productSnapshots.filter((snapshot) =>
    selectedTableKeys.some((key) => key === snapshot.ref.id)
  );

  prodSnaps.forEach((snapshot) => {
    batch.delete(snapshot.ref);
  });

  return batch.commit();
};

interface Option {
  value: string;
  label: string;
}
const FilterByButton = ({
  options,
  selected,
  setSelected,
  buttonName,
  loading,
}: {
  options: Option[];
  selected: Set<string>;
  setSelected: React.Dispatch<React.SetStateAction<Set<string>>>;
  buttonName: string;
  loading: boolean;
}) => {
  const selectedValues = Array.from(selected);

  return (
    <Popover
      content={
        <Space direction='vertical'>
          <Select
            value={selectedValues}
            //@ts-ignore
            onSelect={(item) => {
              selected.add(item);
              const newSelected = _.cloneDeep(selected);
              setSelected(newSelected);
            }}
            //@ts-ignore
            onDeselect={(item) => {
              selected.delete(item);
              const newSelected = _.cloneDeep(selected);
              setSelected(newSelected);
            }}
            mode='multiple'
            placeholder='Filter by'
            style={{ width: '200px' }}
            options={options}
            filterOption={(input, option) => {
              return (
                // @ts-ignore
                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                // @ts-ignore
                option?.search?.some(
                  // @ts-ignore
                  (s) => s.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
              );
            }}
          />
          <Link
            onClick={() => {
              setSelected(new Set());
            }}
          >
            Clear all
          </Link>
        </Space>
      }
      trigger='click'
      placement='bottom'
    >
      <Button loading={loading}>{buttonName}</Button>
    </Popover>
  );
};

export { ProductFilter, FilterByButton };
