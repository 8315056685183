import { Descriptions, Form, Input } from 'antd';
import { FormItemStyle } from '../../common/detail/cards/card-styles';

const SupplierContent = () => (
  <Descriptions bordered>
    <Descriptions.Item label='Name' span={3}>
      <Form.Item name='name' style={FormItemStyle} rules={[{ required: true }]}>
        <Input></Input>
      </Form.Item>
    </Descriptions.Item>
    <Descriptions.Item label='Street' span={3}>
      <Form.Item name={['address', 'street']} style={FormItemStyle}>
        <Input></Input>
      </Form.Item>
    </Descriptions.Item>
    <Descriptions.Item label='City' span={3}>
      <Form.Item name={['address', 'city']} style={FormItemStyle}>
        <Input></Input>
      </Form.Item>
    </Descriptions.Item>
    <Descriptions.Item label='Province' span={3}>
      <Form.Item name={['address', 'province']} style={FormItemStyle}>
        <Input></Input>
      </Form.Item>
    </Descriptions.Item>
    <Descriptions.Item label='Postal Code' span={3}>
      <Form.Item name={['address', 'postalcode']} style={FormItemStyle}>
        <Input></Input>
      </Form.Item>
    </Descriptions.Item>
    <Descriptions.Item label='Country' span={3}>
      <Form.Item name={['address', 'country']} style={FormItemStyle}>
        <Input></Input>
      </Form.Item>
    </Descriptions.Item>
  </Descriptions>
);

export { SupplierContent };
