import { Button, Card, Form, Input, PageHeader, Space, Typography } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from '../../firebase/Fb';
const { Text } = Typography;

interface Props {
  back: Function;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const SignupCard = ({ back }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();

  const handleSignup = async (values: Store) => {
    try {
      setLoading(true);
      setError('');
      await createUserWithEmailAndPassword(auth, values.email, values.password);
      Cookies.set('posy_login_email', values.email ?? '');
      history.push('/');
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <Card>
      <PageHeader title='Create Your Free Account'>
        <Form {...layout} onFinish={handleSignup}>
          <Form.Item
            name='email'
            label='Email'
            rules={[
              {
                required: true,
                type: 'email',
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            name='password'
            label='Password'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.Password></Input.Password>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button type='primary' htmlType='submit' loading={loading}>
                Create
              </Button>
              <Button htmlType='reset' onClick={() => back()}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
          <Text type='danger'>{error}</Text>
        </Form>
      </PageHeader>
    </Card>
  );
};

export { SignupCard };
