import { Button, Popconfirm, Row } from 'antd';
import { DiscardOrSaveButtons } from './DiscardOrSaveButtons';

const DeleteDiscardOrSaveButtons = ({
  type,
  isNew,
  isDirty,
  isLoading,
  onDiscard,
  onDelete,
  onSubmit,
}: {
  type: string;
  isNew: boolean;
  isDirty: boolean;
  isLoading: boolean;
  onDiscard: () => void;
  onDelete: () => void;
  onSubmit: () => void;
}) => {
  return (
    <Row justify={isNew ? 'end' : 'space-between'}>
      {!isNew && (
        <Popconfirm
          title={`Are you sure you want to delete this ${type}? This can’t be undone.`}
          onConfirm={onDelete}
          okText={`Delete ${type}`}
          okType='danger'
          cancelText='Cancel'
        >
          <Button danger loading={isLoading}>
            Delete {type}
          </Button>
        </Popconfirm>
      )}
      <DiscardOrSaveButtons
        isNew={isNew}
        isLoading={isLoading}
        isDirty={isDirty}
        show={isDirty || isNew}
        onSubmit={onSubmit}
        onDiscardConfirm={onDiscard}
      ></DiscardOrSaveButtons>
    </Row>
  );
};

export { DeleteDiscardOrSaveButtons };
