import { DocumentSnapshot, onSnapshot } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../firebase/Auth';
import { GenericRepository } from '../../../firebase/db';

interface ProviderProps {
  children: React.ReactNode;
  id: string;
}

interface ContextProps<T> {
  snapshot: DocumentSnapshot<T> | undefined;
  isLoading: boolean;
}

const createContext = <T,>() => {
  return React.createContext<ContextProps<T>>({
    snapshot: undefined,
    isLoading: true,
  });
};

const createProvider = <T,>(
  genericRepository: GenericRepository<T>,
  ResourceContext: React.Context<ContextProps<T>>,
  className: string // For debugging purposes (logging). PLEASE DO NOT RELY ON THIS VARIABLE.
) => {
  const GenericProvider: React.FC<ProviderProps> = ({ children, id }) => {
    const { currentCompany, currentStore } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [snapshot, setSnapshot] = useState<DocumentSnapshot<T>>();

    useEffect(() => {
      const ref = genericRepository.document(
        currentCompany!.key,
        currentStore!.key
      )(id);

      const unsubscribe = onSnapshot(ref, (snapshot) => {
        console.warn(
          `[Generic provider] Snapshot listener obtained ${className} document (${id}).`
        );
        setSnapshot(snapshot);
        setIsLoading(false);
      });

      return unsubscribe;
    }, [currentCompany, currentStore, id]);

    return (
      <ResourceContext.Provider
        value={{
          snapshot: snapshot,
          isLoading: isLoading,
        }}
      >
        {children}
      </ResourceContext.Provider>
    );
  };

  return GenericProvider;
};

export { createContext, createProvider };
