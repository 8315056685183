import { masterRepository } from '../../firebase/db';
import { Group } from '../../models/group';
import {
  createGenericContext,
  createGenericProvider,
} from './generics/GenericContextProvider';

const GroupContext = createGenericContext<Group>();
const GroupProvider = createGenericProvider<Group>(
  masterRepository.group,
  GroupContext,
  'Group'
);

export { GroupContext, GroupProvider };
