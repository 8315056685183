import { QuestionCircleOutlined } from '@ant-design/icons';
import { AutoComplete, Input, Popover, Row, Space, Spin, Tag } from 'antd';
import Text from 'antd/lib/typography/Text';
import Fuse from 'fuse.js';
import { useContext, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Product } from '../../../models/product';
import { ProductContext } from '../../../providers/base/ProductProvider';
import { HighlighterStyle, SpaceStyle } from '../detail/cards/card-styles';

const barcodeColor = '#108ee9';
const skuColor = '#87d068';

type PathKey = {
  _path: string;
};
interface ProductWithPathKey extends Product, PathKey {}
interface ProductOption {
  label: JSX.Element;
  value: string;
  casequantity: number;
}

const ProductSearchInput = ({
  onAdd,
  hideTitle,
}: {
  onAdd: (path: string, caseQuantity: number) => void;
  hideTitle?: boolean;
}) => {
  const { snapshots: productSnapshots, isLoading: isProductLoading } =
    useContext(ProductContext);
  const [inputText, setInputText] = useState('');
  const [options, setOptions] = useState<ProductOption[]>([]);

  const fuse = useMemo(() => {
    return new Fuse(
      productSnapshots.map((p) => {
        return {
          ...p.data(),
          _path: p.ref.path,
        } as ProductWithPathKey;
      }),
      { shouldSort: true, threshold: 0.4, keys: ['title', 'barcode', 'sku'] }
    );
  }, [productSnapshots]);

  const onSearch = (value: string) => {
    const newOptions = fuse.search(value).map((result) => {
      return {
        label: (
          <Row justify='space-between'>
            <Highlighter
              highlightStyle={HighlighterStyle}
              searchWords={[value]}
              textToHighlight={result.item.title}
            />
            <div>
              <Tag>{result.item.size.ppFullSize()}</Tag>
              {result.item.barcode && (
                <Tag color={barcodeColor}>
                  <Highlighter
                    highlightStyle={HighlighterStyle}
                    searchWords={[value]}
                    textToHighlight={result.item.barcode}
                  ></Highlighter>
                </Tag>
              )}
              {result.item.sku && (
                <Tag color={skuColor}>
                  <Highlighter
                    highlightStyle={HighlighterStyle}
                    searchWords={[value]}
                    textToHighlight={result.item.sku}
                  ></Highlighter>
                </Tag>
              )}
              {result.item.skus.map((sku) => {
                return (
                  <Tag color={skuColor}>
                    <Highlighter
                      highlightStyle={HighlighterStyle}
                      searchWords={[value]}
                      textToHighlight={sku}
                    ></Highlighter>
                  </Tag>
                );
              })}
            </div>
          </Row>
        ),
        value: result.item._path,
        casequantity: result.item.caseQuantity,
      };
    });
    setOptions(newOptions);
    setInputText(value);
  };

  if (isProductLoading) return <Spin></Spin>;

  return (
    <Space direction='vertical' style={SpaceStyle}>
      {!hideTitle && (
        <Text strong>
          Search products{' '}
          {
            <Popover
              content={
                <div>
                  <Tag>Size</Tag>
                  <Tag color={barcodeColor}>Barcode</Tag>
                  <Tag color={skuColor}>SKU</Tag>
                </div>
              }
            >
              <QuestionCircleOutlined />
            </Popover>
          }
        </Text>
      )}
      <AutoComplete
        style={{ width: '100%' }}
        options={options}
        value={inputText}
        onChange={onSearch}
        onSelect={(value: string, option: ProductOption) => {
          onAdd(value, option.casequantity);
          setInputText('');
        }}
      >
        <Input.Search placeholder='Search products by title, barcode, or sku' />
      </AutoComplete>
    </Space>
  );
};

export { ProductSearchInput };
