import { Button, Modal, Radio, Space, Typography } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
// @ts-ignore
import NumPad from 'react-numpad';
import { Amount } from '../../../../models/amount';
import { Cart } from '../../../../models/cart';
import { ppPrice } from '../../../../utils/Formatter';
const { Text } = Typography;

const DiscountAllDialog = (
  setCart: React.Dispatch<React.SetStateAction<Cart>>,
  maxDiscount: number
) => {
  const Dialog = Modal.success({
    centered: true,
    closable: true,
    maskClosable: false,
    title: 'Apply Discount (All)',
    okText: null,
    icon: null,
    okButtonProps: {
      style: {
        display: 'none',
      },
    },
    content: (
      <ApplyDiscount
        setCart={setCart}
        onFinish={() => {
          Dialog.destroy();
        }}
        maxDiscount={maxDiscount}
      />
    ),
  });
};

const ApplyDiscount = ({
  setCart,
  onFinish,
  maxDiscount,
}: {
  setCart: React.Dispatch<React.SetStateAction<Cart>>;
  onFinish: () => void;
  maxDiscount: number;
}) => {
  const [isValid, setIsValid] = useState(true);
  const [discountType, setDiscountType] = useState<'dollar' | 'percentage'>(
    'percentage'
  );
  const [discountDollarValue, setDiscountDollarValue] = useState(0);
  const [discountPercentValue, setDiscountPercentValue] = useState(0);

  useEffect(() => {
    if (discountType === 'percentage' && discountPercentValue > maxDiscount) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [discountType, discountPercentValue]);

  return (
    <Space direction='vertical' style={{ width: '100%', marginTop: 16 }}>
      <Radio.Group
        value={discountType}
        onChange={(e) => setDiscountType(e.target.value)}
      >
        <Radio.Button value='percentage'>Percentage (%)</Radio.Button>
        {/* <Radio.Button value='dollar'>Amount ($)</Radio.Button> */}
      </Radio.Group>
      <div>
        <span
          id='numpad-text-area-center'
          className='numpad-height'
          style={{ display: discountType === 'dollar' ? '' : 'none' }}
        >
          <NumPad.Number
            position='center'
            onChange={(value: any) => {
              setDiscountDollarValue(_.round(_.toNumber(value), 2));
            }}
            placeholder={ppPrice(discountDollarValue)}
            decimal={2}
            negative={false}
          />
        </span>
        <span
          id='numpad-text-area-center'
          className='numpad-height'
          style={{ display: discountType === 'percentage' ? '' : 'none' }}
        >
          <Space>
            <NumPad.Number
              position='center'
              onChange={(value: any) => {
                setDiscountPercentValue(_.round(_.toNumber(value), 0));
              }}
              placeholder={`${discountPercentValue}%`}
              decimal={0}
              negative={false}
            />
            <Text style={{ display: isValid ? 'none' : '' }} type='danger'>
              CANNOT EXCEED {maxDiscount}%
            </Text>
          </Space>
        </span>
      </div>
      <Button
        block
        type='primary'
        style={{ marginTop: 16 }}
        disabled={!isValid}
        onClick={() => {
          setCart((prevCart) => {
            const newCart = _.cloneDeep(prevCart);
            newCart.cartItems.forEach((item) => {
              if (discountPercentValue === 0 || item.product.discountExcept) {
                item.discounts = [];
              } else {
                item.discounts = [
                  new Amount({
                    number: discountPercentValue / 100,
                    unit: 'AmountUnit.Percentage',
                  }),
                ];
              }
            });
            return newCart;
          });

          onFinish();
        }}
      >
        Apply Discount
      </Button>
    </Space>
  );
};

export { DiscountAllDialog };
