import { Cart, ICart } from './cart';
import { DocumentModel, FirestoreTimestamp } from './utils/document-model';

export interface IAuditLog {
  by?: string;
  cart?: ICart;
  createdTime?: FirestoreTimestamp;
}

export class AuditLog extends DocumentModel<IAuditLog> implements IAuditLog {
  readonly by: string;
  readonly cart: Cart;
  readonly createdTime?: FirestoreTimestamp;

  constructor(id: string, data: IAuditLog) {
    super(id, data);
    this.by = data.by ?? '';
    this.cart = new Cart(data.cart);
    this.createdTime = data.createdTime;
  }
}
