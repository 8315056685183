import { message, notification } from 'antd';
import {
  collection,
  doc,
  DocumentData,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  setDoc,
} from 'firebase/firestore';
import { db } from '../firebase/Fb';
import { IFee } from './Fee';

const StreamFees = (
  companyId: string,
  storeId: string,
  observer: {
    next?: ((snapshot: QuerySnapshot<DocumentData>) => void) | undefined;
    error?: ((error: Error) => void) | undefined;
    complete?: (() => void) | undefined;
  }
) => {
  if (companyId && storeId) {
    console.log('Requesting for Fees.');
    return onSnapshot(
      query(
        collection(db, `companies/${companyId}/stores/${storeId}/fees`),
        orderBy('title')
      ),
      observer
    );
  }
};

const createFee = async (companyId: string, storeId: string, fee: IFee) => {
  try {
    const newFeeRef = doc(
      collection(db, `companies/${companyId}/stores/${storeId}/fees`)
    );

    await setDoc(newFeeRef, {
      ...fee,
    });
    message.success('Fee Created!');
    return newFeeRef;
  } catch (error: any) {
    notification['error']({
      message: 'Database Error',
      description: error.toString(),
    });
  }
};

export { StreamFees, createFee };
