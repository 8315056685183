import { masterRepository } from '../../firebase/db';
import { Sale } from '../../models/sale';
import {
  createContext,
  createProvider,
} from './generics/GenericContextProvider';

const SaleContext = createContext<Sale>();
const SaleProvider = createProvider<Sale>(
  masterRepository.sale,
  SaleContext,
  'Sale'
);

export { SaleContext, SaleProvider };
