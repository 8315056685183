import React, { useState } from "react";
import { Drawer, Badge, Space } from "antd";
import { NotificationOutlined } from "@ant-design/icons";

const Notification = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Badge>
        <NotificationOutlined
          className="action-icon-size"
          onClick={() => setVisible(true)}
        />
      </Badge>
      <Drawer
        title={
          <Space>
            <NotificationOutlined />
            Notification
          </Space>
        }
        placement="right"
        closable={true}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <p>
          No new messages!{" "}
          <span role="img" aria-label="party">
            🎉
          </span>
        </p>
      </Drawer>
    </>
  );
};

export { Notification };
