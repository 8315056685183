import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { Address } from '../../../models/address';
import { Supplier } from '../../../models/supplier';
import { ppAddress } from '../../../utils/Formatter';

const SupplierColumn: ColumnsType<Supplier> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: (title: string, record) => title,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    ellipsis: true,
    render: (address: Address, record) => ppAddress(address),
  },
  {
    title: 'Action',
    key: 'action',
    width: 100,
    align: 'center',
    render: (text, record) => (
      <Link
        to={(location) => {
          return {
            ...location,
            pathname: `${location.pathname}/${record.key}`,
          };
        }}
      >
        Edit
      </Link>
    ),
  },
];

export { SupplierColumn };
