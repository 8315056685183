import { masterRepository } from '../../firebase/db';
import { Sale } from '../../models/sale';
import {
  createPaginationContext,
  createPaginationProvider,
} from './generics/GenericPaginationContextProvider';

const SalesContext = createPaginationContext<Sale>();
const SalesProvider = createPaginationProvider<Sale>(
  masterRepository.sale,
  SalesContext,
  'Sale'
);

export { SalesContext, SalesProvider };
