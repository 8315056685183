import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Modal, Space, Typography } from 'antd';
import { Store } from 'antd/lib/form/interface';
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import Cookies from 'js-cookie';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from '../../firebase/Fb';
const { Title, Text } = Typography;

interface LoginCardProps {
  onSignUp: Function;
}

const LoginCard = ({ onSignUp }: LoginCardProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState(Cookies.get('posy_login_email'));
  const [showForgotPasswordDialog, setShowForgotPasswordDialog] =
    useState(false);
  const forgotEmailInput = useRef(null);
  const [forgotEmailError, setForgotEmailError] = useState('');
  const history = useHistory();

  const handleLogin = async (values: Store) => {
    try {
      setLoading(true);
      setError('');
      await signInWithEmailAndPassword(auth, values.email, values.password);
      Cookies.set('posy_login_email', email ?? '');
      history.push('/');
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <Card
      bodyStyle={{ padding: 30 }}
      style={{
        width: 400,
      }}
    >
      <Title
        style={{
          textAlign: 'center',
          marginBottom: '64px',
          marginTop: '25px',
        }}
      >
        Posy.
      </Title>
      <Form
        name='normal_login'
        className='login-form'
        initialValues={{ email: email }}
        onFinish={handleLogin}
      >
        <Form.Item
          name='email'
          rules={[
            {
              required: true,
              message: 'Please input your email address!',
            },
          ]}
        >
          <Input
            disabled={loading}
            prefix={<UserOutlined className='site-form-item-icon' />}
            placeholder='Email address'
            onChange={(event) => setEmail(event.target.value)}
          />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[{ required: true, message: 'Please input your Password!' }]}
          style={{ marginBottom: 0 }}
        >
          <Input
            disabled={loading}
            prefix={<LockOutlined className='site-form-item-icon' />}
            type='password'
            placeholder='Password'
          />
        </Form.Item>
        <Space
          direction='vertical'
          style={{ width: '100%', marginBottom: '8px' }}
        >
          <Button
            type='link'
            style={{
              textAlign: 'right',
              float: 'right',
              padding: 0,
            }}
            onClick={() => {
              setShowForgotPasswordDialog(true);
              setForgotEmailError('');
            }}
          >
            Forgot password?
          </Button>
          <Modal
            destroyOnClose
            visible={showForgotPasswordDialog}
            title='Need help with your password?'
            centered
            onCancel={() => setShowForgotPasswordDialog(false)}
            onOk={() => {
              // @ts-ignore
              const email = forgotEmailInput?.current?.input.value ?? '';

              sendPasswordResetEmail(auth, email)
                .then(() => {
                  setShowForgotPasswordDialog(false);
                })
                .catch((error) => {
                  setForgotEmailError(error.message);
                })
                .finally(() => {
                  // setShowForgotPasswordDialog(false);
                });
            }}
            okText='Send'
          >
            <Space direction='vertical'>
              <Text>
                Enter the email you use for Posy, and we'll help you create a
                new password.
              </Text>
              <Input ref={forgotEmailInput} placeholder='Email address'></Input>
              <Text type='danger'>{forgotEmailError}</Text>
            </Space>
          </Modal>

          <Form.Item>
            <Space direction='vertical' style={{ width: '100%' }}>
              <Button
                loading={loading}
                htmlType='submit'
                style={{ width: '100%' }}
              >
                Log in
              </Button>
              <Button
                style={{ width: '100%' }}
                type='link'
                onClick={() => {
                  onSignUp();
                }}
              >
                Want to try for free?
              </Button>
            </Space>
          </Form.Item>
        </Space>
        <Text type='danger'>{error}</Text>
      </Form>
    </Card>
  );
};

export { LoginCard };
