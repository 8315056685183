import { Amount } from './amount';
import { IRange, Range } from './range';
import { DocumentModel } from './utils/document-model';

export interface IFee {
  amount?: Amount;
  applyOnContainerSize?: boolean;
  applyOnCreate?: boolean;
  criscodes?: number[];
  custom?: boolean;
  deposit?: boolean;
  description?: string;
  eco?: boolean;
  range?: IRange;
  tax?: boolean;
  title?: string;
}

export class Fee extends DocumentModel<IFee> implements IFee {
  readonly amount: Amount;
  readonly applyOnContainerSize: boolean;
  readonly applyOnCreate: boolean;
  readonly criscodes: number[];
  readonly custom: boolean;
  readonly deposit: boolean;
  readonly description: string;
  readonly eco: boolean;
  readonly range: Range;
  readonly tax: boolean;
  readonly title: string;

  constructor(id: string, data: IFee) {
    super(id, data);
    this.amount = new Amount(data.amount);
    this.applyOnContainerSize = data.applyOnContainerSize ?? false;
    this.applyOnCreate = data.applyOnCreate ?? false;
    this.criscodes = data.criscodes ?? [];
    this.custom = data.custom ?? false;
    this.deposit = data.deposit ?? false;
    this.description = data.description ?? '';
    this.eco = data.eco ?? false;
    this.range = new Range(data.range);
    this.tax = data.tax ?? false;
    this.title = data.title ?? '';
  }

  toFirestore() {
    return super.toFirestore();
  }
}
