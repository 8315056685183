import {
  AccountBookOutlined,
  AuditOutlined,
  ContainerOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  DollarOutlined,
  ExceptionOutlined,
  HomeOutlined,
  IdcardOutlined,
  ReconciliationOutlined,
  ScheduleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
// import { DailyProvider } from "../providers/DailyProvider";
import { Spin } from 'antd';
import React, { Suspense, useContext } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AuditLogs from '../components/auditlogs/AuditLogs';
import UpgradeAccount from '../components/error/UpgradeAccount';
import Labels from '../components/label/Labels';
import { Drawers } from '../components/sell/register/drawer/Drawers';
import { PaginatedTransactions } from '../components/sell/transaction/Transactions';
import { Suppliers } from '../components/supplier/Suppliers';
import { Transfers } from '../components/transfer/Transfers';
import { AuthContext } from '../firebase/Auth';
import { CustomerProvider } from '../providers/base/CustomerProvider';
import { EmployeeProvider } from '../providers/base/EmployeeProvider';
import { FeeProvider } from '../providers/base/FeeProvider';
import { PayrollProvider } from '../providers/base/PayrollProvider';
import { ProductProvider } from '../providers/base/ProductProvider';
import { SupplierProvider } from '../providers/base/SupplierProvider';
import { CurrentCompanyProvider } from '../providers/contexts/CurrentCompanyProvider';
import { SalesProvider } from '../providers/pagination/SalesProvider';
import { PosProvider } from '../providers/PosProvider';
import { ReportingProvider } from '../providers/ReportingProvider';
import { WithInventoryProviders } from '../providers/with/WithInventoryProviders';
import { WithPosProviders } from '../providers/with/WithPosProviders';
import { WithSalesOrderProviders } from '../providers/with/WithSalesOrderProviders';
import { CompanyConfiguration } from '../services/CompanyService';
const Summary = React.lazy(() => import('../components/overview/Summary'));
const Dashboard = React.lazy(() => import('../components/dashboard/Dashboard'));
const Employees = React.lazy(() => import('../components/employee/Employees'));
const Payrolls = React.lazy(() => import('../components/payroll/Payrolls'));
const PayrollReports = React.lazy(
  () => import('../components/payroll/PayrollReports')
);
const Customers = React.lazy(() => import('../components/customer/Customers'));
// const Schedules = React.lazy(() => import("../components/schedule/Schedules"));
const Timesheets = React.lazy(
  () => import('../components/schedule/Timesheets')
);
const Products = React.lazy(
  () => import('../components/inventory/product/Products')
);
const Inventory = React.lazy(
  () => import('../components/inventory/inventory/Inventory')
);
const PurchaseOrders = React.lazy(
  () => import('../components/purchaseorder/PurchaseOrders')
);
const Fees = React.lazy(() => import('../components/inventory/fee/Fees'));
const Departments = React.lazy(
  () => import('../components/inventory/department/Departments')
);
const Groups = React.lazy(() => import('../components/inventory/group/Groups'));
const Reports = React.lazy(() => import('../components/reports/Reports'));
const SalesOrders = React.lazy(
  () => import('../components/salesorder/SalesOrders')
);
export interface PosyRoute {
  key: string;
  title: string;
  path: string;
  exact?: boolean;
  icon?: JSX.Element;
  component: () => JSX.Element;
  hasLicense: boolean;
  itemGroup?: string; // Match PosyItemGroup.key
  isSubMenu?: true;
  subMenu?: string;
  openWithNewTab?: boolean;
}
interface PosyItemGroup {
  key: string;
  title: string;
  hasLicense: boolean;
}

const BusySpin = () => (
  <Spin
    size='large'
    style={{
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  ></Spin>
);

const getRoutes = (
  currentConfiguration: CompanyConfiguration,
  trial: boolean,
  path: string,
  userRole: 'owner' | 'manager' | 'member'
) => {
  const routes: PosyRoute[] = [];
  const WithLicenseCheck = (
    component: JSX.Element,
    license: string,
    trial: boolean,
    application: string
  ) => {
    return currentConfiguration.isActive(license) || trial ? (
      component
    ) : (
      <UpgradeAccount title={application} />
    );
  };

  // Home
  routes.push({
    key: 'overview',
    title: 'Overview',
    path: `${path}/overview`,
    icon: <HomeOutlined />,
    hasLicense: true,
    component: () => (
      <Suspense fallback={<BusySpin></BusySpin>}>
        <Summary />
      </Suspense>
    ),
  });

  if (currentConfiguration.isActive('enable_pos')) {
    // Sell Menu
    routes.push({
      key: 'sell-menu',
      title: 'Sell',
      path: '',
      icon: <DollarCircleOutlined />,
      hasLicense: currentConfiguration.isActive('enable_web_pos'),
      component: () => <></>,
      itemGroup: 'pos',
      isSubMenu: true,
    });

    // Registers
    routes.push({
      key: 'registers',
      title: 'Registers',
      path: `${path}/registers`,
      hasLicense: currentConfiguration.isActive('enable_web_pos'),
      component: () =>
        WithLicenseCheck(
          <WithPosProviders>
            <Suspense fallback={<BusySpin></BusySpin>}>
              <Drawers />
            </Suspense>
          </WithPosProviders>,
          'enable_pos',
          false,
          'Point of Sale - Registers'
        ),
      itemGroup: 'pos',
      subMenu: 'sell-menu',
    });

    // Transactions with pagination
    routes.push({
      key: 'transactions',
      title: 'Transactions',
      path: `${path}/transactions`,
      hasLicense: currentConfiguration.isActive('enable_web_pos'),
      component: () =>
        WithLicenseCheck(
          <SalesProvider>
            <Suspense fallback={<BusySpin></BusySpin>}>
              <PaginatedTransactions />
            </Suspense>
          </SalesProvider>,
          'enable_pos',
          false,
          'Point of Sale - Transactions'
        ),
      itemGroup: 'pos',
      subMenu: 'sell-menu',
    });

    // Customer
    routes.push({
      key: 'customers',
      title: 'Customers',
      path: `${path}/customers`,
      hasLicense: currentConfiguration.isActive('enable_web_pos'),
      component: () =>
        WithLicenseCheck(
          <CustomerProvider>
            <Suspense fallback={<BusySpin></BusySpin>}>
              <Customers />
            </Suspense>
          </CustomerProvider>,
          'enable_web_pos',
          false,
          'Point of Sale - Customers'
        ),
      itemGroup: 'pos',
      subMenu: 'sell-menu',
    });

    // Products Menu
    routes.push({
      key: 'products-menu',
      title: 'Products',
      path: '',
      icon: <ReconciliationOutlined />,
      hasLicense:
        currentConfiguration.isActive('enable_pos') && userRole !== 'member',
      component: () => <></>,
      itemGroup: 'pos',
      isSubMenu: true,
    });

    // Products old
    // routes.push({
    //   key: 'products_old',
    //   title: 'All products old',
    //   path: `${path}/products_old`,
    //   hasLicense: currentConfiguration.isActive('enable_pos'),
    //   component: () =>
    //     WithLicenseCheck(
    //       <Suspense fallback={<BusySpin></BusySpin>}>
    //         <Inventory_old />
    //       </Suspense>,
    //       'enable_pos',
    //       false,
    //       'Point of Sale - Products'
    //     ),
    //   itemGroup: 'pos',
    //   subMenu: 'products-menu',
    // });

    // Products
    routes.push({
      key: 'products',
      title: 'All products',
      path: `${path}/products`,
      hasLicense:
        currentConfiguration.isActive('enable_pos') && userRole !== 'member',
      component: () => {
        return WithLicenseCheck(
          <WithPosProviders>
            <Suspense fallback={<BusySpin></BusySpin>}>
              <Products />
            </Suspense>
          </WithPosProviders>,
          'enable_pos',
          false,
          'Point of Sale - Products'
        );
      },
      itemGroup: 'pos',
      subMenu: 'products-menu',
    });

    // Inventory
    routes.push({
      key: 'inventory',
      title: 'Inventory',
      path: `${path}/inventory`,
      hasLicense:
        currentConfiguration.isActive('enable_pos') && userRole !== 'member',
      component: () =>
        WithLicenseCheck(
          <WithInventoryProviders>
            <Suspense fallback={<BusySpin></BusySpin>}>
              <Inventory />
            </Suspense>
          </WithInventoryProviders>,
          'enable_pos',
          false,
          'Point of Sale - Inventory'
        ),
      itemGroup: 'pos',
      subMenu: 'products-menu',
    });

    // Transfers
    routes.push({
      key: 'transfers',
      title: 'Transfers',
      path: `${path}/transfers`,
      hasLicense:
        currentConfiguration.isActive('enable_pos') && userRole !== 'member',
      component: () =>
        WithLicenseCheck(
          <ProductProvider>
            <Suspense fallback={<BusySpin></BusySpin>}>
              <Transfers />
            </Suspense>
          </ProductProvider>,
          'enable_pos',
          false,
          'Point of Sale - Transfers'
        ),
      itemGroup: 'pos',
      subMenu: 'products-menu',
    });

    // Groups
    routes.push({
      key: 'groups',
      title: 'Groups',
      path: `${path}/groups`,
      hasLicense:
        currentConfiguration.isActive('enable_pos') && userRole !== 'member',
      component: () =>
        WithLicenseCheck(
          <PosProvider>
            <Suspense fallback={<BusySpin></BusySpin>}>
              <Groups></Groups>
            </Suspense>
          </PosProvider>,
          'enable_pos',
          false,
          'Point of Sale - Groups'
        ),
      itemGroup: 'pos',
      subMenu: 'products-menu',
    });

    // Department
    routes.push({
      key: 'departments',
      title: 'Departments',
      path: `${path}/departments`,
      hasLicense:
        currentConfiguration.isActive('enable_pos') && userRole !== 'member',
      component: () =>
        WithLicenseCheck(
          <PosProvider>
            <Suspense fallback={<BusySpin></BusySpin>}>
              <Departments></Departments>
            </Suspense>
          </PosProvider>,
          'enable_pos',
          false,
          'Point of Sale - Departments'
        ),
      itemGroup: 'pos',
      subMenu: 'products-menu',
    });

    // Taxes & Fees
    routes.push({
      key: 'taxes',
      title: 'Taxes & fees',
      path: `${path}/taxes`,
      hasLicense:
        currentConfiguration.isActive('enable_pos') && userRole !== 'member',
      component: () =>
        WithLicenseCheck(
          <PosProvider>
            <Suspense fallback={<BusySpin></BusySpin>}>
              <Fees></Fees>
            </Suspense>
          </PosProvider>,
          'enable_pos',
          false,
          'Point of Sale - Taxes & fees'
        ),
      itemGroup: 'pos',
      subMenu: 'products-menu',
    });

    // Shelf labels
    routes.push({
      key: 'shelflabels',
      title: 'Shelf labels',
      path: `${path}/shelflabels`,
      hasLicense:
        currentConfiguration.isActive('enable_pos') && userRole !== 'member',
      component: () =>
        WithLicenseCheck(
          <ProductProvider>
            <FeeProvider>
              <Suspense fallback={<BusySpin></BusySpin>}>
                <Labels />
              </Suspense>
            </FeeProvider>
          </ProductProvider>,
          'enable_pos',
          false,
          'Point of Sale - Shelf Labels'
        ),
      itemGroup: 'pos',
      subMenu: 'products-menu',
    });

    // Analytics Menu
    routes.push({
      key: 'analytics-menu',
      title: 'Analytics',
      path: '',
      icon: <DashboardOutlined />,
      hasLicense:
        currentConfiguration.isActive('enable_pos') && userRole !== 'member',
      component: () => <></>,
      itemGroup: 'pos',
      isSubMenu: true,
    });

    // Dashboard
    routes.push({
      key: 'dashboards',
      title: 'Dashboards',
      path: `${path}/dashboards`,
      hasLicense:
        currentConfiguration.isActive('enable_pos') && userRole !== 'member',
      component: () =>
        WithLicenseCheck(
          <ReportingProvider>
            <Suspense fallback={<BusySpin></BusySpin>}>
              <Dashboard />
            </Suspense>
          </ReportingProvider>,
          'enable_pos',
          false,
          'Point of Sale - Dashboards'
        ),
      itemGroup: 'pos',
      subMenu: 'analytics-menu',
    });

    // Reports
    routes.push({
      key: 'reports',
      title: 'Reports',
      path: `${path}/reports`,
      hasLicense:
        currentConfiguration.isActive('enable_web_pos') &&
        userRole !== 'member',
      component: () => (
        <WithInventoryProviders>
          <Suspense fallback={<BusySpin></BusySpin>}>
            <Reports />
          </Suspense>
        </WithInventoryProviders>
      ),
      itemGroup: 'pos',
      subMenu: 'analytics-menu',
    });

    // Purchase Order Menu
    routes.push({
      key: 'purchase-orders-menu',
      title: 'Purchase Orders',
      path: '',
      icon: <ContainerOutlined />,
      hasLicense:
        currentConfiguration.isActive('enable_pos') && userRole !== 'member',
      component: () => <></>,
      itemGroup: 'pos',
      isSubMenu: true,
    });

    // Purchase Order
    routes.push({
      key: 'purchaseorders',
      title: 'Purchase orders',
      path: `${path}/purchaseorders`,
      hasLicense:
        currentConfiguration.isActive('enable_pos') && userRole !== 'member',
      component: () =>
        WithLicenseCheck(
          <WithInventoryProviders>
            <Suspense fallback={<BusySpin></BusySpin>}>
              <PurchaseOrders />
            </Suspense>
          </WithInventoryProviders>,
          'enable_pos',
          false,
          'Point of Sale - Purchase Orders'
        ),
      itemGroup: 'pos',
      subMenu: 'purchase-orders-menu',
    });

    // Purchase Order
    routes.push({
      key: 'suppliers',
      title: 'Suppliers',
      path: `${path}/suppliers`,
      hasLicense:
        currentConfiguration.isActive('enable_pos') && userRole !== 'member',
      component: () =>
        WithLicenseCheck(
          <SupplierProvider>
            <Suspense fallback={<BusySpin></BusySpin>}>
              <Suppliers />
            </Suspense>
          </SupplierProvider>,
          'enable_pos',
          false,
          'Point of Sale - Suppliers'
        ),
      itemGroup: 'pos',
      subMenu: 'purchase-orders-menu',
    });

    // Sales Order Menu
    routes.push({
      key: 'sales-orders-menu',
      title: 'Sales Orders',
      path: '',
      icon: <AccountBookOutlined />,
      hasLicense: currentConfiguration.isActive('enable_web_pos'),
      component: () => <></>,
      itemGroup: 'pos',
      isSubMenu: true,
    });

    // Sales Order
    routes.push({
      key: 'salesorders',
      title: 'Sales orders',
      path: `${path}/salesorders`,
      hasLicense: currentConfiguration.isActive('enable_web_pos'),
      component: () => {
        return WithLicenseCheck(
          <WithSalesOrderProviders>
            <Suspense fallback={<BusySpin></BusySpin>}>
              <SalesOrders />
            </Suspense>
          </WithSalesOrderProviders>,
          'enable_web_pos',
          false,
          'Point of Sale - Sales Order'
        );
      },
      itemGroup: 'pos',
      subMenu: 'sales-orders-menu',
    });

    // Customer
    routes.push({
      key: 'socustomers',
      title: 'Customers',
      path: `${path}/socustomers`,
      hasLicense: currentConfiguration.isActive('enable_web_pos'),
      component: () =>
        WithLicenseCheck(
          <CustomerProvider>
            <Suspense fallback={<BusySpin></BusySpin>}>
              <Customers />
            </Suspense>
          </CustomerProvider>,
          'enable_web_pos',
          false,
          'Point of Sale - Customers'
        ),
      itemGroup: 'pos',
      subMenu: 'sales-orders-menu',
    });
  }

  // Admin Menu
  routes.push({
    key: 'admin-menu',
    title: 'Admin',
    path: '',
    icon: <SettingOutlined />,
    hasLicense:
      currentConfiguration.isActive('enable_pos') && userRole !== 'member',
    component: () => <></>,
    itemGroup: 'pos',
    isSubMenu: true,
  });

  // Audit Log Menu
  routes.push({
    key: 'audit-log-menu',
    title: 'Audit Logs',
    path: `${path}/auditlogs`,
    icon: <AuditOutlined />,
    hasLicense:
      currentConfiguration.isActive('enable_pos') && userRole !== 'member',
    component: () => <AuditLogs />,
    itemGroup: 'pos',
    subMenu: 'admin-menu',
  });

  // Payroll Menu
  routes.push({
    key: 'payroll-menu',
    title: 'Payroll',
    path: '',
    icon: <DollarOutlined />,
    hasLicense: currentConfiguration.isActive('enable_payroll') || trial,
    component: () => <></>,
    itemGroup: 'em',
    isSubMenu: true,
  });

  // Payroll
  routes.push({
    key: 'payroll',
    title: 'Payroll',
    path: `${path}/payroll`,
    hasLicense:
      (currentConfiguration.isActive('enable_payroll') &&
        userRole !== 'member') ||
      trial,
    component: () =>
      WithLicenseCheck(
        <CurrentCompanyProvider>
          <EmployeeProvider>
            <PayrollProvider>
              <Suspense fallback={<BusySpin></BusySpin>}>
                <Payrolls />
              </Suspense>
            </PayrollProvider>
          </EmployeeProvider>
        </CurrentCompanyProvider>,
        'enable_payroll',
        trial,
        'Employee Management - Payroll'
      ),
    itemGroup: 'em',
    subMenu: 'payroll-menu',
  });

  // Payroll Reports
  routes.push({
    key: 'payrollreports',
    title: 'Reports',
    path: `${path}/payrollreports`,
    hasLicense:
      (currentConfiguration.isActive('enable_payroll') &&
        userRole !== 'member') ||
      trial,
    component: () =>
      WithLicenseCheck(
        <CurrentCompanyProvider>
          <EmployeeProvider>
            <PayrollProvider>
              <Suspense fallback={<BusySpin></BusySpin>}>
                <PayrollReports />
              </Suspense>
            </PayrollProvider>
          </EmployeeProvider>
        </CurrentCompanyProvider>,
        'enable_payroll',
        trial,
        'Employee Management - Payroll Reports'
      ),
    itemGroup: 'em',
    subMenu: 'payroll-menu',
  });

  // Employees
  routes.push({
    key: 'employees',
    title: 'Employees',
    path: `${path}/employees`,
    icon: <IdcardOutlined />,
    hasLicense:
      (currentConfiguration.isActive('enable_payroll') &&
        userRole !== 'member') ||
      trial,
    component: () =>
      WithLicenseCheck(
        <EmployeeProvider>
          <Suspense fallback={<BusySpin></BusySpin>}>
            <Employees />
          </Suspense>
        </EmployeeProvider>,
        'enable_payroll',
        trial,
        'Employee Management - Employees'
      ),
    itemGroup: 'em',
  });

  // Timesheets
  routes.push({
    key: 'scheduling',
    title: 'Scheduling',
    path: `${path}/scheduling`,
    icon: <ScheduleOutlined />,
    hasLicense:
      currentConfiguration.isActive('enable_scheduling') &&
      userRole !== 'member',
    component: () =>
      WithLicenseCheck(
        <EmployeeProvider>
          <Suspense fallback={<BusySpin></BusySpin>}>
            <>no license</>
          </Suspense>
        </EmployeeProvider>,
        'enable_scheduling',
        false,
        'Employee Management - Scheduling'
      ),
    itemGroup: 'em',
  });

  // Timesheets
  routes.push({
    key: 'timesheets',
    title: 'Timesheets',
    path: `${path}/timesheets`,
    icon: <ExceptionOutlined />,
    hasLicense:
      currentConfiguration.isActive('enable_timesheets') &&
      userRole !== 'member',
    component: () =>
      WithLicenseCheck(
        <EmployeeProvider>
          <Suspense fallback={<BusySpin></BusySpin>}>
            <Timesheets />
          </Suspense>
        </EmployeeProvider>,
        'enable_timesheets',
        false,
        'Employee Management - Timesheets'
      ),
    itemGroup: 'em',
  });

  return routes;
};

const PosyRoutes = () => {
  const { currentConfiguration, isTrial, currentUser, currentCompany } =
    useContext(AuthContext);

  let role: 'owner' | 'manager' | 'member' = 'member';
  if (currentCompany?.key) {
    switch (currentUser.firestore?.companies[currentCompany.key]) {
      case 'owner':
        role = 'owner';
        break;

      case 'manager':
        role = 'manager';
        break;

      default:
        role = 'member';
        break;
    }
  }

  const { path } = useRouteMatch();
  const routes: PosyRoute[] = getRoutes(
    currentConfiguration,
    isTrial,
    path,
    role
  ).filter((route) => !route.isSubMenu);

  return (
    <Switch>
      {routes.map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            children={route.component}
          ></Route>
        );
      })}
    </Switch>
  );
};

const getItemGroups = (configuration: CompanyConfiguration) => {
  const menues: PosyItemGroup[] = [];

  if (configuration.isActive('enable_pos')) {
    menues.push({
      key: 'pos',
      title: 'Point of Sale',
      hasLicense: true,
    });
  }

  menues.push({
    key: 'em',
    title: 'Employee Management',
    hasLicense: configuration.isActive('enable_payroll'),
  });

  return menues;
};

export { PosyRoutes, getRoutes, getItemGroups, BusySpin };
