import _ from 'lodash';
import moment from 'moment';
import { Institution } from '../components/payroll/detail/eft';
import { getInstitutionalId, getJulianDate } from '../utils/Formatter';
import { Bank } from './bank';
import { Company } from './company';
import { Date, IDate } from './date';
import { Employee, EmployeeStatus } from './employee';
import { Payroll } from './payroll';
import { DocumentModel, FirestoreTimestamp } from './utils/document-model';
import { IWage, Wage } from './wage';

interface IHoursWorked {
  holidayHoursWorked: number;
  normalHoursWorked: number;
  overtimeHoursWorked: number;
}

class HoursWorked implements IHoursWorked {
  readonly holidayHoursWorked: number;
  readonly normalHoursWorked: number;
  readonly overtimeHoursWorked: number;

  constructor(data?: IHoursWorked) {
    this.holidayHoursWorked = data?.holidayHoursWorked ?? 0;
    this.normalHoursWorked = data?.normalHoursWorked ?? 0;
    this.overtimeHoursWorked = data?.overtimeHoursWorked ?? 0;
  }
}

export interface IPayrollEmployee {
  additionalFederalTaxDeduction?: number;
  advancedPayDeductions?: number;
  allowance?: number;
  cpp?: boolean;
  dob?: IDate;
  ei?: boolean;
  email?: string;
  emailSent?: boolean;
  endDate?: IDate;
  holidayPayEligibleAmount?: number; // Used for SK, ON
  holidayPayEligibleHours?: number;
  hoursWorked?: IHoursWorked;
  id?: string;
  otherDeductions?: number;
  payroll?: IPayrollPayloadResponse;
  phspDeductions?: number;
  startDate?: IDate;
  status?: EmployeeStatus;
  storagePath?: string;
  tip?: number;
  vacationPayout?: boolean;
  vacationUsed?: number;
  wage?: IWage;
  createdTime?: FirestoreTimestamp;
  updatedTime?: FirestoreTimestamp;
}

export class PayrollEmployee
  extends DocumentModel<IPayrollEmployee>
  implements IPayrollEmployee
{
  readonly additionalFederalTaxDeduction: number;
  readonly advancedPayDeductions: number;
  readonly allowance: number;
  readonly cpp: boolean;
  readonly dob: Date;
  readonly ei: boolean;
  readonly email: string;
  readonly emailSent: boolean;
  readonly endDate: Date;
  readonly holidayPayEligibleAmount: number; // Used for SK, ON
  readonly holidayPayEligibleHours: number;
  readonly hoursWorked: HoursWorked;
  readonly id: string;
  readonly otherDeductions: number;
  readonly payroll: PayrollPayloadResponse;
  readonly phspDeductions: number;
  readonly startDate: Date;
  readonly status: EmployeeStatus;
  readonly storagePath: string;
  readonly tip: number;
  readonly vacationPayout: boolean;
  readonly vacationUsed: number;
  readonly wage: Wage;
  readonly createdTime?: FirestoreTimestamp;
  readonly updatedTime?: FirestoreTimestamp;

  constructor(id: string, data: IPayrollEmployee) {
    super(id, data);
    this.additionalFederalTaxDeduction =
      data.additionalFederalTaxDeduction ?? 0;
    this.advancedPayDeductions = data.advancedPayDeductions ?? 0;
    this.allowance = data.allowance ?? 0;
    this.cpp = data.cpp ?? false;
    this.dob = new Date(data.dob);
    this.ei = data.ei ?? false;
    this.email = data.email ?? '';
    this.emailSent = data.emailSent ?? false;
    this.endDate = new Date(data.endDate);
    this.holidayPayEligibleAmount = data.holidayPayEligibleAmount ?? 0; // Used for SK, ON
    this.holidayPayEligibleHours = data.holidayPayEligibleHours ?? 0;
    this.hoursWorked = new HoursWorked(data.hoursWorked);
    this.id = data.id ?? '';
    this.otherDeductions = data.otherDeductions ?? 0;
    this.payroll = new PayrollPayloadResponse(data.payroll);
    this.phspDeductions = data.phspDeductions ?? 0;
    this.startDate = new Date(data.startDate);
    this.status = data.status ?? EmployeeStatus.Active;
    this.storagePath = data.storagePath ?? '';
    this.tip = data.tip ?? 0;
    this.vacationPayout = data.vacationPayout ?? false;
    this.vacationUsed = data.vacationUsed ?? 0;
    this.wage = new Wage(data.wage);
    this.createdTime = data.createdTime;
    this.updatedTime = data.updatedTime;
  }

  // TD EFT80
  generateRecordTypeLineTD(netPay: number, bank: Bank, employee: Employee) {
    const recordTypeId = 'D';

    const name = _.padEnd(
      `${employee.firstName.toUpperCase()} ${employee.lastName.toUpperCase()}`,
      23,
      ' '
    );
    if (name.length !== 23)
      throw new Error(`${employee.getFullname()}'s name is in wrong format.`);

    const paymentDueDate = '      ';
    if (paymentDueDate.length !== 6)
      throw new Error(
        `${employee.getFullname()}'s paymentDueDate is in wrong format.`
      );

    const originatorCrossReferenceNumber = _.padEnd(employee.sin, 19, ' ');
    if (originatorCrossReferenceNumber.length !== 19) {
      throw new Error('Originating Cross Reference Number is in wrong format.');
    }

    const institutionId = getInstitutionalId(
      bank.institutionNumber,
      bank.transitNumber
    );
    if (institutionId.length !== 9)
      throw new Error(
        `${employee.getFullname()}'s institution id is in wrong format.`
      );

    const accountNumber = _.padEnd(bank.accountNumber, 12, ' ');
    if (accountNumber.length !== 12)
      throw new Error(
        `${employee.getFullname()}'s account number is in wrong format.`
      );

    const payAmount = _.padStart(_.toString(_.round(netPay * 100, 0)), 10, '0');
    if (payAmount.length !== 10) throw new Error('Pay amount in wrong format.');

    const line =
      recordTypeId +
      name +
      paymentDueDate +
      originatorCrossReferenceNumber +
      institutionId +
      accountNumber +
      payAmount;

    if (line.length !== 80)
      throw new Error('generateRecordTypeLineTD line in wrong format.');

    return line + String.fromCharCode(13);
  }

  // BMO EFT80
  generateRecordTypeLine(
    recordTypeId: string,
    netPay: number,
    bank: Bank,
    employee: Employee
  ) {
    // "C or "D": Logical Record Type ID
    if (recordTypeId !== 'C') {
      throw new Error('Record type id in wrong format.');
    }

    // Numeric: Amount, two decimal places understood.
    const payAmount = _.padStart(_.toString(_.round(netPay * 100, 0)), 10, '0');
    if (payAmount.length !== 10) throw new Error('Pay amount in wrong format.');

    // 0BBBTTTTT (numeric): Payee/Payor Institution ID, where 0=constant, BBB=Bank#, TTTTT=Branch Transit #
    const institutionId = getInstitutionalId(
      bank.institutionNumber,
      bank.transitNumber
    );
    if (institutionId.length !== 9)
      throw new Error(
        `${employee.getFullname()}'s institution id is in wrong format.`
      );

    // Alphanumeric: Payee/Payor Account #, Left Justified, blank filled, no embedded blanks or dashes (except for institutions # 538, 815, 829 and 865)
    const accountNumber = _.padEnd(bank.accountNumber, 12, ' ');
    if (accountNumber.length !== 12)
      throw new Error(
        `${employee.getFullname()}'s account number is in wrong format.`
      );

    // Alphanumeric: Payee/Payor Name
    const name = _.padEnd(
      `${employee.firstName.toUpperCase()} ${employee.lastName.toUpperCase()}`,
      29,
      ' '
    );
    if (name.length !== 29)
      throw new Error(`${employee.getFullname()}'s name is in wrong format.`);

    // Alphanumeric: Cross reference #, Customer ID # to reference item, e.g. Employee SIN Number.
    const reference = employee.sin;
    if (reference.length > 19)
      throw new Error(
        `${employee.getFullname()}'s reference is in wrong format.`
      );

    let line = recordTypeId + payAmount + institutionId + accountNumber + name;
    if (line.length !== 61)
      throw new Error(`${employee.getFullname()}'s line is in wrong format.`);

    line += reference;
    if (line.length > 80)
      throw new Error(
        `${employee.getFullname()}'s detailRecordTypeLine with reference is in wrong format.`
      );

    return line + String.fromCharCode(13);
  }

  generateRecordTypeLine1464(
    recordTypeId: string,
    netPay: number,
    bank: Bank,
    employee: Employee,
    currentNumOfRecords: number,
    company: Company,
    itemTraceNumber: string,
    crlf: boolean
  ) {
    // "C or "D": Logical Record Type ID
    if (recordTypeId !== 'C') {
      throw new Error('Record type id in wrong format.');
    }

    // Numeric: Logical record count: Must be sequential (one greater than the previous record) or the file import will fail.
    const logicalRecordCount = _.padStart(
      _.toString(currentNumOfRecords + 1),
      9,
      '0'
    );
    if (logicalRecordCount.length !== 9)
      throw new Error('Logical record count in wrong format.');

    // Numeric: Origination control data: `${company.originatorId}${fileCreationId}`
    const originationControlData = `${company.originatorId}${_.padStart(
      _.toString(company.fileCreationId),
      4,
      '0'
    )}`;
    if (originationControlData.length !== 14)
      throw new Error('Origination control data in wrong format.');

    // Numeric: CPA code for payroll 200
    const transactionType = '200';
    if (transactionType.length !== 3)
      throw new Error('Transaction type in wrong format.');

    // Numeric: Amount, two decimal places understood.
    const payAmount = _.padStart(_.toString(_.round(netPay * 100, 0)), 10, '0');
    if (payAmount.length !== 10) throw new Error('Pay amount in wrong format.');

    // Date funds to be available / due date
    const fundsDate = getJulianDate(moment());
    if (fundsDate.length !== 6) throw new Error('Funds date in wrong format.');

    // 0BBBTTTTT (numeric): Payee/Payor Institution ID, where 0=constant, BBB=Bank#, TTTTT=Branch Transit #
    const institutionId = getInstitutionalId(
      bank.institutionNumber,
      bank.transitNumber
    );
    if (institutionId.length !== 9)
      throw new Error(
        `${employee.getFullname()}'s institution id is in wrong format.`
      );

    // Alphanumeric: Payee/Payor Account #, Left Justified, blank filled, no embedded blanks or dashes (except for institutions # 538, 815, 829 and 865)
    const accountNumber = _.padEnd(bank.accountNumber, 12, ' ');
    if (accountNumber.length !== 12)
      throw new Error(
        `${employee.getFullname()}'s account number is in wrong format.`
      );

    // Numeric: Item trace number
    if (itemTraceNumber.length !== 22)
      throw new Error('Item trace number in wrong format.');

    // Alphanumeric: Stored transaction type 000
    const storedTransactionType = '000';
    if (storedTransactionType.length !== 3)
      throw new Error('Stored transaction type is in wrong format.');

    // Alphanumeric: Originator’s short name (Sender name) (left justified, remainder is space filled)
    const originatorShortName = _.padEnd(company.originatorShortName, 15, ' ');
    if (originatorShortName.length !== 15)
      throw new Error('Originator short name is in wrong format.');

    // Alphanumeric: Payee/Payor Name
    const name = _.padEnd(
      `${employee.firstName.toUpperCase()} ${employee.lastName.toUpperCase()}`,
      30,
      ' '
    );
    if (name.length !== 30)
      throw new Error(`${employee.getFullname()}'s name is in wrong format.`);

    // Alphanumeric: Originator’s long name (Sender name) (left justified, remainder is space filled)
    const originatorLongName = _.padEnd(company.originatorLongName, 30, ' ');
    if (originatorLongName.length !== 30)
      throw new Error('Originator long name is in wrong format.');

    // Alphanumeric: Originating Direct Clearer's User's ID
    const originatingDirectClearerUserId = company.originatorId;
    if (originatingDirectClearerUserId.length !== 10) {
      throw new Error(
        'Originating Direct Clearers Users ID is in wrong format.'
      );
    }

    // Alphanumeric: Cross reference number
    const originatorCrossReferenceNumber = _.padEnd('', 19, ' ');
    if (originatorCrossReferenceNumber.length !== 19) {
      throw new Error('Originating Cross Reference Number is in wrong format.');
    }

    // Numeric: Institutional Id Number for Returns.
    const returnInstitutionId = getInstitutionalId(
      company.bank.institutionNumber,
      company.bank.transitNumber
    );
    if (returnInstitutionId.length !== 9)
      throw new Error(
        `${employee.getFullname()}'s institution id is in wrong format.`
      );

    // Alphanumeric: Account number for returns.
    const returnAccountNumber = _.padEnd(company.bank.accountNumber, 12, ' ');
    if (returnAccountNumber.length !== 12)
      throw new Error(
        `${employee.getFullname()}'s account number is in wrong format.`
      );

    // Alphanumeric: Originator's Sundry Information.
    const originatorSundryInformation = _.padEnd('', 15, ' ');
    if (originatorSundryInformation.length !== 15) {
      throw new Error('Originator Sundry Information is in wrong format.');
    }

    // Alphanumeric: Filler.
    const filler = _.padEnd('', 22, ' ');
    if (filler.length !== 22) {
      throw new Error('Filler is in wrong format.');
    }

    // Alphanumeric: Originator direct clearer settlement code.
    const originatorDirectClearerSettlementCode = _.padEnd('', 2, ' ');
    if (originatorDirectClearerSettlementCode.length !== 2) {
      throw new Error(
        'Originator direct clearer settlement code is in wrong format.'
      );
    }

    // Numeric: Invalid data element id.
    const invalidDataElementId = '00000000000';
    if (invalidDataElementId.length !== 11) {
      throw new Error('Invalid data element id is in wrong format.');
    }

    let line =
      recordTypeId +
      logicalRecordCount +
      originationControlData +
      transactionType +
      payAmount +
      fundsDate +
      institutionId +
      accountNumber +
      itemTraceNumber +
      storedTransactionType +
      originatorShortName +
      name +
      originatorLongName +
      originatingDirectClearerUserId +
      originatorCrossReferenceNumber +
      returnInstitutionId +
      returnAccountNumber +
      originatorSundryInformation +
      filler +
      originatorDirectClearerSettlementCode +
      invalidDataElementId;
    if (line.length !== 264)
      throw new Error(`${employee.getFullname()}'s line is in wrong format.`);

    // Segments.
    const blankSegments = _.padEnd('', 240 * 5, ' ');
    line += blankSegments;

    if (line.length !== 1464) {
      throw new Error(
        `${employee.getFullname()}'s segment line is in wrong format.`
      );
    }

    return (
      line + String.fromCharCode(13) + (crlf ? String.fromCharCode(10) : '')
    );
  }

  getDetailRecordTypeLine(
    employee: Employee,
    institution: Institution,
    company: Company,
    payroll: Payroll,
    currentNumOfRecords: number
  ): [string, number] {
    let addedNumberOfRecords = 0;
    let totalNetPay = this.payroll.response.netPay;
    if (totalNetPay <= 0) {
      throw new Error(`${employee.getFullname()}'s total net pay cannot be 0`);
    }

    let line = '';

    // Secondary bank
    if (employee.eftSecondary && employee.splitDeposit.number > 0) {
      if (totalNetPay > employee.splitDeposit.number) {
        switch (institution) {
          case Institution.TD:
            line += this.generateRecordTypeLineTD(
              employee.splitDeposit.number,
              employee.bankSecondary,
              employee
            );
            break;

          case Institution.BMO:
            line += this.generateRecordTypeLine(
              'C',
              employee.splitDeposit.number,
              employee.bankSecondary,
              employee
            );
            break;

          case Institution.ATB:
            line += this.generateRecordTypeLine1464(
              'C',
              employee.splitDeposit.number,
              employee.bankSecondary,
              employee,
              currentNumOfRecords,
              company,
              // ATB item trace number: 219921990FFFFEEEEEIIII Item trace number (2199-direct clearer Id,21990=ATB Data Centre, F=file creation number, E=Profile ID,I-sequential,number of transactions in file)(zero filled,fixed length)
              `219921990${_.padStart(
                _.toString(company.fileCreationId),
                4,
                '0'
              )}${_.padStart(company.profileId, 5, '0')}0000`,
              false
            );
            break;

          case Institution.SERVUS:
            line += this.generateRecordTypeLine1464(
              'C',
              employee.splitDeposit.number,
              employee.bankSecondary,
              employee,
              currentNumOfRecords,
              company,
              '0000000000000000000000',
              true
            );
            break;

          default:
            throw new Error('Unsupported institution');
        }

        totalNetPay -= employee.splitDeposit.number;
      } else {
        switch (institution) {
          case Institution.TD:
            line += this.generateRecordTypeLineTD(
              totalNetPay,
              employee.bankSecondary,
              employee
            );
            break;

          case Institution.BMO:
            line += this.generateRecordTypeLine(
              'C',
              totalNetPay,
              employee.bankSecondary,
              employee
            );
            break;

          case Institution.ATB:
            line += this.generateRecordTypeLine1464(
              'C',
              totalNetPay,
              employee.bankSecondary,
              employee,
              currentNumOfRecords,
              company,
              // ATB item trace number: 219921990FFFFEEEEEIIII Item trace number (2199-direct clearer Id,21990=ATB Data Centre, F=file creation number, E=Profile ID,I-sequential,number of transactions in file)(zero filled,fixed length)
              `219921990${_.padStart(
                _.toString(company.fileCreationId),
                4,
                '0'
              )}${_.padStart(company.profileId, 5, '0')}0000`,
              false
            );
            break;

          case Institution.SERVUS:
            line += this.generateRecordTypeLine1464(
              'C',
              totalNetPay,
              employee.bankSecondary,
              employee,
              currentNumOfRecords,
              company,
              '0000000000000000000000',
              true
            );
            break;

          default:
            throw new Error('Unsupported institution');
        }

        totalNetPay = 0;
      }

      addedNumberOfRecords++;
    }

    // Primary bank
    if (totalNetPay > 0) {
      switch (institution) {
        case Institution.TD:
          line += this.generateRecordTypeLineTD(
            totalNetPay,
            employee.bank,
            employee
          );
          break;

        case Institution.BMO:
          line += this.generateRecordTypeLine(
            'C',
            totalNetPay,
            employee.bank,
            employee
          );
          break;

        case Institution.ATB:
          line += this.generateRecordTypeLine1464(
            'C',
            totalNetPay,
            employee.bank,
            employee,
            currentNumOfRecords + addedNumberOfRecords,
            company,
            // ATB item trace number: 219921990FFFFEEEEEIIII Item trace number (2199-direct clearer Id,21990=ATB Data Centre, F=file creation number, E=Profile ID,I-sequential,number of transactions in file)(zero filled,fixed length)
            `219921990${_.padStart(
              _.toString(company.fileCreationId),
              4,
              '0'
            )}${_.padStart(company.profileId, 5, '0')}0000`,
            false
          );
          break;

        case Institution.SERVUS:
          line += this.generateRecordTypeLine1464(
            'C',
            totalNetPay,
            employee.bank,
            employee,
            currentNumOfRecords + addedNumberOfRecords,
            company,
            '0000000000000000000000',
            true
          );
          break;

        default:
          throw new Error('Unsupported institution');
      }

      addedNumberOfRecords++;
    }

    return [line, addedNumberOfRecords];
  }
}

const isHolidayPayEligibleAmount = (company: Company) => {
  return company.address.province === 'SK' || company.address.province === 'ON';
};

export { isHolidayPayEligibleAmount };

interface IDeductions {
  advancedPayDeductions?: number;
  cpp?: number;
  ei?: number;
  federalTax?: number;
  otherDeductions?: number;
  phspDeductions?: number;
  provincialTax?: number;
  total?: number;
}

class Deductions implements IDeductions {
  readonly advancedPayDeductions: number;
  readonly cpp: number;
  readonly ei: number;
  readonly federalTax: number;
  readonly otherDeductions: number;
  readonly phspDeductions: number;
  readonly provincialTax: number;
  readonly total: number;

  constructor(data?: IDeductions) {
    this.advancedPayDeductions = data?.advancedPayDeductions ?? 0;
    this.cpp = data?.cpp ?? 0;
    this.ei = data?.ei ?? 0;
    this.federalTax = data?.federalTax ?? 0;
    this.otherDeductions = data?.otherDeductions ?? 0;
    this.phspDeductions = data?.phspDeductions ?? 0;
    this.provincialTax = data?.provincialTax ?? 0;
    this.total = data?.total ?? 0;
  }
}

interface IGrossPay {
  allowance?: number;
  eligibleHoliday?: number;
  holiday?: number;
  overtime?: number;
  regular?: number;
  tip?: number;
  total?: number;
  vacation?: number;
}

class GrossPay implements IGrossPay {
  readonly allowance: number;
  readonly eligibleHoliday: number;
  readonly holiday: number;
  readonly overtime: number;
  readonly regular: number;
  readonly tip: number;
  readonly total: number;
  readonly vacation: number;

  constructor(data?: IGrossPay) {
    this.allowance = data?.allowance ?? 0;
    this.eligibleHoliday = data?.eligibleHoliday ?? 0;
    this.holiday = data?.holiday ?? 0;
    this.overtime = data?.overtime ?? 0;
    this.regular = data?.regular ?? 0;
    this.tip = data?.tip ?? 0;
    this.total = data?.total ?? 0;
    this.vacation = data?.vacation ?? 0;
  }
}

interface IPayrollResponse {
  deductions?: IDeductions;
  employeeId?: string;
  grossPay?: IGrossPay;
  netPay?: number;
}
class PayrollResponse implements IPayrollResponse {
  readonly deductions: Deductions;
  readonly employeeId: string;
  readonly grossPay: GrossPay;
  readonly netPay: number;

  constructor(data?: IPayrollResponse) {
    this.deductions = new Deductions(data?.deductions);
    this.employeeId = data?.employeeId ?? '';
    this.grossPay = new GrossPay(data?.grossPay);
    this.netPay = data?.netPay ?? 0;
  }
}

interface IPayrollPayloadResponse {
  response?: IPayrollResponse;
}
class PayrollPayloadResponse implements IPayrollPayloadResponse {
  readonly response: PayrollResponse;

  constructor(data?: IPayrollPayloadResponse) {
    this.response = new PayrollResponse(data?.response);
  }
}
