import { QueryDocumentSnapshot, Timestamp } from 'firebase/firestore';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Product } from '../../../../models/product';
import { string2DocumentReference } from '../../../../models/utils/document-reference-serializer';
import { DepartmentContext } from '../../../../providers/base/DepartmentProvider';
import { ProductStatusFilter, Sort } from '../ProductFilter';

const useProductFilterSort = (
  productSnapshots: QueryDocumentSnapshot<Product>[],
  statusFilter: ProductStatusFilter,
  stringFilter: string,
  groupFilter: Set<string>,
  departmentFilter: Set<string>,
  taxFilter: Set<string>,
  sort: Sort
) => {
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const { snapshots: departmentSnapshots } = useContext(DepartmentContext);

  useEffect(() => {
    const newFilteredProducts = productSnapshots
      .map((snapshot) => snapshot.data())
      // Sorting
      .sort((a, b) => {
        if (sort.name === 'updatedTime') {
          if (sort.order === 'desc') {
            return (
              ((b.updatedTime as Timestamp)?.seconds ?? 0) -
              ((a.updatedTime as Timestamp)?.seconds ?? 0)
            );
          }
          return (
            ((a.updatedTime as Timestamp)?.seconds ?? 0) -
            ((b.updatedTime as Timestamp)?.seconds ?? 0)
          );
        } else if (sort.name === 'price') {
          if (sort.order === 'asc') {
            return a.price - b.price;
          }
          return b.price - a.price;
        } else if (sort.name === 'title') {
          if (sort.order === 'asc') {
            return a.title.localeCompare(b.title);
          }
          return b.title.localeCompare(a.title);
        } else if (sort.name === 'quantity') {
          if (sort.order === 'asc') {
            return a.quantity - b.quantity;
          }
          return b.quantity - a.quantity;
        }
        return 0;
      })
      // Product status filter
      .filter((product) => {
        if (statusFilter === 'active') {
          return !product.disabled;
        } else if (statusFilter === 'disabled') {
          return product.disabled;
        } else {
          return true;
        }
      })
      // String match filter
      .filter((product) => {
        return (
          product.title.toLowerCase().includes(stringFilter.toLowerCase()) ||
          product.barcode.toLowerCase().includes(stringFilter.toLowerCase()) ||
          product.barcodes.some((barcode) =>
            barcode.toLowerCase().includes(stringFilter.toLowerCase())
          ) ||
          product.sku.toLowerCase().includes(stringFilter.toLowerCase()) ||
          product.skus.some((sku) =>
            sku.toLowerCase().includes(stringFilter.toLowerCase())
          )
        );
      })
      // Group filter
      .filter((product) => {
        if (groupFilter.size > 0) {
          return product.groups.some((groupPath) => {
            const groupRef = string2DocumentReference(groupPath);
            if (groupRef) {
              return groupFilter.has(groupRef.id);
            }
            return false;
          });
        }
        return true;
      })
      // Fees filter
      .filter((product) => {
        if (taxFilter.size > 0) {
          return product.fees.some((feePath) => {
            const feeRef = string2DocumentReference(feePath);
            if (feeRef) {
              return taxFilter.has(feeRef.id);
            }
            return false;
          });
        }
        return true;
      })
      .filter((product) => {
        if (departmentFilter.size > 0) {
          return Array.from(departmentFilter).some((departmentId) => {
            const department = departmentSnapshots.find(
              (department) => department.id === departmentId
            );
            if (department) {
              const associatedProductIds = _.compact(
                department
                  .data()
                  .products.map((productPath) =>
                    string2DocumentReference(productPath)
                  )
              ).map((departmentRef) => departmentRef.id);
              return associatedProductIds.includes(product.key);
            }
            return false;
          });
        }
        return true;
      });
    setFilteredProducts(newFilteredProducts);
  }, [
    productSnapshots,
    statusFilter,
    stringFilter,
    sort,
    groupFilter,
    departmentFilter,
    taxFilter,
  ]);

  return filteredProducts;
};

export { useProductFilterSort };
