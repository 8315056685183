import _ from 'lodash';
import moment from 'moment';
import { getInstitutionalId, getJulianDate } from '../utils/Formatter';
import { Company } from './company';
import { Date, IDate } from './date';
import { getNthFromPayroll } from './pay-period-generator';
import { PayrollEmployee } from './payroll-employee';
import { PayrollSummary } from './payroll-summary';
import { DocumentModel, FirestoreTimestamp } from './utils/document-model';

export enum PayPeriodType {
  Weekly = 'Weekly',
  BiWeekly = 'Bi-Weekly',
  SemiMonthly = 'Semi-Monthly',
  Monthly = 'Monthly',
}
export enum PayrollStatus {
  Open = 'Open',
  Processing = 'Processing',
  Processed = 'Processed',
  Error = 'Error',
}

interface IPayroll {
  endDate?: IDate;
  period?: number;
  periodMonth?: number;
  periodYear?: number;
  startDate?: IDate;
  status?: PayrollStatus;
  storagePath?: string;
  summary?: PayrollSummary;
  totalPeriods?: number;
  createdTime?: FirestoreTimestamp;
  runTime?: FirestoreTimestamp;
  updatedTime?: FirestoreTimestamp;
}

export class Payroll extends DocumentModel<IPayroll> implements IPayroll {
  readonly endDate: IDate;
  readonly period: number;
  readonly periodMonth: number;
  readonly periodYear: number;
  readonly startDate: IDate;
  readonly status: PayrollStatus;
  readonly storagePath: string;
  readonly summary: PayrollSummary;
  readonly totalPeriods: number;
  readonly createdTime?: FirestoreTimestamp;
  readonly runTime?: FirestoreTimestamp;
  readonly updatedTime?: FirestoreTimestamp;

  constructor(id: string, data: IPayroll) {
    super(id, data);
    this.endDate = new Date(data.endDate);
    this.period = data.period ?? -1;
    this.periodMonth = data.periodMonth ?? -1;
    this.periodYear = data.periodYear ?? -1;
    this.startDate = new Date(data.startDate);
    this.status = data.status ?? PayrollStatus.Error;
    this.storagePath = data.storagePath ?? '';
    this.summary = new PayrollSummary(data.summary);
    this.totalPeriods = data.totalPeriods ?? -1;
    this.createdTime = data.createdTime;
    this.runTime = data.runTime;
    this.updatedTime = data.updatedTime;
  }

  isRunerable = (payrolls: Payroll[], company: Company | undefined) => {
    if (!payrolls.length || !company) {
      return false;
    }
    if (this.period === 1) {
      return true;
    }
    const previous = getNthFromPayroll(-1, this.key, payrolls, company);
    if (previous && previous.status === PayrollStatus.Processed) {
      return true;
    }
    return false;
  };

  isUndoable = (payrolls: Payroll[], company: Company | undefined) => {
    if (!payrolls.length || !company) {
      return false;
    }
    if (this.period === this.totalPeriods) {
      return true;
    }
    const next = getNthFromPayroll(1, this.key, payrolls, company);
    if (!next || next.status === PayrollStatus.Open) {
      return true;
    }
    return false;
  };

  // TD
  getBastchHeaderRecordTypeHLine = (company: Company) => {
    const recordTypeId = 'H';
    const originatorId = _.padStart(company.originatorId, 10, ' ');
    const paymentType = 'C';
    const CPACode = '200';
    const paymentDueDate = moment().add(2, 'days').format('DDMMYY'); // Could be +2 days from today.
    const originatorShortName = _.padEnd(company.originatorShortName, 15, ' ');
    const institutionalId = getInstitutionalId(
      company.bank.institutionNumber,
      company.bank.transitNumber
    );
    const accountId = _.padEnd(company.bank.accountNumber, 12, ' ');
    const fileCreationId = _.padStart(
      _.toString(company.fileCreationId),
      4,
      '0'
    );
    const space19Filler = _.padStart('', 19, ' ');

    const line =
      recordTypeId +
      originatorId +
      paymentType +
      CPACode +
      paymentDueDate +
      originatorShortName +
      institutionalId +
      accountId +
      fileCreationId +
      space19Filler;

    if (line.length !== 80) {
      throw new Error('getBastchHeaderRecordTypeHLine in wrong format.');
    }

    return line + String.fromCharCode(13) + String.fromCharCode(10);
  };

  // BMO
  getBatchHeaderRecordTypeALine = (company: Company) => {
    const recordTypeId = 'A';
    const originatorId = _.padStart(company.originatorId, 10, ' ');
    const fileCreationId = _.padStart(
      _.toString(company.fileCreationId),
      4,
      '0'
    );
    const creationDate = getJulianDate(moment());
    const destinationDataCenterCode = _.padStart(
      company.destinationCode,
      5,
      '0'
    );

    const line =
      recordTypeId +
      originatorId +
      fileCreationId +
      creationDate +
      destinationDataCenterCode;
    if (line.length !== 1 + 10 + 4 + 6 + 5) {
      throw new Error('getBatchHeaderRecordTypeALine in wrong format.');
    }

    return line + String.fromCharCode(13) + String.fromCharCode(10);
  };

  // ATB
  // originatorId = `21990${profile id}`
  // destinationCode = `21990`
  getBatchHeaderRecordTypeALine1464 = (company: Company, crlf: boolean) => {
    const recordTypeId = 'A';
    const recordCount = '000000001';
    const originatorId = _.padStart(company.originatorId, 10, ' ');
    const fileCreationId = _.padStart(
      _.toString(company.fileCreationId),
      4,
      '0'
    );
    const creationDate = getJulianDate(moment());
    const destinationDataCenterCode = _.padStart(
      company.destinationCode,
      5,
      '0'
    );
    const space20Filler = _.padStart('', 20, ' ');
    const currencyCode = 'CAD';
    const space1406Filler = _.padStart('', 1406, ' ');

    const line =
      recordTypeId +
      recordCount +
      originatorId +
      fileCreationId +
      creationDate +
      destinationDataCenterCode +
      space20Filler +
      currencyCode +
      space1406Filler;

    if (line.length !== 1 + 9 + 10 + 4 + 6 + 5 + 20 + 3 + 1406) {
      throw new Error('getBatchHeaderRecordTypeALine1464 in wrong format.');
    }

    return (
      line + String.fromCharCode(13) + (crlf ? String.fromCharCode(10) : '')
    );
  };

  getBatchHeaderRecordTypeXLine = (company: Company) => {
    const recordTypeId = 'X';
    const batchPaymentType = 'C';
    const transactionTypeCode = _.padStart('200', 3, '0');
    const transactionDate = getJulianDate(moment());
    const shortName = _.padEnd(company.originatorShortName, 15, ' ');
    const longName = _.padEnd(company.originatorLongName, 30, ' ');
    const institutionalId = getInstitutionalId(
      company.bank.institutionNumber,
      company.bank.transitNumber
    );
    const accountId = _.padEnd(company.bank.accountNumber, 12, ' ');

    const line =
      recordTypeId +
      batchPaymentType +
      transactionTypeCode +
      transactionDate +
      shortName +
      longName +
      institutionalId +
      accountId;
    if (line.length !== 1 + 1 + 3 + 6 + 15 + 30 + 9 + 12) {
      throw new Error('getBatchHeaderRecordTypeXLine in wrong format.');
    }
    return line + String.fromCharCode(13);
  };

  getBatchControlRecordTypeTLine = (
    payrollEmployees: PayrollEmployee[],
    numOfRecordTypes: number
  ) => {
    const recordTypeId = 'T';

    const batchRecordCount = _.padStart(_.toString(numOfRecordTypes), 8, '0');
    if (batchRecordCount.length !== 8)
      throw new Error('Batch record count in wrong format.');

    const batchAmount = _.padStart(
      _.toString(
        _.round(
          payrollEmployees.reduce(
            (prev, curr) => prev + curr.payroll.response.netPay,
            0
          ) * 100,
          2
        )
      ),
      14,
      '0'
    );
    if (batchAmount.length !== 14)
      throw new Error('Batch record count in wrong format.');

    const filler = _.padStart('', 57, ' ');

    const line = recordTypeId + batchRecordCount + batchAmount + filler;
    if (line.length !== 80)
      throw new Error('getBatchControlRecordTypeTLine line in wrong format.');

    return line + String.fromCharCode(13);
  };

  getBatchControlRecordTypeYLine = (
    payrollEmployees: PayrollEmployee[],
    numOfRecordTypes: number
  ) => {
    const recordTypeId = 'Y';
    const batchPaymentType = 'C';

    const batchRecordCount = _.padStart(_.toString(numOfRecordTypes), 8, '0');
    if (batchRecordCount.length !== 8)
      throw new Error('Batch record count in wrong format.');

    const batchAmount = _.padStart(
      _.toString(
        _.round(
          payrollEmployees.reduce(
            (prev, curr) => prev + curr.payroll.response.netPay,
            0
          ) * 100,
          2
        )
      ),
      14,
      '0'
    );
    if (batchAmount.length !== 14)
      throw new Error('Batch record count in wrong format.');

    const line =
      recordTypeId + batchPaymentType + batchRecordCount + batchAmount;
    if (line.length !== 24)
      throw new Error('BatchControlRecordTypeYLine in wrong format.');

    return line + String.fromCharCode(13);
  };

  getFileControlTrailerRecordTypeZ = (
    payrollEmployees: PayrollEmployee[],
    numOfCRecordTypes: number
  ) => {
    const recordTypeId = 'Z';
    const totalAmountD = _.padStart('', 14, '0');
    const totalCountD = _.padStart('', 5, '0');

    const totalAmountC = _.padStart(
      _.toString(
        _.round(
          payrollEmployees.reduce(
            (prev, curr) => prev + curr.payroll.response.netPay,
            0
          ) * 100,
          2
        )
      ),
      14,
      '0'
    );
    const totalCountC = _.padStart(_.toString(numOfCRecordTypes), 5, '0');

    const line =
      recordTypeId + totalAmountD + totalCountD + totalAmountC + totalCountC;
    if (line.length !== 1 + 14 + 5 + 14 + 5) {
      throw new Error('getFileControlTrailerRecordTypeZ in wrong format.');
    }

    return line + String.fromCharCode(13);
  };

  getFileControlTrailerRecordTypeZ1464 = (
    payrollEmployees: PayrollEmployee[],
    numOfRecordTypes: number,
    numOfCTypes: number,
    company: Company,
    payroll: Payroll
  ) => {
    const recordTypeId = 'Z';

    const logicalRecordCount = _.padStart(
      _.toString(numOfRecordTypes + 1),
      9,
      '0'
    );
    if (logicalRecordCount.length !== 9)
      throw new Error('Logical record count is in wrong format.');

    const originationControlData = `${company.originatorId}${_.padStart(
      _.toString(company.fileCreationId),
      4,
      '0'
    )}`;
    if (originationControlData.length !== 14)
      throw new Error('Origination control data in wrong format.');

    const totalAmountD = _.padStart('', 14, '0');
    const totalCountD = _.padStart('', 8, '0');
    const totalAmountC = _.padStart(
      _.toString(
        _.round(
          payrollEmployees.reduce(
            (prev, curr) => prev + curr.payroll.response.netPay,
            0
          ) * 100,
          0
        )
      ),
      14,
      '0'
    );
    const totalCountC = _.padStart(_.toString(numOfCTypes), 8, '0');
    const numberOfErrorCorrections =
      '00000000000000000000000000000000000000000000';
    const filler = _.padStart('', 1352, ' ');

    const line =
      recordTypeId +
      logicalRecordCount +
      originationControlData +
      totalAmountD +
      totalCountD +
      totalAmountC +
      totalCountC +
      numberOfErrorCorrections +
      filler;

    if (line.length !== 1464)
      throw new Error('Record type z 1464 is in wrong format.');

    return line;
  };
}
