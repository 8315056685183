import { Button, Form, Input, PageHeader, Typography } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { httpsCallable } from 'firebase/functions';
import { useContext, useState } from 'react';
import { AuthContext } from '../../firebase/Auth';
import { fn } from '../../firebase/Fb';
const { Text } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 16, span: 16 },
};

const ProfileForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { reloadCurrentUser } = useContext(AuthContext);

  const handleSubmit = async (values: Store) => {
    try {
      setLoading(true);
      setError('');
      const fnUpdateUserInfo = httpsCallable(fn, 'updateUserInfo');
      await fnUpdateUserInfo({
        firstname: values.firstname,
        lastname: values.lastname,
      });
      reloadCurrentUser();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageHeader title='Profile Info'>
      <Form {...layout} onFinish={handleSubmit}>
        <Form.Item
          name='firstname'
          label='Firstname'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          name='lastname'
          label='Lastname'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button loading={loading} htmlType='submit' type='primary' block>
            Next
          </Button>
        </Form.Item>
        <Text type='danger'>{error}</Text>
      </Form>
    </PageHeader>
  );
};

export { ProfileForm };
