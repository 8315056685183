import { Spin } from 'antd';
import {
  onAuthStateChanged,
  signOut,
  User as FirebaseUser,
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Company,
  CompanyConfiguration,
  getCompanies,
  Store,
  UserInfo,
} from '../services/CompanyService';
import { auth, db } from './Fb';

interface AuthProviderProps {
  children: React.ReactNode;
}

interface User {
  firebase: FirebaseUser | null;
  firestore?: UserInfo;
}

interface AuthContextProps {
  loading: boolean;
  currentUser: User;
  currentCompany: Company | null;
  currentStore: Store | null;
  currentConfiguration: CompanyConfiguration;
  companies: Company[];
  isTrial: boolean;
  trialDaysLeft: number;
  reloadCurrentUser: Function;
  signoutCurrentUser: Function;
  setCompany: Function;
}

const AuthContext = React.createContext<AuthContextProps>({
  loading: true,
  currentUser: { firebase: null },
  currentCompany: null,
  currentStore: null,
  currentConfiguration: new CompanyConfiguration(new Map()),
  companies: [],
  isTrial: false,
  trialDaysLeft: 0,
  reloadCurrentUser: () => {},
  signoutCurrentUser: () => {},
  setCompany: () => {},
});

const getCompanyIdFromUrlPath = () => {
  return window.location.pathname.split('/')[2];
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<User>({ firebase: null });
  const [currentCompany, setCurrentCompany] = useState<Company | null>(null);
  const [currentStore, setCurrentStore] = useState<Store | null>(null);
  const [isTrial, setIsTrial] = useState<boolean>(false);
  const [trialDaysLeft, setTrialDaysLeft] = useState(0);
  const [currentConfiguration, setCurrentConfiguration] =
    useState<CompanyConfiguration>(new CompanyConfiguration(new Map()));
  const [companies, setCompanies] = useState<Company[]>([]);
  const [pending, setPending] = useState(true);
  const history = useHistory();

  const reloadCurrentUser = () => {
    setUserContext(currentUser.firebase);
  };

  const signoutCurrentUser = () => {
    /**
     * Setting it to null is required because firebase is realtime and it might trigger
     * a query which could potentially get permission denied because the user is now
     * logged out!
     */
    setLoading(true);
    setCurrentCompany(null);
    setCurrentStore(null);
    signOut(auth);
  };

  const setTrial = (company: Company) => {
    const TRIALLENGTH = 30;
    if (company?.trial) {
      setIsTrial(true);
      if (company?.createdTime) {
        const diff = moment().diff(company.createdTime.toDate(), 'days');
        if (diff <= TRIALLENGTH) {
          // 30 day trial.
          setTrialDaysLeft(TRIALLENGTH - diff);
        }
      }
      return;
    }
    setIsTrial(false);
    setTrialDaysLeft(0);
  };

  const setCompany = (company?: Company) => {
    if (company) {
      setCurrentCompany(company);
      // Todo: Perhaps consolidate configuration into Company object in the future.
      setCurrentConfiguration(
        new CompanyConfiguration(
          new Map(Object.entries(company.configurations ?? {}))
        )
      );
      setTrial(company);
      if (company.stores.length > 0) {
        setCurrentStore(company.stores[0]); // Assume we have 1-to-1 mapping between company and store for now.
      } else {
        setCurrentStore(null);
      }
      if (getCompanyIdFromUrlPath()) {
        const newPathnameArray = window.location.pathname.split('/');
        newPathnameArray[2] = company.key;
        history.push(newPathnameArray.join('/'));
      }
    } else {
      setCurrentCompany(null);
      setCurrentStore(null);
      setIsTrial(false);
      setTrialDaysLeft(0);
    }
    setLoading(false);
  };

  const setUserContext = async (user: FirebaseUser | null) => {
    if (user?.uid) {
      console.log('Requesting for User.');
      // UserInfo
      const userInfo = (await (
        await getDoc(doc(db, `users/${user.uid}`))
      ).data()) as UserInfo;
      setCurrentUser({
        firebase: user,
        firestore: userInfo,
      });

      // List of companies associated with the user.
      const companies = await getCompanies(userInfo);
      setCompanies(companies);

      // Current company
      if (companies.length > 0) {
        const companyId = getCompanyIdFromUrlPath();
        if (companyId) {
          const company = companies.find((c) => c.key === companyId);
          if (company) {
            setCompany(company);
          } else {
            history.replace('/403');
          }
        } else {
          setCompany(companies[0]); // Todo: ability to set default company for users more than 1 company.
        }
      } else {
        setCompany();
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser({
        firebase: user,
      });
      await setUserContext(user);
      setPending(false);
    });

    return () => unsubscribe();
  }, []);

  if (pending) {
    return (
      <Spin
        size='large'
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    );
  }

  return (
    <AuthContext.Provider
      value={{
        loading,
        currentUser,
        currentCompany,
        currentStore,
        currentConfiguration,
        companies,
        isTrial,
        trialDaysLeft,
        reloadCurrentUser,
        signoutCurrentUser,
        setCompany,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
