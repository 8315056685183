import {
  Button,
  Card,
  Descriptions,
  InputNumber,
  Layout,
  Row,
  Space,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { increment, writeBatch } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { db } from '../../firebase/Fb';
import { Product } from '../../models/product';
import { ProductContext } from '../../providers/base/ProductProvider';
import { ppPrice } from '../../utils/Formatter';
import { ProductSearchInput } from '../common/product/ProductSearchInput';
import { Messages, Notifications } from '../notification/Messages';
import { ProductTransfer } from './ProductTransfer';

const Transfers = () => {
  const { snapshots } = useContext(ProductContext);
  const [numberOfUnits, setNumberOfUnits] = useState<number>(0);

  const [fromProduct, setFromProduct] = useState<Product>();
  const [toProduct, setToProduct] = useState<Product>();

  const [fromSizeQuantity, setFromSizeQuantity] = useState<number>(0);
  const [toSizeQuantity, setToSizeQuantity] = useState<number>(0);

  const [fromNewQuantity, setFromNewQuantity] = useState<number>(0);
  const [toNewQuantityDelta, setToNewQuantityDelta] = useState<number>(0);

  const [fromNewTotalCostDelta, setFromNewTotalCostDelta] = useState<number>(0);
  const [toNewTotalCostDelta, setToNewTotalCostDelta] = useState<number>(0);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFromSizeQuantity(fromProduct?.size.quantity ?? 0);
  }, [fromProduct]);

  useEffect(() => {
    setToSizeQuantity(toProduct?.size.quantity ?? 0);
  }, [toProduct]);

  useEffect(() => {
    if (
      fromProduct &&
      toProduct &&
      numberOfUnits > 0 &&
      fromSizeQuantity &&
      toSizeQuantity
    ) {
      setFromNewQuantity(fromProduct.quantity - numberOfUnits);
      setToNewQuantityDelta(
        (fromSizeQuantity / toSizeQuantity) * numberOfUnits
      );
    }
  }, [fromSizeQuantity, toSizeQuantity, fromProduct, toProduct, numberOfUnits]);

  useEffect(() => {
    if (fromProduct) {
      const costPerQuantity = fromProduct.totalCost / fromProduct.quantity;
      const delta = costPerQuantity * numberOfUnits;
      setFromNewTotalCostDelta(delta * -1);
      setToNewTotalCostDelta(delta);
    }
  }, [fromProduct, toProduct, numberOfUnits]);

  useEffect(() => {
    if (!Number.isSafeInteger(toNewQuantityDelta)) {
      setError('Transfer quantity must be a whole number.');
    } else {
      setError('');
    }
  }, [toNewQuantityDelta]);

  const discard = () => {
    setFromProduct(undefined);
    setNumberOfUnits(0);
    setToProduct(undefined);
  };

  const save = async () => {
    try {
      setLoading(true);
      const batch = writeBatch(db);
      const fromProductSnap = snapshots.find(
        (snap) => snap.id === fromProduct!.key
      )!;
      const toProductSnap = snapshots.find(
        (snap) => snap.id === toProduct!.key
      )!;

      batch.update(fromProductSnap.ref, {
        quantity: increment(numberOfUnits * -1),
        totalCost: increment(fromNewTotalCostDelta),
      });

      batch.update(toProductSnap.ref, {
        quantity: increment(toNewQuantityDelta),
        totalCost: increment(toNewTotalCostDelta),
      });

      await batch.commit();
      discard();
    } catch (error) {
      Notifications.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Content>
        <Row justify='space-between' style={{ paddingBottom: 16 }}>
          <Title level={3}>Transfers</Title>
          <Space>
            <Button
              loading={loading}
              hidden={!fromProduct || !toProduct}
              onClick={() => {
                discard();
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              hidden={!fromProduct || !toProduct}
              type='primary'
              disabled={!!error && !!fromProduct && !!toProduct}
              onClick={save}
            >
              Confirm transfer
            </Button>
          </Space>
        </Row>
        <Card>
          <Text strong>From:</Text>
          <ProductSearchInput
            hideTitle
            onAdd={(path) => {
              const p = snapshots
                .find((snapshot) => snapshot.ref.path === path)
                ?.data();
              if (p) {
                setFromProduct(p);
              } else {
                Messages.info('Product not found.');
              }
            }}
          />
          <ProductTransfer
            product={fromProduct}
            sizeQuantity={fromSizeQuantity}
            setSizeQuantity={setFromSizeQuantity}
          />
        </Card>
        {fromProduct && (
          <Card style={{ marginTop: 24 }}>
            <Text strong>How many stock units do you want to transfer? </Text>
            <InputNumber
              precision={0}
              min={1}
              max={fromProduct.quantity}
              value={numberOfUnits}
              onChange={setNumberOfUnits}
            ></InputNumber>
          </Card>
        )}
        {fromProduct && numberOfUnits > 0 && (
          <Card style={{ marginTop: 24 }}>
            <Text strong>To:</Text>
            <ProductSearchInput
              hideTitle
              onAdd={(path) => {
                const p = snapshots
                  .find((snapshot) => snapshot.ref.path === path)
                  ?.data();
                if (p) {
                  setToProduct(p);
                } else {
                  Messages.info('Product not found.');
                }
              }}
            />
            <ProductTransfer
              product={toProduct}
              sizeQuantity={fromSizeQuantity}
              setSizeQuantity={setToSizeQuantity}
            ></ProductTransfer>
          </Card>
        )}
        {fromProduct && toProduct && (
          <Card style={{ marginTop: 24 }}>
            <Space direction='vertical'>
              <Text strong>Transfer Summary</Text>
              <Space wrap>
                <Descriptions bordered size='small' style={{ marginTop: 16 }}>
                  <Descriptions.Item label='Title' span={3}>
                    {fromProduct.title}
                  </Descriptions.Item>
                  <Descriptions.Item label='In stock' span={3}>
                    <Space>
                      <Text delete>{fromProduct.quantity}</Text>
                      {'>'}
                      <Text mark>{fromNewQuantity}</Text>
                    </Space>
                  </Descriptions.Item>
                  <Descriptions.Item label='Total product cost' span={3}>
                    <Space>
                      <Text delete>{ppPrice(fromProduct.totalCost)}</Text>
                      {'>'}
                      <Text mark>
                        {ppPrice(fromProduct.totalCost + fromNewTotalCostDelta)}
                      </Text>
                    </Space>
                  </Descriptions.Item>
                </Descriptions>

                <Descriptions bordered size='small' style={{ marginTop: 16 }}>
                  <Descriptions.Item label='Title' span={3}>
                    {toProduct.title}
                  </Descriptions.Item>
                  <Descriptions.Item label='In stock' span={3}>
                    <Space>
                      <Text delete>{toProduct.quantity}</Text>
                      {'>'}
                      <Text mark>
                        {toProduct.quantity + toNewQuantityDelta}
                      </Text>
                      {error && <Text type='danger'>{error}</Text>}
                    </Space>
                  </Descriptions.Item>
                  <Descriptions.Item label='Total product cost' span={3}>
                    <Space>
                      <Text delete>{ppPrice(toProduct.totalCost)}</Text>
                      {'>'}
                      <Text mark>
                        {ppPrice(toProduct.totalCost + toNewTotalCostDelta)}
                      </Text>
                    </Space>
                  </Descriptions.Item>
                </Descriptions>
              </Space>
            </Space>
          </Card>
        )}
      </Content>
    </Layout>
  );
};

export { Transfers };
