import { ConfigProvider } from 'antd';
import en_US from 'antd/lib/locale-provider/en_US';
import { BrowserRouter, Route } from 'react-router-dom';
import './App.css';
import { Admin } from './components/admin/Admin';
import Appshell from './components/Appshell';
import { Carts } from './components/Carts';
import { PermissionDenied } from './components/error/PermissionDenied';
import Login from './components/login/Login';
import Onboarding from './components/onboarding/Onboarding';
import { Redirector } from './components/Redirector';
import { CustomerDisplay } from './components/sell/register/CustomerDisplay';
import { AuthProvider } from './firebase/Auth';
import PrivateRoute from './routes/PrivateRoute';

const App = () => (
  <BrowserRouter>
    <AuthProvider>
      <Route path='/login' component={Login} />
      <Route path='/cart' component={Carts} />
      <Route path='/mirror' component={Carts} />
      <Route path='/display' component={CustomerDisplay} />
      <Route path='/403' component={PermissionDenied} />
      <PrivateRoutes></PrivateRoutes>
    </AuthProvider>
  </BrowserRouter>
);

const PrivateRoutes = () => {
  return (
    <ConfigProvider locale={en_US}>
      <PrivateRoute exact path='/' component={Redirector}></PrivateRoute>
      <PrivateRoute
        key='onboarding'
        path='/onboarding'
        component={Onboarding}
      />
      <PrivateRoute
        path='/c/:companyid'
        component={Appshell}
        key='applicationshell'
      />
      <PrivateRoute key='admin' path='/admin' component={Admin} />
    </ConfigProvider>
  );
};

export default App;
