import { masterRepository } from '../../firebase/db';
import { Drawer } from '../../models/drawer';
import {
  createGenericContext,
  createGenericProvider,
} from './generics/GenericContextProvider';

const DrawerContext = createGenericContext<Drawer>();
const DrawerProvider = createGenericProvider<Drawer>(
  masterRepository.drawer,
  DrawerContext,
  'Drawer'
);

export { DrawerContext, DrawerProvider };
