import { CustomerProvider } from '../base/CustomerProvider';
import { DepartmentProvider } from '../base/DepartmentProvider';
import { FeeProvider } from '../base/FeeProvider';
import { GroupProvider } from '../base/GroupProvider';
import { ProductProvider } from '../base/ProductProvider';
import { PurchaseOrderProvider } from '../base/PurchaseOrderProvider';
import { SupplierProvider } from '../base/SupplierProvider';

const WithInventoryProviders = ({ children }: { children: JSX.Element }) => {
  return (
    <ProductProvider>
      <GroupProvider>
        <FeeProvider>
          <DepartmentProvider>
            <PurchaseOrderProvider
              orderBy={{ fieldPath: 'createdTime', directionStr: 'desc' }}
            >
              <SupplierProvider>
                <CustomerProvider>{children}</CustomerProvider>
              </SupplierProvider>
            </PurchaseOrderProvider>
          </DepartmentProvider>
        </FeeProvider>
      </GroupProvider>
    </ProductProvider>
  );
};

export { WithInventoryProviders };
