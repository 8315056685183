/**
 * Mar 25 1991 is represented as
 * {
 *     year: 1991,
 *     month: 3,
 *     day: 25
 * }
 */
export interface IDate {
  year?: number;
  month?: number;
  day?: number;
}

export class Date {
  readonly year: number;
  readonly month: number;
  readonly day: number;

  constructor(data?: IDate) {
    this.year = data?.year ?? 9999;
    this.month = data?.month ?? 1;
    this.day = data?.day ?? 1;
  }
}
