import { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { SupplierContext } from '../../providers/base/SupplierProvider';
import { BusySpin } from '../../routes/Routes';
import { NotFound } from '../common/result/NotFound';
import { SupplierDetail } from './detail/SupplierDetail';
import { SupplierList } from './list/SupplierList';

const Suppliers = () => {
  const { url } = useRouteMatch();
  const { snapshots: supplierSnapshots, isLoading: isSupplierLoading } =
    useContext(SupplierContext);

  return (
    <Switch>
      <Redirect from='/:url*(/+)' to={window.location.pathname.slice(0, -1)} />
      <Route
        path={`${url}/:supplierId`}
        render={({ match }) => {
          if (isSupplierLoading) {
            return <BusySpin></BusySpin>;
          }

          const supplierId = match.params.supplierId;
          const supplier = supplierSnapshots.find(
            (snapshot) => snapshot.id === supplierId
          );

          if (supplier || supplierId === 'new') {
            return <SupplierDetail supplierId={supplierId}></SupplierDetail>;
          }

          return (
            <NotFound
              backUrl='.'
              backButtonText='View suppliers'
              itemText='supplier'
            ></NotFound>
          );
        }}
      />
      <Route exact path={url} render={() => <SupplierList></SupplierList>} />
    </Switch>
  );
};

export { Suppliers };
