import { Company } from '../../../models/company';
import { Employee } from '../../../models/employee';
import { Payroll } from '../../../models/payroll';
import { PayrollEmployee } from '../../../models/payroll-employee';

export enum Institution {
  BMO = 'BMO',
  ATB = 'ATB',
  SERVUS = 'SERVUS',
  TD = 'TD',
}

// BMO only.
const generateEft80 = (
  payroll: Payroll,
  company: Company,
  payrollEmployees: PayrollEmployee[],
  employees: Employee[],
  institution: Institution
) => {
  let numOfRecordTypes = 0;
  let eft80 =
    payroll!.getBatchHeaderRecordTypeALine(company!) +
    payroll!.getBatchHeaderRecordTypeXLine(company!) +
    payrollEmployees.reduce((prev, curr) => {
      const [lines, addedNumberOfLines] = curr.getDetailRecordTypeLine(
        employees.find((e) => e.key === curr.key)!,
        institution,
        company,
        payroll,
        numOfRecordTypes
      );
      numOfRecordTypes += addedNumberOfLines;
      return prev + lines;
    }, '');
  eft80 += payroll!.getBatchControlRecordTypeYLine(
    payrollEmployees,
    numOfRecordTypes
  );
  eft80 += payroll!.getFileControlTrailerRecordTypeZ(
    payrollEmployees,
    numOfRecordTypes
  );
  return eft80;
};

// TD only.
const generateEft80TD = (
  payroll: Payroll,
  company: Company,
  payrollEmployees: PayrollEmployee[],
  employees: Employee[],
  institution: Institution
) => {
  let numOfRecordTypes = 0;
  let eft80 =
    payroll!.getBastchHeaderRecordTypeHLine(company!) +
    payrollEmployees.reduce((prev, curr) => {
      const [lines, addedNumberOfLines] = curr.getDetailRecordTypeLine(
        employees.find((e) => e.key === curr.key)!,
        institution,
        company,
        payroll,
        numOfRecordTypes
      );
      numOfRecordTypes += addedNumberOfLines;
      return prev + lines;
    }, '');
  eft80 += payroll!.getBatchControlRecordTypeTLine(
    payrollEmployees,
    numOfRecordTypes
  );
  return eft80;
};

const generateEft1464 = (
  payroll: Payroll,
  company: Company,
  payrollEmployees: PayrollEmployee[],
  employees: Employee[],
  institution: Institution
) => {
  // numOfRecordTypes starts from 1 because ALine already hardcodes first record of 1.
  let numOfRecordTypes = 1;
  let numOfCTypes = 0;
  let eft1464 = payroll!.getBatchHeaderRecordTypeALine1464(
    company!,
    institution === Institution.SERVUS
  );

  eft1464 += payrollEmployees.reduce((prev, curr) => {
    const [lines, addedNumberOfLines] = curr.getDetailRecordTypeLine(
      employees.find((e) => e.key === curr.key)!,
      institution,
      company,
      payroll,
      numOfRecordTypes
    );
    numOfRecordTypes += addedNumberOfLines;
    numOfCTypes += addedNumberOfLines;
    return prev + lines;
  }, '');

  eft1464 += payroll!.getFileControlTrailerRecordTypeZ1464(
    payrollEmployees,
    numOfRecordTypes,
    numOfCTypes,
    company,
    payroll
  );
  return eft1464;
};

const generateEft = (
  payroll: Payroll,
  company: Company,
  payrollEmployees: PayrollEmployee[],
  employees: Employee[]
) => {
  let eft = '';
  switch (company.institution) {
    case Institution.TD:
      eft = generateEft80TD(
        payroll,
        company,
        payrollEmployees,
        employees,
        company.institution
      );
      break;

    case Institution.BMO:
      eft = generateEft80(
        payroll,
        company,
        payrollEmployees,
        employees,
        company.institution
      );
      break;

    case Institution.ATB:
      eft = generateEft1464(
        payroll,
        company,
        payrollEmployees,
        employees,
        company.institution
      );
      break;

    case Institution.SERVUS:
      eft = generateEft1464(
        payroll,
        company,
        payrollEmployees,
        employees,
        company.institution
      );
      break;

    default:
      throw new Error('Unsupported institution');
  }
  return eft;
};

export { generateEft };
