import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  addBeforeUnloadDirtyDialog,
  removeBeforeUnloadDirtyDialog,
} from '../../../utils/dirty-state-helpers';

type KeyValue = {
  [key: string]: any;
};

export type Status = {
  ___status___: 'created' | 'updated' | 'deleted';
};

interface Exit {
  exit: boolean;
  url: string;
  replace: boolean;
}

/**
 * Diffs original object and changed object to find the deltas. This is useful for keeping
 * track of the dirty states and returns all of the delta fields in order to persist to the
 * database. Use useDirtyArray for array types.
 *
 * key attribute is returned to help distinguish which object it is. Key is usually
 * referred to firestore document id if it is non-empty. Empty key means it's a new object.
 * @param before
 * @param after
 * @param exit
 * @returns isDirty to keep track of dirty state. deltaValues returns only the deltas
 * (modified) fields (very useful for updating firestore). deltaValue returns undefined
 * if nothing has changed.
 */
const useDirtyObject = <T extends KeyValue>(
  before: T,
  after: T,
  exit?: Exit
) => {
  const [deltaValues, setDeltaValues] = useState<Partial<T>>();
  const [isDirty, setIsDirty] = useState(false);
  useExit(isDirty, exit);
  useUnbeforeUnloadDialog(isDirty);

  useEffect(() => {
    const deltaValues = getChangedFieldValues<T>(before, after);
    if (deltaValues) {
      setIsDirty(true);
      setDeltaValues(deltaValues);
    } else {
      setIsDirty(false);
      setDeltaValues(undefined);
    }
  }, [before, after]);

  return { isDirty, deltaValues };
};

/**
 * Similar to useDirtyObject but for Array objects. This is useful for cases like
 * purchase order items as it keeps track of all of the collection under the same
 * ui component list. The difference is that we need to indicate whether object
 * was created, updated, deleted and this is done by ___status___ key.
 * @param before
 * @param after
 * @param exit
 * @returns
 */
const useDirtyArray = <T extends KeyValue>(
  before: T[],
  after: T[],
  exit?: Exit
) => {
  const [deltaValues, setDeltaValues] = useState<Partial<T>[]>();
  const [isDirty, setIsDirty] = useState(false);
  useExit(isDirty, exit);
  useUnbeforeUnloadDialog(isDirty);

  useEffect(() => {
    const createdOrUpdateDeltas = _.differenceWith(
      after,
      before,
      _.isEqual
    ).filter(
      // Bug until Antd fixes Form.List https://github.com/ant-design/ant-design/issues/27451
      (item) =>
        !_.keys(item)
          .map((key) => item[key])
          .reduce((prev, curr) => prev && curr === undefined, true)
    );
    const newCreatedOrUpdatedDelta = createdOrUpdateDeltas.map((item) => {
      if (item.key) {
        // Exists in the database. Must find difference and remove unchanged fields.
        const original = before.find((bItem) => bItem.key === item.key)!;
        return {
          ...getChangedFieldValues<T>(original, item)!,
          ___status___: 'updated',
        };
      } else {
        // New addition.
        return {
          ...item,
          ___status___: 'created',
        };
      }
    });

    const deletedDeltas = _.differenceWith(before, after, _.isEqual).filter(
      // Remove duplicates from newCreatedOrUpdatedDelta
      (item) =>
        !newCreatedOrUpdatedDelta.some((couItem) => couItem.key === item.key)
    );
    const newDeletedDetas = deletedDeltas.map((item) => ({
      ...item,
      ___status___: 'deleted',
    }));

    const newDeltaValues = [...newCreatedOrUpdatedDelta, ...newDeletedDetas];

    if (newDeltaValues.length > 0) {
      setIsDirty(true);
      setDeltaValues(newDeltaValues);
    } else {
      setIsDirty(false);
      setDeltaValues(undefined);
    }
  }, [before, after]);

  return { isDirty, deltaValues };
};

/**
 * Finds the delta by comparing object to object. Key will always be inserted to keep track of
 * which object it belongs to in the Database. Returns undefined if no differences.
 * @param before T object
 * @param after T object
 * @returns Partial<T> of deltas, undefined if nothing has changed.
 */
const getChangedFieldValues = <T extends KeyValue>(before: T, after: T) => {
  const fields = _.intersection(_.keys(after), _.keys(before));
  const changedFields: string[] = [];
  fields.forEach((field) => {
    if (!_.isEqual(after[field], before[field])) {
      changedFields.push(field);
    }
  });
  if (changedFields.length > 0) {
    return _.pick(after, ['key', ...changedFields]) as Partial<T>;
  } else {
    return undefined;
  }
};

/**
 * Before navigating, we need to wait until isDirty cleans up, ottherwise, unsave change dialog popup will prompt (Prompt component).
 * @param isDirty
 * @param exit if exit is not defined, skip.
 */
const useExit = (isDirty: boolean, exit?: Exit) => {
  const history = useHistory();
  useEffect(() => {
    if (exit && exit.exit && !isDirty) {
      if (exit.replace) {
        history.replace(exit.url);
      } else {
        history.push(exit.url);
      }
    }
  }, [isDirty, exit, history]);
};

const useUnbeforeUnloadDialog = (isDirty: boolean) => {
  useEffect(() => {
    if (isDirty) {
      addBeforeUnloadDirtyDialog();
    } else {
      removeBeforeUnloadDirtyDialog();
    }
  }, [isDirty]);
};

export { useDirtyObject, useDirtyArray, useUnbeforeUnloadDialog, useExit };
