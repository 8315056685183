import { CustomerProvider } from '../base/CustomerProvider';
import { DepartmentProvider } from '../base/DepartmentProvider';
import { FeeProvider } from '../base/FeeProvider';
import { GroupProvider } from '../base/GroupProvider';
import { ProductProvider } from '../base/ProductProvider';
import { SaleProvider } from '../base/SaleProvider';
import { SalesOrderProvider } from '../base/SalesOrderProvider';

const WithSalesOrderProviders = ({ children }: { children: JSX.Element }) => {
  return (
    <ProductProvider>
      <GroupProvider>
        <FeeProvider>
          <CustomerProvider>
            <DepartmentProvider>
              <SaleProvider
                orderBy={{ fieldPath: 'createdTime', directionStr: 'desc' }}
                limit={1}
              >
                <SalesOrderProvider
                  orderBy={{ fieldPath: 'createdTime', directionStr: 'desc' }}
                >
                  {children}
                </SalesOrderProvider>
              </SaleProvider>
            </DepartmentProvider>
          </CustomerProvider>
        </FeeProvider>
      </GroupProvider>
    </ProductProvider>
  );
};

export { WithSalesOrderProviders };
