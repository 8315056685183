import { Button, Card, Col, Layout, Row, Space } from 'antd';
import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { db } from '../../../firebase/Fb';
import { Cart } from '../../../models/cart';
import { IDrawer } from '../../../models/drawer';
import { CartList } from './CartList';

const CustomerDisplay = () => {
  const [cart, setCart] = useState<Cart>(new Cart());
  const [zoomFactor, setZoomFactor] = useState(1.75);
  const pageEndRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const companyId = params.get('c');
    const storeId = params.get('s');
    const displayId = params.get('d');

    const q = doc(
      db,
      `companies/${companyId}/stores/${storeId}/drawers/${displayId}`
    );
    const unsubscribe = onSnapshot(q, {
      next: (documentSnapshot) => {
        const resource = documentSnapshot.data() as IDrawer;
        setCart(new Cart(resource.cart));
        const lastElement = document.getElementById('last-cart-item-summary');
        lastElement?.scrollIntoView({
          block: 'end',
        });
      },
    });

    return unsubscribe;
  }, []);

  return (
    <Layout style={{ zoom: zoomFactor, minHeight: '100%' }}>
      <Space direction='vertical' align='end'>
        <Space size='small'>
          <Button size='small' onClick={() => setZoomFactor(zoomFactor + 0.25)}>
            +
          </Button>
          <Button size='small' onClick={() => setZoomFactor(zoomFactor - 0.25)}>
            -
          </Button>
        </Space>
      </Space>
      <Row
        justify='space-between'
        align='bottom'
        style={{
          margin: '40px',
          display: cart?.cartItems?.length ? '' : 'none',
        }}
      >
        <Col span={14}>
          <Card>
            <CartList
              cart={cart}
              setCart={() => {}}
              maxDiscount={0}
              viewOnly
              hideSummary
            />
          </Card>
        </Col>
        <Col span={10}>
          <Card style={{ marginLeft: '40px' }}>
            <CartList
              cart={cart}
              setCart={() => {}}
              maxDiscount={0}
              viewOnly
              summaryOnly
              emphasizeTotal
            />
          </Card>
        </Col>
      </Row>
      <div id='last-cart-item-summary'></div>
    </Layout>
  );
};

export { CustomerDisplay };
