import { DocumentReference, Timestamp } from 'firebase/firestore';
import moment from 'moment';
import { ppMomentDateTime } from '../utils/Formatter';
import { Cart, ICart } from './cart';
import { DocumentModel, FirestoreTimestamp } from './utils/document-model';
import {
  documentReference2String,
  string2DocumentReference,
} from './utils/document-reference-serializer';

interface ISalesOrder {
  address?: string;
  cart?: ICart;
  customer?: DocumentReference | string;
  email?: string;
  message?: string;
  number?: string;
  sale?: DocumentReference | string;
  salePayment?: DocumentReference | string;
  total?: number;
  totalAr?: number;
  totalPayment?: number;
  deliveredTime?: FirestoreTimestamp;
  invoicedTime?: FirestoreTimestamp;
  invoiceDueTime?: FirestoreTimestamp;
  createdTime?: FirestoreTimestamp;
  updatedTime?: FirestoreTimestamp;
}

export class SalesOrder
  extends DocumentModel<ISalesOrder>
  implements ISalesOrder
{
  readonly address: string;
  readonly cart: Cart;
  readonly customer: string;
  readonly email: string;
  readonly message: string;
  readonly number: string;
  readonly sale: string;
  readonly salePayment: string;
  readonly total: number;
  readonly totalAr: number;
  readonly totalPayment: number;
  readonly deliveredTime?: FirestoreTimestamp;
  readonly invoicedTime?: FirestoreTimestamp;
  readonly invoiceDueTime?: FirestoreTimestamp;
  readonly createdTime?: FirestoreTimestamp;
  readonly updatedTime?: FirestoreTimestamp;

  constructor(id: string, data: ISalesOrder) {
    super(id, data);
    this.key = id;
    this.address = data.address ?? '';
    this.cart = new Cart(data.cart);
    this.customer = documentReference2String(data.customer);
    this.email = data.email ?? '';
    this.message = data.message ?? '';
    this.number = data.number ?? '';
    this.sale = documentReference2String(data.sale);
    this.salePayment = documentReference2String(data.salePayment);
    this.total = data.total ?? 0;
    this.totalAr = data.totalAr ?? 0;
    this.totalPayment = data.totalPayment ?? 0;
    this.deliveredTime = data.deliveredTime;
    this.invoicedTime = data.invoicedTime;
    this.invoiceDueTime = data.invoiceDueTime;
    this.createdTime = data.createdTime;
    this.updatedTime = data.updatedTime;
  }

  toFirestore() {
    const iSalesOrder = super.toFirestore();

    // Convert path strings back into firestore reference objects.
    if (this._modifiedProperties.customer) {
      iSalesOrder.customer = string2DocumentReference(this.customer);
    }
    if (this._modifiedProperties.sale) {
      iSalesOrder.sale = string2DocumentReference(this.sale);
    }
    if (this._modifiedProperties.salePayment) {
      iSalesOrder.salePayment = string2DocumentReference(this.salePayment);
    }
    // Prevent explosion of document size. This is because Department has Products as a field.
    if (this._modifiedProperties.cart && iSalesOrder.cart?.cartItems) {
      iSalesOrder.cart.cartItems.forEach((cartItem) => {
        cartItem.departments?.forEach((department) => {
          department.products = [];
        });
      });
    }

    return iSalesOrder;
  }

  getDate() {
    try {
      return ppMomentDateTime(
        moment((this.createdTime as Timestamp).toDate()),
        'MMM D YYYY'
      );
    } catch (error) {
      return '';
    }
  }

  getCreatedTime() {
    try {
      return ppMomentDateTime(
        moment((this.createdTime as Timestamp).toDate()),
        'MMM D YYYY, h:mm:ss A'
      );
    } catch (error) {
      return '';
    }
  }
}
