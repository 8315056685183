import { FieldValue, serverTimestamp, Timestamp } from 'firebase/firestore';
import _ from 'lodash';

export type ModifiedProperties<T> = { [P in keyof Required<T>]: boolean };
export type FirestoreTimestamp = Timestamp | FieldValue;
interface CreateUpdateTimestamp {
  createdTime: FirestoreTimestamp;
  updatedTime: FirestoreTimestamp;
}

/**
 * Base model class for all model types. This is mostly used during pushing the state back to Firestore
 * where 'new Model('', {object})' would create a new object that keeps track of all of the new attributes
 * inside {object} by _modifiedProperties. _modifiedProperties helps keep track of what needs to be pushed
 * back to Firestore as we use 'update' or 'set' with merged:true option. We DO NOT want to update all of
 * the document fields.
 *
 * In case 'key', '_modifiedProperties' is inserted into 'data' parameter, we want to ignore it to prevent
 * unused data to Firestore db. 'key', '_modifiedProperties' are only used within client code to denote
 * object id (also, we use it as react key) and to keep track of modified fields.
 */
export class DocumentModel<T> {
  key: string; // Can't have _key (underscore) due to how React components rely on this (i.e. Table) to generate a unique Key prop.
  _modifiedProperties: Partial<ModifiedProperties<T> & CreateUpdateTimestamp>;

  constructor(id: string, data: T) {
    this.key = id;
    this._modifiedProperties = Object.keys(data).reduce((attrs, key) => {
      if (['key', '_modifiedProperties'].includes(key)) return attrs;
      return {
        ...attrs,
        [key]: true,
      };
    }, {});
  }

  /**
   * Returns T object with modified properties only. This is because we only want to modify fields that have been modified.
   * @returns T
   */
  toFirestore(): T {
    const iModel = JSON.parse(
      JSON.stringify(
        Object.keys(this._modifiedProperties).reduce(
          (attrs, key) => ({
            ...attrs,
            // @ts-ignore
            [key]: this[key],
          }),
          {}
        ) as T
      )
    );
    // Prevent client side timestamp. Always rely on serverside timestamp.
    if (this._modifiedProperties.createdTime) {
      iModel.createdTime = serverTimestamp();
    }
    // By default we always want to set updated timestamp upon writing to db.
    iModel.updatedTime = serverTimestamp();
    return iModel;
  }

  // fromFirestore is declared in db.ts.
}

const roundTo2Decimal = (value?: number) => {
  if (value === undefined) {
    return 0;
  }
  if (isNaN(value)) {
    return 0;
  }
  return _.round(value, 2);
};

export { roundTo2Decimal };
