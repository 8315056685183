import { DocumentReference } from 'firebase/firestore';
import _ from 'lodash';
import { DocumentModel } from './utils/document-model';
import {
  documentReference2String,
  string2DocumentReference,
} from './utils/document-reference-serializer';

export interface IDepartment {
  description?: string;
  favourite?: boolean;
  parent?: DocumentReference | string;
  products?: DocumentReference[] | string[];
  title?: string;
}

export class Department
  extends DocumentModel<IDepartment>
  implements IDepartment
{
  readonly description: string;
  readonly favourite: boolean;
  readonly parent: string;
  readonly products: string[];
  readonly title: string;

  constructor(id: string, data: IDepartment) {
    super(id, data);
    this.description = data.description ?? '';
    this.favourite = data.favourite ?? false;
    this.parent = documentReference2String(data.parent);
    this.products = data.products?.map(documentReference2String) ?? [];
    this.title = data.title ?? '';
  }

  toFirestore() {
    const iDepartment = super.toFirestore();

    // Convert path strings back into firestore reference objects.
    if (this._modifiedProperties.parent) {
      iDepartment.parent = string2DocumentReference(this.parent);
    }
    if (this._modifiedProperties.products) {
      iDepartment.products = _.compact(
        this.products.map(string2DocumentReference)
      );
    }

    return iDepartment;
  }
}
