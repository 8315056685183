export interface ISplitDeposit {
  number?: number;
  type?: 'Fixed' | 'Percentage';
}

export class SplitDeposit implements ISplitDeposit {
  readonly number: number;
  readonly type: 'Fixed' | 'Percentage';

  constructor(data?: ISplitDeposit) {
    this.number = data?.number ?? 0;
    this.type = data?.type ?? 'Fixed';
  }
}
