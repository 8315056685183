import { message, notification } from 'antd';
import {
  collection,
  doc,
  DocumentData,
  onSnapshot,
  QuerySnapshot,
  setDoc,
} from 'firebase/firestore';
import { db } from '../firebase/Fb';
import { IGroup } from './Group';

const StreamGroups = (
  companyId: string,
  storeId: string,
  observer: {
    next?: ((snapshot: QuerySnapshot<DocumentData>) => void) | undefined;
    error?: ((error: Error) => void) | undefined;
    complete?: (() => void) | undefined;
  }
) => {
  if (companyId && storeId) {
    console.log('Requesting for Groups.');
    return onSnapshot(
      collection(db, `companies/${companyId}/stores/${storeId}/groups`),
      observer
    );
  }
};

const createGroup = async (
  companyId: string,
  storeId: string,
  group: IGroup
) => {
  try {
    const newGroupRef = doc(
      collection(db, `companies/${companyId}/stores/${storeId}/groups`)
    );
    await setDoc(newGroupRef, group);
    message.success('Created!');
    return newGroupRef;
  } catch (error: any) {
    notification['error']({
      message: 'Database Error',
      description: error.toString(),
    });
  }
};

export { StreamGroups, createGroup };
