import { Button, Card, Form, Input, Space, Steps, Typography } from "antd";
import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../../firebase/Auth";
import { CompanyForm } from "./CompanyForm";
import "./Onboarding.scss";
import { ProfileForm } from "./ProfileForm";
import { SmileOutlined } from "@ant-design/icons";
import { GotoMyCompany } from "./GotoMyCompany";
const { Title, Text } = Typography;

const Onboarding = () => {
  const {
    currentUser,
    currentCompany,
    currentConfiguration,
    companies,
    setCompany,
  } = useContext(AuthContext);
  //   const [current, setCurrent] = useState(0);
  let current = 0;
  if (currentCompany) {
    current = 2;
  } else if (currentUser.firestore) {
    current = 1;
  }

  return (
    <div className="login-setup-screen">
      <Space
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
        }}
        align="center"
      >
        <Card
          style={{
            width: 600,
          }}
        >
          <Title
            style={{
              textAlign: "center",
              marginBottom: "64px",
              marginTop: "25px",
            }}
          >
            Welcome!
          </Title>
          <Steps current={current} style={{ marginBottom: 24 }}>
            <Steps.Step key="user" title="Setup Profile"></Steps.Step>
            <Steps.Step key="company" title="Setup Company"></Steps.Step>
            <Steps.Step
              key="done"
              title="Done"
              icon={<SmileOutlined />}
            ></Steps.Step>
          </Steps>
          {current === 0 ? <ProfileForm></ProfileForm> : <></>}
          {current === 1 ? <CompanyForm></CompanyForm> : <></>}
          {current === 2 ? <GotoMyCompany></GotoMyCompany> : <></>}
        </Card>
      </Space>
    </div>
  );
};

export default withRouter(Onboarding);
