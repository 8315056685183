export enum WageType {
  Hourly = 'Hourly',
  Monthly = 'Monthly',
  Pay = 'Pay',
}

export interface IWage {
  type?: WageType;
  number?: number;
}

export class Wage implements IWage {
  readonly type: WageType;
  readonly number: number;

  constructor(data?: IWage) {
    this.type = data?.type ?? WageType.Hourly;
    this.number = data?.number ?? 0;
  }
}
