import {
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import _ from 'lodash';
import { Cart, ICart } from './Cart';

export interface Payment {
  totalPayed: number;
  totalCashReturned: number;
  type:
    | 'PaymentType.Cash'
    | 'PaymentType.Credit'
    | 'PaymentType.Debit'
    | 'PaymentType.Cheque'
    | 'PaymentType.Loyalty';
}

export interface ISale {
  cart: ICart;
  drawerName: string;
  payments: Payment[];
  voided: boolean;
  createdTime: Timestamp;
  updatedTime: Timestamp;
}

class Sale implements ISale {
  cart: Cart;
  drawerName: string;
  payments: Payment[];
  voided: boolean;
  createdTime: Timestamp;
  updatedTime: Timestamp;

  // Non-db columns
  reference: DocumentReference;
  key: string; // Uuid

  constructor(snapshot: QueryDocumentSnapshot<DocumentData>) {
    const document = snapshot.data();

    this.cart = new Cart(document.cart);
    this.drawerName = document.drawerName ?? '';
    this.payments = document.payments ?? [];
    this.voided = document.voided ?? false;
    this.createdTime = document.createdTime ?? { nanoseconds: 0, seconds: 0 };
    this.updatedTime = document.createdTime ?? { nanoseconds: 0, seconds: 0 };

    // Non-db columns
    this.reference = snapshot.ref;
    this.key = snapshot.id;
  }

  calculateTotalPayment() {
    return this.payments.reduce((prev, curr) => {
      const cashReturned =
        curr.totalCashReturned < 0 ? 0 : curr.totalCashReturned; // totalCashReturned can be negative for split payment
      return (prev += _.round(curr.totalPayed, 2) - _.round(cashReturned, 2));
    }, 0);
  }
}

export { Sale };
