import { message, notification } from 'antd';
import {
  collection,
  doc,
  DocumentData,
  onSnapshot,
  QuerySnapshot,
  writeBatch,
} from 'firebase/firestore';
import { db } from '../firebase/Fb';
import { Department, IDepartment } from './Department';

const getSubdepartmentsRecursive = (
  selected: Department,
  departments: Department[]
): Department[] => {
  const subdepartments = getSubdepartments(selected, departments);

  if (subdepartments.length > 0) {
    return [
      ...subdepartments,
      ...subdepartments.flatMap((subdepartment) =>
        getSubdepartmentsRecursive(subdepartment, departments)
      ),
    ];
  } else {
    return [];
  }
};

const getParentDepartmentsRecursive = (
  selected: Department,
  departments: Department[]
): Department[] => {
  if (selected.parent) {
    const parent = departments.find((d) => d.key === selected.parent?.id);
    if (parent) {
      return [parent, ...getParentDepartmentsRecursive(parent, departments)];
    }
  }
  return [];
};

const getSubdepartments = (selected: Department, departments: Department[]) => {
  return departments.filter(
    (department) => department?.parent?.id === selected.key
  );
};

const StreamDepartments = (
  companyId: string,
  storeId: string,
  observer: {
    next?: ((snapshot: QuerySnapshot<DocumentData>) => void) | undefined;
    error?: ((error: Error) => void) | undefined;
    complete?: (() => void) | undefined;
  }
) => {
  if (companyId && storeId) {
    console.log('Requesting for Departments.');
    return onSnapshot(
      collection(db, `companies/${companyId}/stores/${storeId}/departments`),
      observer
    );
  }
};

const createDepartment = async (
  companyId: string,
  storeId: string,
  department: IDepartment,
  subdepartments?: Department[]
) => {
  try {
    const batch = writeBatch(db);

    const newDepartmentRef = doc(
      collection(db, `companies/${companyId}/stores/${storeId}/departments`)
    );
    batch.set(newDepartmentRef, department);

    // Subdepartments
    subdepartments?.forEach((department) => {
      batch.update(department.reference, {
        parent: newDepartmentRef,
      });
    });
    await batch.commit();
    message.success('Department Created!');
    return newDepartmentRef;
  } catch (error: any) {
    notification['error']({
      message: 'Database Error',
      description: error.toString(),
    });
  }
};

export {
  getSubdepartmentsRecursive,
  getParentDepartmentsRecursive,
  getSubdepartments,
  StreamDepartments,
  createDepartment,
};
