import { useContext } from 'react';
import { Route, useRouteMatch } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import { DrawerContext } from '../../../../providers/base/DrawerProvider';
import { BusySpin } from '../../../../routes/Routes';
import { NotFound } from '../../../common/result/NotFound';
import Register from '../Register';
import { DrawersList } from './DrawersList';

const Drawers = () => {
  const { url } = useRouteMatch();
  const { snapshots: drawerSnapshots, isLoading: isDrawerLoading } =
    useContext(DrawerContext);
  return (
    <Switch>
      <Redirect from='/:url*(/+)' to={window.location.pathname.slice(0, -1)} />
      <Route
        path={`${url}/:drawerId`}
        render={({ match }) => {
          if (isDrawerLoading) {
            return <BusySpin />;
          }

          const drawerId = match.params.drawerId;
          const drawer = drawerSnapshots.find(
            (snapshot) => snapshot.id === drawerId
          );

          if (drawer || drawerId === 'none') {
            return <Register drawerId={drawerId} />;
          }

          return (
            <NotFound
              backUrl='.'
              backButtonText='View registers'
              itemText='register'
            />
          );
        }}
      />
      <Route exact path={url} render={() => <DrawersList />} />
    </Switch>
  );
};

export { Drawers };
