import _ from 'lodash';
import { Amount, calculateAmount, IAmount } from './amount';
import { Department, IDepartment } from './department';
import { Fee, IFee } from './fee';
import { Group, IGroup } from './group';
import { IProduct, Product } from './product';

export interface ICartItem {
  departments?: IDepartment[];
  groups?: IGroup[];
  discounts?: IAmount[];
  fees?: IFee[];
  product?: IProduct;
  quantity?: number;
  returnQuantity?: number;
  returnMaxQuantity?: number;
}

export class CartItem implements ICartItem {
  readonly departments: Department[];
  readonly groups: Group[];
  discounts: Amount[];
  readonly fees: Fee[];
  readonly product: Product;
  quantity: number;
  readonly returnQuantity: number;
  readonly returnMaxQuantity: number;

  constructor(data?: ICartItem) {
    this.departments =
      data?.departments?.map((department) => new Department('', department)) ??
      [];
    this.groups =
      //@ts-ignore
      data?.groups?.map((group) => new Group(group?.key ?? '', group)) ?? [];
    this.discounts = data?.discounts?.map((amount) => new Amount(amount)) ?? [];
    this.fees = data?.fees?.map((fee) => new Fee('', fee)) ?? [];
    //@ts-ignore
    this.product = new Product(data?.product?.key ?? '', data?.product ?? {});
    this.quantity = data?.quantity ?? 0;
    this.returnQuantity = data?.returnQuantity ?? 0;
    this.returnMaxQuantity = this.quantity - this.returnQuantity;
  }

  calculateSubtotal() {
    if (this.product) {
      return (
        this.calculateSubtotalWithoutDiscount() -
        this.discounts.reduce((prev, discount) => {
          if (discount.unit === 'AmountUnit.Percentage') {
            return this.product.price * discount.number * this.quantity;
          } else if (discount.unit === 'AmountUnit.Dollar') {
            return prev + discount.number;
          }
          throw new Error('Unsupported discount type.');
        }, 0)
      );
    }
    throw Error('Missing product from cart item.');
  }

  calculateSubtotalWithoutDiscount() {
    if (this.product) {
      return this.product.price * this.quantity;
    }
    throw Error('Missing product from cart item.');
  }

  // Gets price (before taxes and fees) from final price.
  calculatePrice(finalPrice: number) {
    let price = finalPrice;
    this.fees.forEach((fee) => {
      if (fee.amount.unit === 'AmountUnit.Dollar') {
        let sizeQuantity = 1;
        if ((fee.deposit || fee.eco) && this.product.size.quantity > 1) {
          sizeQuantity = this.product.size.quantity;
        }
        price -= fee.amount.number * sizeQuantity;
      }
    });
    let taxes = 1;
    this.fees.forEach((fee) => {
      if (fee.amount.unit === 'AmountUnit.Percentage') {
        taxes += fee.amount.number;
      }
    });
    return price / taxes; // Do not round to 2 percision as this would cause final price to be off for Ventura liquor store.
  }

  calculateFees(
    fee?: 'eco' | 'tax' | 'deposit' | 'custom',
    withoutDiscount?: boolean
  ) {
    return this.fees.reduce((prevFeeAcc, currFee) => {
      if (fee === 'custom') {
        if (!currFee['eco'] && !currFee['tax'] && !currFee['deposit']) {
          return (
            prevFeeAcc +
            calculateAmount(
              currFee.amount,
              withoutDiscount
                ? this.calculateSubtotalWithoutDiscount()
                : this.calculateSubtotal(),
              this.quantity
            )
          );
        }
        return prevFeeAcc;
      } else if (!fee || currFee[fee]) {
        let sizeQuantity = 1;
        // Used for 6pk 12pk etc where we have multiple cans.
        if (
          (currFee.deposit || currFee.eco) &&
          this.product.size.quantity > 1
        ) {
          sizeQuantity = this.product.size.quantity;
        }

        return (
          prevFeeAcc +
          calculateAmount(
            currFee.amount,
            withoutDiscount
              ? this.calculateSubtotalWithoutDiscount()
              : this.calculateSubtotal(),
            this.quantity * sizeQuantity
          )
        );
      }
      return prevFeeAcc;
    }, 0);
  }

  calculateTotal() {
    return this.calculateSubtotal() + this.calculateFees();
  }

  calculateTotalWithoutDiscount() {
    return (
      this.calculateSubtotalWithoutDiscount() +
      this.calculateFees(undefined, true)
    );
  }

  calculateDiscount() {
    return this.calculateTotalWithoutDiscount() - this.calculateTotal();
  }

  hasDiscount() {
    return (
      this.discounts.length &&
      this.calculateTotalWithoutDiscount() > this.calculateTotal()
    );
  }

  /**
   * Returns total loyalty exemptions in points.
   * @returns number
   */
  calculateLoyaltyExemption() {
    // If discount given or item is already exempt from loyalty, return total.
    if (this.discounts.length > 0 || this.product.loyaltyExcept) {
      return _.round(this.calculateTotal() * 100, 0);
    }

    // Else this item qualifies as loyalty points.
    return 0;
  }
}
