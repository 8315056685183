import { Button, Space } from "antd";
import React, { useState } from "react";
import { Typography } from "antd";
import { withRouter, RouteComponentProps } from "react-router-dom";
import "./Login.scss";
import { LoginCard } from "./LoginCard";
import { SignupCard } from "./SignupCard";
// import { PosyLogo } from "./common/PosyLogo";
const Typewriter = require("typewriter-effect");

const { Title, Text } = Typography;

const Login = ({ history }: RouteComponentProps) => {
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);

  return (
    <div className="login-screen">
      <Space
        direction="vertical"
        style={{
          position: "absolute",
          left: "50%",
          top: showLogin ? "50%" : "25%",
          transform: "translate(-50%, -50%)",
          width: 600,
        }}
        align="center"
      >
        {showSignup ? (
          <SignupCard
            back={() => {
              setShowSignup(false);
              setShowLogin(true);
            }}
          />
        ) : showLogin ? (
          <LoginCard
            onSignUp={() => {
              setShowSignup(true);
            }}
          />
        ) : (
          <Space direction="vertical" align="center">
            <Title
              style={{
                textAlign: "center",
                color: "white",
              }}
            >
              <Typewriter
                options={{
                  strings: [
                    "Point of Sale.",
                    "Payroll.",
                    "Inventory Management.",
                    "Employee Management.",
                    "Analytics.",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </Title>
            <Button
              type="primary"
              ghost
              onClick={() => setShowLogin(true)}
              style={{ color: "white", borderColor: "white" }}
            >
              Start your intelligent business.
            </Button>
          </Space>
        )}
      </Space>
    </div>
  );
};

export default withRouter(Login);
