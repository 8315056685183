import { message, notification } from 'antd';
import {
  deleteDoc,
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { Size } from '../utils/Formatter';
import { Department } from './Department';
import { Fee } from './Fee';
import { Group } from './Group';

export interface IProduct {
  barcode: string;
  casePrice: number;
  caseQuantity: number;
  description: string;
  disabled: boolean;
  favourite: boolean;
  fees: DocumentReference[];
  groups: DocumentReference[];
  itemcode: string;
  memo: string;
  negative: boolean;
  variable: boolean;
  price: number;
  quantity: number;
  size: Size;
  sku: string;
  title: string;
  totalCost: number;
  createdTime?: Timestamp;
  updatedTime?: Timestamp;
}

export class Product implements IProduct {
  barcode: string;
  casePrice: number;
  caseQuantity: number;
  description: string;
  disabled: boolean;
  favourite: boolean;
  fees: DocumentReference[];
  groups: DocumentReference[];
  itemcode: string;
  memo: string;
  negative: boolean;
  variable: boolean;
  price: number;
  quantity: number;
  size: Size;
  sku: string;
  title: string;
  totalCost: number;
  createdTime: Timestamp;
  updatedTime: Timestamp;

  // Non-db columns
  reference: DocumentReference;
  key: string; // Uuid
  grossMargin: number;
  groupObjects: Group[];
  feeObjects: Fee[];
  departmentObjects: Department[];

  constructor(snapshot: QueryDocumentSnapshot<DocumentData>) {
    // Since overloading is not allowed in typescript, check to see if it is IProduct vs Snapshot
    const document = snapshot.data ? snapshot.data() : snapshot;

    /**
     * Backward compatibility. number -> Size
     */
    let sizeConverted: Size =
      typeof document.size === 'number'
        ? { number: document.size, unit: 'SizeUnit.ML', quantity: 1 }
        : document.size ?? { number: 0, unit: 'SizeUnit.EA', quantity: 1 };
    /**
     * Backward compatibility. Size (without quantity) -> Size (with quantity)
     */
    if (!sizeConverted.quantity) {
      sizeConverted.quantity = 1;
    }

    this.barcode = document.barcode ?? '';
    this.casePrice = document.casePrice ?? 0;
    this.caseQuantity = document.caseQuantity ?? 0;
    this.description = document.description ?? '';
    this.disabled = document.disabled ?? false;
    this.favourite = document.favourite ?? false;
    this.fees = document.fees ?? [];
    this.groups = document.groups ?? [];
    this.itemcode = document.itemcode ?? '';
    this.memo = document.memo ?? '';
    this.negative = document.negative ?? false;
    this.variable = document.variable ?? false;
    this.price = document.price ?? 0;
    this.quantity = document.quantity ?? 0;
    this.size = sizeConverted;
    this.title = document.title ?? '';
    this.sku = document.sku ?? '';
    this.totalCost = document.totalCost ?? 0;
    this.createdTime = document.createdTime ?? { nanoseconds: 0, seconds: 0 };
    this.updatedTime = document.updatedTime ?? { nanoseconds: 0, seconds: 0 };

    // Non-db columns
    this.reference = snapshot.ref;
    this.key = snapshot.id;
    this.grossMargin = 0;
    this.groupObjects = [];
    this.feeObjects = [];
    this.departmentObjects = [];
  }

  async update() {
    try {
      await updateDoc(this.reference, {
        barcode: this.barcode.trim(),
        casePrice: this.casePrice,
        caseQuantity: this.caseQuantity,
        description: this.description,
        disabled: this.disabled,
        favourite: this.favourite,
        fees: this.fees,
        groups: this.groups,
        itemcode: this.itemcode,
        memo: this.memo,
        negative: this.negative,
        variable: this.variable,
        price: this.price,
        quantity: this.quantity,
        size: this.size,
        sku: this.sku,
        title: this.title,
        totalCost: this.totalCost,
        createdTime: this.createdTime,
        updatedTime: serverTimestamp(),
      });
      message.success('Product Updated!');
      return true;
    } catch (error: any) {
      notification['error']({
        message: 'Database Error',
        description: error.toString(),
      });
      return false;
    }
  }

  async delete() {
    try {
      await deleteDoc(this.reference);
      message.success('Product Deleted!');
      return true;
    } catch (error: any) {
      notification['error']({
        message: 'Database Error',
        description: error.toString(),
      });
      return false;
    }
  }

  getFullTitle() {
    return `${this.title} - ${this.barcode} ${this.sku}`;
  }

  asIProduct() {
    const iProduct: IProduct = {
      barcode: this.barcode,
      casePrice: this.casePrice,
      caseQuantity: this.caseQuantity,
      description: this.description,
      disabled: this.disabled,
      favourite: this.favourite,
      fees: this.fees,
      groups: this.groups,
      itemcode: this.itemcode,
      memo: this.memo,
      negative: this.negative,
      variable: this.variable,
      price: this.price,
      quantity: this.quantity,
      size: this.size,
      sku: this.sku,
      title: this.title,
      totalCost: this.totalCost,
      createdTime: this.createdTime,
      updatedTime: this.updatedTime,
    };
    return iProduct;
  }
}
