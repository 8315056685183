import { DocumentReference } from 'firebase/firestore';
import {
  documentReference2String,
  string2DocumentReference,
} from './utils/document-reference-serializer';

export interface ICustomerPrice {
  price: number;
  customer: DocumentReference | string;
}

export class CustomerPrice implements ICustomerPrice {
  readonly price: number;
  readonly customer: string;

  constructor(data?: ICustomerPrice) {
    this.price = data?.price ?? 0;
    this.customer = documentReference2String(data?.customer);
  }

  toFirestore(): ICustomerPrice {
    return {
      price: this.price,
      customer: string2DocumentReference(this.customer),
    };
  }
}
