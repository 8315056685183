import { Button, Card, Layout, Row, Space, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { SupplierContext } from '../../../providers/base/SupplierProvider';
import { SupplierColumn } from './SupplierColumn';

const SupplierList = () => {
  const { snapshots, isLoading } = useContext(SupplierContext);
  const { url } = useRouteMatch();
  const history = useHistory();

  return (
    <Layout>
      <Content>
        <Row justify='space-between' style={{ paddingBottom: 16 }}>
          <Title level={3}>Suppliers</Title>
          <Space size='large'>
            <Button type='primary' onClick={() => history.push(`${url}/new`)}>
              Add supplier
            </Button>
          </Space>
        </Row>
        <Card>
          <Table
            bordered
            size='small'
            loading={isLoading}
            dataSource={snapshots.map((snap) => snap.data())}
            columns={SupplierColumn}
          />
        </Card>
      </Content>
    </Layout>
  );
};

export { SupplierList };
