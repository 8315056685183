import _ from 'lodash';

type Unit =
  | 'SizeUnit.CS'
  | 'SizeUnit.CT'
  | 'SizeUnit.DZ'
  | 'SizeUnit.EA'
  | 'SizeUnit.FT'
  | 'SizeUnit.G'
  | 'SizeUnit.KG'
  | 'SizeUnit.L'
  | 'SizeUnit.LB'
  | 'SizeUnit.ML'
  | 'SizeUnit.OZ'
  | 'SizeUnit.PK';

export interface ISize {
  number?: number;
  quantity?: number;
  unit?: Unit;
}

export class Size implements ISize {
  readonly number: number;
  readonly quantity: number;
  readonly unit: Unit;

  constructor(data?: ISize) {
    this.number = data?.number ?? 0;
    this.quantity = data?.quantity ?? 1;
    this.unit = data?.unit ?? 'SizeUnit.EA';
  }

  ppFullSize() {
    if (this.quantity > 1) {
      return `${this.quantity} x ${this.ppSize()}`;
    }
    return this.ppSize();
  }

  ppSize() {
    let unit: string;
    switch (this.unit) {
      case 'SizeUnit.CS':
        unit = 'CS';
        break;
      case 'SizeUnit.CT':
        unit = 'CT';
        break;
      case 'SizeUnit.DZ':
        unit = 'DZ';
        break;
      case 'SizeUnit.EA':
        unit = 'EA';
        break;
      case 'SizeUnit.FT':
        unit = 'FT';
        break;
      case 'SizeUnit.G':
        unit = 'G';
        break;
      case 'SizeUnit.KG':
        unit = 'KG';
        break;
      case 'SizeUnit.L':
        unit = 'L';
        break;
      case 'SizeUnit.LB':
        unit = 'LB';
        break;
      case 'SizeUnit.ML':
        unit = 'ML';
        break;
      case 'SizeUnit.OZ':
        unit = 'OZ';
        break;
      case 'SizeUnit.PK':
        unit = 'PK';
        break;
      default:
        unit = '';
        break;
    }

    return `${this.number} ${unit}`;
  }
}

const string2Unit: {
  [key: string]: Unit;
} = {
  CS: 'SizeUnit.CS',
  CT: 'SizeUnit.CT',
  DZ: 'SizeUnit.DZ',
  EA: 'SizeUnit.EA',
  FT: 'SizeUnit.FT',
  G: 'SizeUnit.G',
  KG: 'SizeUnit.KG',
  L: 'SizeUnit.L',
  LB: 'SizeUnit.LB',
  ML: 'SizeUnit.ML',
  OZ: 'SizeUnit.OZ',
  PK: 'SizeUnit.PK',
};

const unit2String: {
  [key: string]: string;
} = {
  'SizeUnit.CS': 'CS',
  'SizeUnit.CT': 'CT',
  'SizeUnit.DZ': 'DZ',
  'SizeUnit.EA': 'EA',
  'SizeUnit.FT': 'FT',
  'SizeUnit.G': 'G',
  'SizeUnit.KG': 'KG',
  'SizeUnit.L': 'L',
  'SizeUnit.LB': 'LB',
  'SizeUnit.ML': 'ML',
  'SizeUnit.OZ': 'OZ',
  'SizeUnit.PK': 'PK',
};

const string2Size = (size: string): ISize => {
  try {
    const upperCaseSize = _.toUpper(size);
    const [before, after] = upperCaseSize.split('X');

    let quantity;
    let numberUnit;
    if (after) {
      numberUnit = after;
      quantity = isNaN(_.toNumber(before)) ? 1 : _.toNumber(before);
    } else {
      numberUnit = before;
      quantity = 1;
    }

    const match = numberUnit.match(/([0-9.]+)([a-zA-Z]+)/);
    if (!match) {
      throw Error(
        `Unknown size string. It must follow the format of 1234ML but found ${size}`
      );
    }

    const number = _.toNumber(match[1]);

    const unit = match[2];
    const unitValue = string2Unit[unit];
    if (!unitValue) {
      throw Error(
        `Unknown unit string. It must be one of either ${_.keys(
          string2Unit
        ).join(', ')}.`
      );
    }

    return {
      number: number,
      quantity: quantity,
      unit: unitValue,
    };
  } catch (error) {
    console.error(error);
    return {
      quantity: 0,
      number: 0,
      unit: 'SizeUnit.EA',
    };
  }
};

const size2String = (size: ISize): string => {
  if (size.quantity === 1) {
    return `${size.number}${unit2String[size.unit ?? '']}`;
  }
  return `${size.quantity} x ${size.number}${unit2String[size.unit ?? '']}`;
};

export { string2Size, size2String };
