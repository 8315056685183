import { masterRepository } from '../../firebase/db';
import { PurchaseOrder } from '../../models/purchase-order';
import {
  createGenericContext,
  createGenericProvider,
} from './generics/GenericContextProvider';

const PurchaseOrderContext = createGenericContext<PurchaseOrder>();
const PurchaseOrderProvider = createGenericProvider<PurchaseOrder>(
  masterRepository.purchaseorder,
  PurchaseOrderContext,
  'PurchaseOrder'
);

export { PurchaseOrderContext, PurchaseOrderProvider };
