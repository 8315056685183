import { Input, Modal } from 'antd';
import { doc } from 'firebase/firestore';
import _ from 'lodash';
import { useContext, useRef, useState } from 'react';
import { db } from '../../../firebase/Fb';
import { Cart } from '../../../models/cart';
import { DepartmentContext } from '../../../providers/base/DepartmentProvider';
import { FeeContext } from '../../../providers/base/FeeProvider';
import { GroupContext } from '../../../providers/base/GroupProvider';
import { ProductContext } from '../../../providers/base/ProductProvider';
import { BusySpin } from '../../../routes/Routes';

const BarcodeInput = ({
  setCart,
}: {
  setCart: React.Dispatch<React.SetStateAction<Cart>>;
}) => {
  const { snapshots: productSnapshots, isLoading: isProductLoading } =
    useContext(ProductContext);
  const { snapshots: groupSnapshots, isLoading: isGroupLoading } =
    useContext(GroupContext);
  const { snapshots: departmentSnapshots, isLoading: isDepartmentLoading } =
    useContext(DepartmentContext);
  const { snapshots: feeSnapshots, isLoading: isFeeLoading } =
    useContext(FeeContext);
  const [barcode, setBarcode] = useState('');
  const inputRef = useRef<any>(null);
  const isLoading = isProductLoading || isGroupLoading || isDepartmentLoading;

  if (isLoading) {
    return <BusySpin />;
  }

  return (
    <Input
      autoComplete='off'
      id='barcode-input-area'
      ref={inputRef}
      value={barcode}
      onChange={(e) => setBarcode(e.target.value)}
      allowClear
      autoFocus
      placeholder='Scan or enter EAN/UPC'
      onFocus={() => {
        inputRef?.current?.select();
      }}
      onPressEnter={async () => {
        // Import Code Test.
        // let arr: any = [];
        // debugger;
        // const chunkSize = 500;
        // const chunks = [];

        // for (let i = 0; i < arr.length; i += chunkSize) {
        //   const chunk = arr.slice(i, i + chunkSize);
        //   chunks.push(chunk);
        // }
        // debugger;
        // for (const chunk of chunks) {
        //   const batch = writeBatch(db);
        //   chunk.forEach((product: any) => {
        //     const ref = doc(
        //       db,
        //       'companies',
        //       'QMxDFM65tUHpmO5z5qvP',
        //       'stores',
        //       'WKLK0PC2uhpeLtXvHiSh',
        //       'products',
        //       product.key
        //     );

        //     batch.set(ref, {
        //       barcode: product.barcode,
        //       barcodes: product.barcodes,
        //       casePrice: product.casePrice,
        //       caseQuantity: product.caseQuantity,
        //       description: product.description,
        //       disabled: product.disabled,
        //       discountExcept: product.discountExcept,
        //       favourite: product.favourite,
        //       favouriteColor: product.favouriteColor,
        //       fees: [],
        //       groups: [],
        //       invoiceCostPerItem: product.invoiceCostPerItem,
        //       itemcode: product.itemcode,
        //       loyaltyExcept: product.loyaltyExcept,
        //       memo: product.memo,
        //       negative: product.negative,
        //       price: product.price,
        //       quantity: product.quantity,
        //       size: product.size,
        //       sku: product.sku,
        //       skus: product.skus,
        //       title: product.title,
        //       totalCost: product.totalCost,
        //       variable: product.variable,
        //     });
        //   });
        //   await batch.commit();
        // }

        if (!barcode) return;

        const pSnap =
          productSnapshots.find((snap) => {
            if (snap.data().disabled) {
              return false;
            }
            return (
              snap.data().barcode === barcode ||
              snap.data().barcodes.some((b) => b === barcode)
            );
          }) ??
          productSnapshots.find((snap) => {
            if (snap.data().disabled) {
              return false;
            }
            return (
              snap.data().sku === barcode ||
              snap.data().skus.some((s) => s === barcode)
            );
          });

        const product = pSnap?.data();

        if (pSnap && product) {
          const groups = _.compact(
            product.groups.map((path) =>
              groupSnapshots.find((snap) => snap.ref.path === path)?.data()
            )
          );
          const fees = _.compact(
            product.fees.map((path) =>
              feeSnapshots.find((snap) => snap.ref.path === path)?.data()
            )
          );
          const departments = departmentSnapshots
            .filter((snap) =>
              snap.data().products.some((path) => path === pSnap.ref.path)
            )
            .map((snap) => snap.data());

          setCart((prevCart) => {
            const newCart = _.cloneDeep(prevCart);
            const customerSpecificPrice = product.prices.find(
              (customerPrice) => {
                const customerRef = doc(db, customerPrice.customer);
                return customerRef.id === prevCart.customerId;
              }
            );
            if (customerSpecificPrice) {
              (product.price as number) = customerSpecificPrice.price;
            }

            newCart.add({
              product: product,
              departments: departments,
              groups: groups,
              fees: fees,
              discounts: [],
              quantity: 1,
            });
            return newCart;
          });

          setBarcode('');
        } else {
          Modal.error({
            centered: true,
            title: 'Unable to find item',
            content: <div>{`UPC/EAN detected: ${barcode}`}</div>,
            onOk: () => {
              inputRef.current!.focus();
            },
          });
        }
      }}
    ></Input>
  );
};

export { BarcodeInput };
