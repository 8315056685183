import { Button, Popconfirm, Space } from 'antd';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import RouteLeavingGuard from '../guard/RouteLeavingGuard';

const DiscardOrSaveButtons = ({
  allowMetaKeyEnterSubmit,
  show,
  isNew,
  isDirty,
  isLoading,
  routeLeavingGuard,
  onSubmit,
  onDiscardConfirm,
}: {
  allowMetaKeyEnterSubmit?: boolean;
  show: boolean;
  isNew: boolean;
  isDirty: boolean;
  isLoading: boolean;
  routeLeavingGuard?: boolean;
  onSubmit: () => void;
  onDiscardConfirm: (
    e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined
  ) => void;
}) => {
  const history = useHistory();
  const discardText = isNew ? 'Cancel' : 'Discard';
  const discardExplain = isNew
    ? 'If you leave this page, all unsaved changes will be lost. Are you sure you want to leave this page?'
    : 'If you discard changes, you’ll delete any edits you made since you last saved.';
  const discardConfirmText = isNew ? 'Leave page' : 'Discard changes';

  useEffect(() => {
    // Prevent multiple listener from attaching to window.
    if (allowMetaKeyEnterSubmit) return;

    const listenForCmdEnter = (e: KeyboardEvent) => {
      if (e.code === 'Enter' && e.metaKey && isDirty) {
        onSubmit();
      }
    };

    window.addEventListener('keydown', listenForCmdEnter);
    return () => {
      window.removeEventListener('keydown', listenForCmdEnter);
    };
  }, [allowMetaKeyEnterSubmit, onSubmit, isDirty]);

  return (
    <>
      {routeLeavingGuard && (
        <RouteLeavingGuard
          when={isDirty}
          navigate={(path) => history.push(path)}
          shouldBlockNavigation={(location) => {
            return true;
          }}
        />
      )}
      <Space style={{ display: show ? '' : 'none' }}>
        <Popconfirm
          disabled={!isDirty}
          title={discardExplain}
          onConfirm={onDiscardConfirm}
          okText={discardConfirmText}
          okType='danger'
          cancelText='Continue editing'
        >
          <Button
            onClick={() => {
              if (!isDirty) {
                onDiscardConfirm();
              }
            }}
            loading={isLoading}
          >
            {discardText}
          </Button>
        </Popconfirm>
        <Button type='primary' onClick={onSubmit} loading={isLoading}>
          Save
        </Button>
      </Space>
    </>
  );
};

export { DiscardOrSaveButtons };
