import {
  CreditCardOutlined,
  HeartOutlined,
  LeftOutlined,
  MoneyCollectOutlined,
  RedEnvelopeOutlined,
  RightOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Divider,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import { doc } from 'firebase/firestore';
import { useContext } from 'react';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../../firebase/Auth';
import { db } from '../../../../firebase/Fb';
import { Payment } from '../../../../models/payment';
import { Sale } from '../../../../models/sale';
import { ProductProvider } from '../../../../providers/base/ProductProvider';
import { SalesContext } from '../../../../providers/pagination/SalesProvider';
import { ppPrice } from '../../../../utils/Formatter';
import { HighlighterStyle } from '../../../common/detail/cards/card-styles';
import { TransactionFilter } from '../../../common/transaction/TransactionFilter';
import { onPrint } from '../../register/ProductCart';
import { Receipt } from '../../register/Receipt';
const { Link: AntdLink } = Typography;

const PaymentMethods = ({ payments }: { payments: Payment[] }) => {
  return (
    <>
      {payments.map((payment, index) => {
        if (payment.type === 'PaymentType.Cash') {
          return (
            <Tag key={index} icon={<MoneyCollectOutlined />}>
              {'Cash'}
            </Tag>
          );
        } else if (payment.type === 'PaymentType.Credit') {
          return (
            <Tag key={index} icon={<CreditCardOutlined />}>
              {'Credit'}
            </Tag>
          );
        } else if (payment.type === 'PaymentType.Debit') {
          return (
            <Tag key={index} icon={<CreditCardOutlined />}>
              {'Debit'}
            </Tag>
          );
        } else if (payment.type === 'PaymentType.Cheque') {
          return (
            <Tag key={index} icon={<RedEnvelopeOutlined />}>
              {'Cheque'}
            </Tag>
          );
        } else if (payment.type === 'PaymentType.Loyalty') {
          return (
            <Tag key={index} icon={<HeartOutlined />}>
              {'Loyalty'}
            </Tag>
          );
        } else if (payment.type === 'PaymentType.AR') {
          return (
            <Tag key={index} icon={<MoneyCollectOutlined />}>
              {'AR'}
            </Tag>
          );
        }
        return <></>;
      })}
    </>
  );
};

const TransactionList = () => {
  const {
    isLoading,
    snapshots,
    flipPage,
    pageSize,
    setPageSize,
    stringFilter,
  } = useContext(SalesContext);
  const { currentStore } = useContext(AuthContext);
  const sales = snapshots.map((snap) => snap.data());

  const PageNavigator = () => {
    const { paginationDisabled } = useContext(SalesContext);

    return (
      <Space hidden={paginationDisabled}>
        <Button
          disabled={paginationDisabled}
          icon={<LeftOutlined />}
          onClick={() => flipPage(-1)}
        />
        <Button
          disabled={paginationDisabled}
          icon={<RightOutlined />}
          onClick={() => flipPage(1)}
        />
        <Select
          disabled={paginationDisabled}
          value={pageSize}
          onChange={(value) => setPageSize(value)}
        >
          <Select.Option value={10}>10 / page</Select.Option>
          <Select.Option value={20}>20 / page</Select.Option>
          <Select.Option value={50}>50 / page</Select.Option>
          <Select.Option value={100}>100 / page</Select.Option>
          <Select.Option value={250}>250 / page</Select.Option>
        </Select>
      </Space>
    );
  };

  // const doMagic = () => {
  //   console.log('starting...');
  //   const batch = writeBatch(db);
  //   snapshots.forEach((snap) => {
  //     const data = snap.data();
  //     if (!data.barcodes.length) {
  //       const bcds =
  //         data.cart.cartItems.map((item) => {
  //           return [
  //             item.product?.barcode ?? '',
  //             ...(item.product?.barcodes ?? []),
  //           ];
  //         }) ?? [];
  //       const barcodes = _.uniq(_.flatMap(bcds, (value) => value));
  //       batch.update(snap.ref, {
  //         barcodes: barcodes,
  //       });
  //     }
  //   });
  //   batch
  //     .commit()
  //     .then(() => {
  //       console.log('success');
  //     })
  //     .catch((error) => {
  //       Notifications.error(error);
  //       console.log('error happened');
  //       console.error(error);
  //     });
  // };

  return (
    <Layout>
      <Content>
        <iframe
          style={{ display: 'none' }}
          id='print-iframe'
          width='100%'
          height='100%'
        />
        <Row justify='space-between'>
          <Title level={3}>Transactions</Title>
        </Row>
        <Card>
          <ProductProvider>
            <TransactionFilter />
          </ProductProvider>
          <Table
            bordered
            style={{ marginTop: '24px' }}
            loading={isLoading}
            size='small'
            dataSource={sales}
            columns={[
              {
                title: 'Transaction',
                align: 'center',
                render: (record: Sale) => {
                  return (
                    <Link
                      style={{
                        color: record.isVoidedOrRefund()
                          ? '#ff4d4f'
                          : undefined,
                        fontWeight: 'bold',
                      }}
                      to={(location) => {
                        if (record.salesOrder) {
                          const soRef = doc(db, record.salesOrder);
                          return {
                            ...location,
                            pathname: `./salesorders/${soRef.id}`,
                          };
                        }
                        return {
                          ...location,
                          pathname: `${location.pathname}/${record.key}`,
                        };
                      }}
                    >
                      <Highlighter
                        highlightStyle={HighlighterStyle}
                        searchWords={[stringFilter]}
                        textToHighlight={record.getTransactionTimestampId()}
                      />
                    </Link>
                  );
                },
              },
              {
                title: '# of items',
                align: 'center',
                render: (record: Sale) => {
                  return record.cart.cartItems.reduce(
                    (prev, curr) => prev + curr.quantity,
                    0
                  );
                },
              },
              {
                title: 'Payment Amount',
                align: 'center',
                render: (record: Sale) => {
                  return ppPrice(record.calculateTotalPayment());
                },
              },
              {
                title: 'Register',
                align: 'center',
                dataIndex: 'drawerName',
              },
              {
                title: 'Payment Methods',
                align: 'center',
                render: (record: Sale) => {
                  return <PaymentMethods payments={record.payments} />;
                },
              },
              {
                title: 'Timestamp',
                align: 'center',
                render: (record: Sale) => {
                  return record.getCreatedTime();
                },
              },
              {
                title: 'Action',
                align: 'center',
                render: (record: Sale) => {
                  return (
                    <>
                      <Link
                        to={(location) => {
                          if (record.salesOrder) {
                            const soRef = doc(db, record.salesOrder);
                            return {
                              ...location,
                              pathname: `./salesorders/${soRef.id}`,
                            };
                          }
                          return {
                            ...location,
                            pathname: `${location.pathname}/${record.key}`,
                          };
                        }}
                      >
                        Edit
                      </Link>
                      <Divider type='vertical' />
                      <AntdLink
                        onClick={() => {
                          Modal.confirm({
                            closable: true,
                            maskClosable: true,
                            okText: 'Print',
                            icon: null,
                            centered: true,
                            content: (
                              <Receipt
                                key={'receipt-' + record.key}
                                fullHeight
                                store={currentStore!}
                                sale={record}
                              />
                            ),
                            onOk: () => {
                              onPrint();
                            },
                          });
                        }}
                      >
                        Receipt
                      </AntdLink>
                    </>
                  );
                },
              },
            ]}
            pagination={false}
          />

          <Row justify='end' style={{ marginTop: 16 }}>
            <PageNavigator />
          </Row>
        </Card>
      </Content>
    </Layout>
  );
};

export { TransactionList };
