interface IPayrollSummary {
  cpp?: number;
  cpp2?: number;
  ei?: number;
  employees?: number;
  employeesProcessed?: number;
  grossPay?: number;
  netPay?: number;
  payToCRA?: number;
  tax?: number;
}

export class PayrollSummary implements IPayrollSummary {
  readonly cpp: number;
  readonly cpp2: number;
  readonly ei: number;
  readonly employees: number;
  readonly employeesProcessed: number;
  readonly grossPay: number;
  readonly netPay: number;
  readonly payToCRA: number;
  readonly tax: number;

  constructor(data?: IPayrollSummary) {
    this.cpp = data?.cpp ?? 0;
    this.cpp2 = data?.cpp2 ?? 0;
    this.ei = data?.ei ?? 0;
    this.employees = data?.employees ?? 0;
    this.employeesProcessed = data?.employeesProcessed ?? 0;
    this.grossPay = data?.grossPay ?? 0;
    this.netPay = data?.netPay ?? 0;
    this.payToCRA = data?.payToCRA ?? 0;
    this.tax = data?.tax ?? 0;
  }
}
