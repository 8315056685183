import { Supplier } from '../../../models/supplier';
import { SupplierFormFields } from './supplier-form-fields';

const supplier2Form = (supplier?: Supplier): SupplierFormFields => {
  return JSON.parse(
    JSON.stringify({
      ...new Supplier('', {}),
      ...supplier,
    })
  );
};

export { supplier2Form };
