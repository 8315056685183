export type PaymentType =
  | 'PaymentType.Cash'
  | 'PaymentType.Credit'
  | 'PaymentType.Debit'
  | 'PaymentType.Cheque'
  | 'PaymentType.Loyalty'
  | 'PaymentType.AR';

export interface IPayment {
  totalCashReturned?: number;
  totalPayed?: number;
  type?: PaymentType;
}

export class Payment implements IPayment {
  readonly totalCashReturned: number;
  readonly totalPayed: number;
  type: PaymentType;

  constructor(data?: IPayment) {
    this.totalCashReturned = data?.totalCashReturned ?? 0;
    this.totalPayed = data?.totalPayed ?? 0;
    this.type = data?.type ?? 'PaymentType.Cash';
  }
}
