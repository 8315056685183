import { Modal } from 'antd';
import { Location } from 'history';
import { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { removeBeforeUnloadDirtyDialog } from '../../../utils/dirty-state-helpers';
interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}
const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      removeBeforeUnloadDirtyDialog();
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {/* Your own alert/dialog/modal component */}
      <Modal
        centered
        title='Unsaved changes'
        visible={modalVisible}
        onOk={handleConfirmNavigationClick}
        okText='Leave page'
        okType='danger'
        onCancel={closeModal}
        cancelText='Cancel'
      >
        You have unsaved changes, are you sure you want to leave this page?
      </Modal>
    </>
  );
};
export default RouteLeavingGuard;
