import { Amount, IAmount } from './amount';

export interface ILevel {
  discount?: IAmount;
  price?: number;
  quantity?: number;
}

export class Level implements ILevel {
  readonly discount: Amount;
  readonly price: number;
  readonly quantity: number;

  constructor(data?: ILevel) {
    this.discount = new Amount(data?.discount);
    this.price = data?.price ?? 0;
    this.quantity = data?.quantity ?? 0;
  }
}
