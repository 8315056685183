import {
  collection,
  collectionGroup,
  DocumentData,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  Timestamp,
  where,
} from 'firebase/firestore';
import { DateTime } from 'luxon';
import { db } from '../firebase/Fb';

const StreamDrawers = (
  companyId: string,
  storeId: string,
  startDateTime: DateTime | undefined,
  endDateTime: DateTime | undefined,
  observer: {
    next?: ((snapshot: QuerySnapshot<DocumentData>) => void) | undefined;
    error?: ((error: Error) => void) | undefined;
    complete?: (() => void) | undefined;
  }
) => {
  if (companyId && storeId) {
    console.log('Requesting for Drawers.');
    let q = query(
      collection(db, `companies/${companyId}/stores/${storeId}/drawers`),
      orderBy('updatedTime', 'desc')
    );
    if (startDateTime) {
      q = query(
        q,
        where('updatedTime', '>=', Timestamp.fromDate(startDateTime.toJSDate()))
      );
      if (endDateTime) {
        query(
          q,
          where('updatedTime', '<=', Timestamp.fromDate(endDateTime.toJSDate()))
        );
      }
    }
    return onSnapshot(q, observer);
  }
};

const StreamDrawerTransfers = (
  companyId: string,
  storeId: string,
  startDateTime: DateTime | undefined,
  endDateTime: DateTime | undefined,
  observer: {
    next?: ((snapshot: QuerySnapshot<DocumentData>) => void) | undefined;
    error?: ((error: Error) => void) | undefined;
    complete?: (() => void) | undefined;
  }
) => {
  // Todo: change this to HTTP GET request as doing collection group queries can grab data from other companies/stores!!!
  if (companyId && storeId) {
    console.log('Requesting for DrawerTransfers.');
    let q = query(
      collectionGroup(db, 'transfers'),
      where('companyId', '==', companyId),
      where('storeId', '==', storeId),
      orderBy('createdTime', 'desc')
    );
    if (startDateTime) {
      q = query(
        q,
        where('createdTime', '>=', Timestamp.fromDate(startDateTime.toJSDate()))
      );
      if (endDateTime) {
        q = query(
          q,
          where('createdTime', '<=', Timestamp.fromDate(endDateTime.toJSDate()))
        );
      }
    }
    return onSnapshot(q, observer);
  }
};

export { StreamDrawers, StreamDrawerTransfers };
