import _ from 'lodash';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

const useDebouncedOnFormValuesChange = <T,>(
  setFieldsValue: Dispatch<SetStateAction<T>>
) => {
  const onFormValuesChange = useMemo(
    () =>
      _.debounce((changedValues: any, values: T) => {
        return setFieldsValue(values);
      }, 350),
    [setFieldsValue]
  );

  useEffect(() => {
    return onFormValuesChange.cancel;
  }, [onFormValuesChange]);

  return onFormValuesChange;
};

export { useDebouncedOnFormValuesChange };
