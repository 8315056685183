import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';

const NotFound = ({
  backUrl,
  backButtonText,
  itemText,
}: {
  backUrl: string;
  backButtonText: string;
  itemText: string;
}) => {
  const history = useHistory();

  return (
    <Result
      status='404'
      title={`There's no ${itemText} at this address`}
      subTitle='Please check the URL and try again, or press the button to go back to the main menu.'
      extra={
        <Button type='primary' onClick={() => history.push(backUrl)}>
          {backButtonText}
        </Button>
      }
    />
  );
};

export { NotFound };
