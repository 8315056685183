import { masterRepository } from '../../firebase/db';
import { Fee } from '../../models/fee';
import {
  createGenericContext,
  createGenericProvider,
} from './generics/GenericContextProvider';

const FeeContext = createGenericContext<Fee>();
const FeeProvider = createGenericProvider<Fee>(
  masterRepository.fee,
  FeeContext,
  'Fee'
);

export { FeeContext, FeeProvider };
