import { masterRepository } from '../../firebase/db';
import { Company } from '../../models/company';
import {
  createGenericContext,
  createGenericProvider,
} from './generics/GenericContextProvider';

const CompanyContext = createGenericContext<Company>();
const CompanyProvider = createGenericProvider<Company>(
  masterRepository.company,
  CompanyContext,
  'Company'
);

export { CompanyContext, CompanyProvider };
