import { notification } from 'antd';
import {
  DocumentSnapshot,
  endBefore,
  FieldPath,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  OrderByDirection,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
  WhereFilterOp,
} from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../firebase/Auth';
import { GenericRepository } from '../../../firebase/db';

interface OrderByClause {
  fieldPath: string | FieldPath;
  directionStr?: OrderByDirection | undefined;
}

interface WhereClause {
  fieldPath: string | FieldPath;
  opStr: WhereFilterOp;
  value: unknown;
}

interface PaginationProviderProps {
  children: React.ReactNode;
}

interface PaginationContextProps<T> {
  snapshots: QueryDocumentSnapshot<T>[];
  isLoading: boolean;
  flipPage: Function;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  stringFilter: string;
  setStringFilter: React.Dispatch<React.SetStateAction<string>>;
  setFilterBy: React.Dispatch<React.SetStateAction<WhereClauseSearch[]>>;
  paginationDisabled: boolean;
}

const defaultPageSize = 20;

const createPaginationContext = <T,>() => {
  return React.createContext<PaginationContextProps<T>>({
    snapshots: [],
    isLoading: true,
    flipPage: (page: number) => {},
    pageSize: defaultPageSize,
    setPageSize: () => {},
    stringFilter: '',
    setStringFilter: () => {},
    setFilterBy: () => {},
    paginationDisabled: false,
  });
};

export interface WhereClauseSearch extends WhereClause {
  isSearch?: boolean;
}

const createPaginationProvider = <T,>(
  genericRepository: GenericRepository<T>,
  ResourceContext: React.Context<PaginationContextProps<T>>,
  className: string // For debugging purposes (logging). PLEASE DO NOT RELY ON THIS VARIABLE.
) => {
  const GenericPaginationProvider: React.FC<PaginationProviderProps> = ({
    children,
  }) => {
    const { currentCompany, currentStore } = useContext(AuthContext);
    const [snapshots, setSnapshots] = useState<QueryDocumentSnapshot<T>[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [pageOrder, setPageOrder] = useState<OrderByClause>({
      fieldPath: 'createdTime',
      directionStr: 'desc',
    });
    const [pageAfter, setPageAfter] = useState<DocumentSnapshot>();
    const [pageBefore, setPageBefore] = useState<DocumentSnapshot>();
    const [filterBy, setFilterBy] = useState<WhereClauseSearch[]>([]);
    const [stringFilter, setStringFilter] = useState('');
    const [paginationDisabled, setPaginationDisabled] = useState(false);

    useEffect(() => {
      setIsLoading(true);

      const ref = genericRepository.collection(
        currentCompany!.key,
        currentStore!.key
      )();

      // Base query
      let q = query(ref);

      // Filter by
      if (filterBy.length) {
        const dictionary = new Map();

        filterBy.forEach((searchBy) => {
          q = query(
            q,
            where(searchBy.fieldPath, searchBy.opStr, searchBy.value)
          );

          if (!dictionary.get(searchBy.fieldPath) && searchBy.isSearch) {
            q = query(q, orderBy(searchBy.fieldPath));
            dictionary.set(searchBy.fieldPath, 1);
          }
        });

        // Sort by createdTime.
        q = query(q, orderBy('createdTime', 'desc'));

        // Prevent explosion of match.
        q = query(q, limit(defaultPageSize));

        // Disable pagination (otherwise there's a bug where it only looks after/before startAfter endBefore)
        setPaginationDisabled(true);
      } else {
        setPaginationDisabled(false);

        // Order by
        q = query(q, orderBy(pageOrder.fieldPath, pageOrder.directionStr));

        // Pagination
        if (pageAfter) {
          q = query(q, startAfter(pageAfter), limit(pageSize));
        } else if (pageBefore) {
          q = query(q, endBefore(pageBefore), limitToLast(pageSize));
        } else {
          q = query(q, limit(pageSize));
        }
      }

      const unsubscribe = onSnapshot(q, {
        next: (snapshot) => {
          console.warn(
            `[Generic provider] Snapshot listener obtained ${className} collection size of ${snapshot.size}.`
          );
          const resources = snapshot.docs.map((documentSnapshot) => {
            return documentSnapshot;
          });
          setSnapshots(resources);
          setIsLoading(false);
        },
        error: (error) => {
          notification['error']({
            message: 'Database Error',
            description: error.toString(),
          });
          setIsLoading(false);
        },
      });

      return () => {
        console.warn(
          `[Generic provider] Snapshot listener destroyed ${className} snapshots.`
        );
        unsubscribe();
      };
    }, [
      currentCompany,
      currentStore,
      pageOrder,
      pageAfter,
      pageBefore,
      pageSize,
      filterBy,
    ]);

    const flipPage = (page: number) => {
      if (page === 1) {
        setPageAfter(snapshots[snapshots.length - 1]);
        setPageBefore(undefined);
      } else if (page === -1) {
        setPageAfter(undefined);
        setPageBefore(snapshots[0]);
      } else {
        throw new Error('Not supported');
      }
    };

    return (
      <ResourceContext.Provider
        value={{
          snapshots: snapshots,
          isLoading: isLoading,
          flipPage: flipPage,
          pageSize: pageSize,
          setPageSize: setPageSize,
          stringFilter: stringFilter,
          setStringFilter: setStringFilter,
          setFilterBy: setFilterBy,
          paginationDisabled: paginationDisabled,
        }}
      >
        {children}
      </ResourceContext.Provider>
    );
  };

  return GenericPaginationProvider;
};

export { createPaginationContext, createPaginationProvider };
