import { masterRepository } from '../../firebase/db';
import { Payroll } from '../../models/payroll';
import {
  createGenericContext,
  createGenericProvider,
} from './generics/GenericContextProvider';

const PayrollContext = createGenericContext<Payroll>();
const PayrollProvider = createGenericProvider<Payroll>(
  masterRepository.payroll,
  PayrollContext,
  'Payroll'
);

export { PayrollContext, PayrollProvider };
