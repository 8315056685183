import { doc, DocumentReference } from 'firebase/firestore';
import { db } from '../../firebase/Fb';

const documentReference2String = (
  documentReference?: DocumentReference | string
): string => {
  if (
    documentReference === undefined ||
    documentReference === null ||
    documentReference === ''
  ) {
    return '';
  } else if (typeof documentReference !== 'string') {
    return documentReference.path;
  }
  return documentReference;
};

const string2DocumentReference = (documentPath: string) => {
  if (documentPath === '') {
    return '';
  }
  return doc(db, documentPath);
};

export { documentReference2String, string2DocumentReference };
