import { message, notification } from 'antd';
import {
  deleteDoc,
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  serverTimestamp,
  writeBatch,
} from 'firebase/firestore';
import { db } from '../firebase/Fb';
import { Product } from './Product';

export interface IDepartment {
  description: string;
  favourite: boolean;
  title: string;
  products: DocumentReference[];
  parent?: DocumentReference;
}

export class Department implements IDepartment {
  description: string;
  favourite: boolean;
  title: string;
  products: DocumentReference[];
  parent?: DocumentReference;

  // Non-db columns
  reference: DocumentReference;
  key: string; // Uuid
  productObjects: Product[];

  constructor(snapshot: QueryDocumentSnapshot<DocumentData>) {
    const document = snapshot.data();

    this.description = document.description ?? '';
    this.favourite = document.favourite ?? false;
    this.title = document.title ?? '';
    this.parent = document.parent;
    this.products = document.products ?? [];

    // Non-db columns
    this.reference = snapshot.ref;
    this.key = snapshot.id;
    this.productObjects = [];
  }

  async delete() {
    try {
      await deleteDoc(this.reference);
      message.success('Deleted!');
      return true;
    } catch (error: any) {
      notification['error']({
        message: 'Database Error',
        description: error.toString(),
      });
      return false;
    }
  }

  async update() // addedSubdepartments?: Department[],
  // removedSubdepartments?: Department[]
  {
    try {
      const batch = writeBatch(db);

      batch.update(this.reference, {
        title: this.title,
        description: this.description,
        favourite: this.favourite,
        products: this.products,
        updatedTime: serverTimestamp(),
      });

      // const parent = this.reference;
      // addedSubdepartments?.forEach((subdepartment) =>
      //   batch.update(subdepartment.reference, { parent: parent })
      // );

      // removedSubdepartments?.forEach((subdepartment) =>
      //   batch.update(subdepartment.reference, {
      //     parent: deleteField(),
      //   })
      // );
      await batch.commit();
      message.success('Updated!');
      return true;
    } catch (error: any) {
      notification['error']({
        message: 'Database Error',
        description: error.toString(),
      });
      return false;
    }
  }
}
