import { Button, Modal, Space } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
// @ts-ignore
import NumPad from 'react-numpad';
import { ppPrice } from '../../../utils/Formatter';

const VariablePriceDialog = (onPriceSet: (price: number) => void) => {
  const Dialog = Modal.success({
    centered: true,
    closable: true,
    maskClosable: false,
    title: 'Set Price',
    okText: null,
    icon: null,
    content: (
      <PriceDialog
        onOk={onPriceSet}
        closeDialog={() => {
          Dialog.destroy();
        }}
      />
    ),
    okButtonProps: {
      style: {
        display: 'none',
      },
    },
  });
};

const PriceDialog = ({
  onOk,
  closeDialog,
}: {
  onOk: (price: number) => void;
  closeDialog: () => void;
}) => {
  const [price, setPrice] = useState(0);

  return (
    <Space direction='vertical' style={{ width: '100%', marginTop: '8px' }}>
      <span id='numpad-text-area-center' className='numpad-extra-height'>
        <NumPad.Number
          position='center'
          decimal={2}
          negative={false}
          placeholder={ppPrice(price)}
          onChange={(value: any) => {
            setPrice(_.round(_.toNumber(value), 2));
          }}
        />
      </span>
      <Button
        size='large'
        block
        type='primary'
        disabled={price === 0}
        onClick={() => {
          onOk(price);
          closeDialog();
        }}
      >
        Add to cart
      </Button>
    </Space>
  );
};

export { VariablePriceDialog };
