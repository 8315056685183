import { PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, message, Space, Spin, Typography } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../firebase/Auth';
import { generatePersonas } from '../../models/employee';
import './UserProfileMenu.scss';
// import {
//   UploadRequestOption,
//   UploadChangeParam,
//   UploadFile,
// } from "antd/lib/upload/interface";
const { Text } = Typography;

const UserProfileMenu = () => {
  const { currentUser, reloadCurrentUser, signoutCurrentUser } =
    useContext(AuthContext);
  const [uploading, setUploading] = useState(false);

  const validateAvatarImg = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt10M;
  };

  // const customUpload = async ({
  //   onError,
  //   onSuccess,
  //   file,
  // }: UploadRequestOption) => {
  //   try {
  //     const image = await st
  //       .ref(`avatars/${Date.now()}${file.size}${file.name}`)
  //       .put(file);
  //     const imageUrl = await image.ref.getDownloadURL();
  //     await currentUser.firebase?.updateProfile({
  //       photoURL: imageUrl,
  //     });
  //     reloadCurrentUser();
  //     onSuccess({}, file);
  //   } catch (error: any) {
  //     message.error(error);
  //     onError(error);
  //   }
  // };

  // const uploadOnChange = (info: UploadChangeParam<UploadFile<any>>) => {
  //   if (info.file.status === "uploading") {
  //     setUploading(true);
  //   } else {
  //     setUploading(false);
  //   }
  // };

  return (
    <Space size='middle' className='profile-popover-width' direction='vertical'>
      {/* <Upload
        beforeUpload={validateAvatarImg}
        // customRequest={customUpload}
        showUploadList={false}
        // onChange={uploadOnChange}
      > */}
      <Avatar
        // style={{ cursor: "pointer" }}
        size={100}
        icon={uploading ? <Spin /> : <UserOutlined />}
        // src={uploading ? '' : currentUser.firebase?.photoURL ?? ''}
        style={{
          cursor: 'pointer',
          backgroundColor: '#ccc',
        }}
        // src={currentUser.firebase?.photoURL ?? ''}
        src={
          currentUser.firebase?.uid &&
          generatePersonas(currentUser.firebase.uid)
        }
      />
      {/* </Upload> */}
      <Space size={0} direction='vertical'>
        <Text
          strong
        >{`${currentUser.firestore?.firstname} ${currentUser.firestore?.lastname}`}</Text>
        <Text
          onClick={() => {
            currentUser.firebase?.getIdToken(true).then((token) => {
              if (navigator.clipboard) {
                navigator.clipboard.writeText(token);
              }
            });
          }}
        >
          {currentUser.firebase?.email}
        </Text>
      </Space>
      <Button
        onClick={() => {
          signoutCurrentUser();
        }}
        icon={<PoweroffOutlined />}
      >
        Sign out
      </Button>
    </Space>
  );
};

export { UserProfileMenu };
