import _ from 'lodash';

const FormItemStyle = {
  width: '100%',
  marginBottom: 0,
};

const InputStyle = {
  maxWidth: '400px',
};

const SelectStyle = {
  maxWidth: '400px',
};

const TextAreaStyle = {
  maxWidth: '100%',
};

const CardStyle = {
  width: '100%',
};

const SpaceStyle = {
  width: '100%',
};

const SideMenuMaxWidthStyle = {
  maxWidth: '300px',
};

const RowStyle = {
  marginBottom: 16,
};

const HighlighterStyle = { backgroundColor: '#ffc069', padding: 0 };

const FormLabelCol = { xs: 8, sm: 6, md: 7, lg: 6 };

const DollarInputNumberFormatter = (value: number | undefined) => {
  if (value) {
    return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '';
};

const PercentageInputNumberFormatter = (value: number | undefined) => {
  return `${value}%`;
};

const BankInfoFormatter = (size: number) => {
  return (value: number | undefined) => {
    if (value) {
      return _.padStart(_.toString(value), size, '0');
    }
    return _.padStart('', size, '0');
  };
};

const HoursInputNumberFormatter = (value?: number) => {
  if (value) {
    return `${value} hrs`;
  }
  return '';
};

const TransitFormatter = BankInfoFormatter(5);
const InstitutionFormatter = BankInfoFormatter(3);

export {
  FormItemStyle,
  InputStyle,
  CardStyle,
  RowStyle,
  SelectStyle,
  TextAreaStyle,
  SideMenuMaxWidthStyle,
  SpaceStyle,
  HighlighterStyle,
  FormLabelCol,
  DollarInputNumberFormatter,
  PercentageInputNumberFormatter,
  BankInfoFormatter,
  HoursInputNumberFormatter,
  TransitFormatter,
  InstitutionFormatter,
};
