import { Card, Form, FormInstance, PageHeader } from 'antd';
import { addDoc, deleteDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Supplier } from '../../../models/supplier';
import { SupplierContext } from '../../../providers/base/SupplierProvider';
import { FormLabelCol } from '../../common/detail/cards/card-styles';
import { DeleteDiscardOrSaveButtons } from '../../common/detail/DeleteDiscardOrSaveButtons';
import { DetailLayout } from '../../common/detail/DetailLayout';
import { DiscardOrSaveButtons } from '../../common/detail/DiscardOrSaveButtons';
import { useDebouncedOnFormValuesChange } from '../../common/detail/useDebouncedOnFormValuesChange';
import { useDirtyObject } from '../../common/use/useDirty';
import { Messages, Notifications } from '../../notification/Messages';
import { form2Supplier } from '../form-converters/form-2-supplier';
import { supplier2Form } from '../form-converters/supplier-2-form';
import { SupplierFormFields } from '../form-converters/supplier-form-fields';
import { SupplierContent } from './SupplierContent';

const SupplierDetail = ({ supplierId }: { supplierId: string }) => {
  const history = useHistory();
  const formRef = useRef<FormInstance>(null);
  const [form] = Form.useForm();
  const { snapshots, repository } = useContext(SupplierContext);
  const snapshot = snapshots.find((snap) => snap.id === supplierId);
  const supplier = snapshot?.data();
  const [initialFieldsValue, setInitialFieldsValue] =
    useState<SupplierFormFields>(supplier2Form(supplier));
  const [fieldsValue, setFieldsValue] = useState(initialFieldsValue);
  const [exit, setExit] = useState({ exit: false, url: '.', replace: false });
  const { isDirty, deltaValues } = useDirtyObject<SupplierFormFields>(
    initialFieldsValue,
    fieldsValue,
    exit
  );
  const onFormValuesChange =
    useDebouncedOnFormValuesChange<SupplierFormFields>(setFieldsValue);
  const [isActionButtonLoading, setIsActionButtonLoading] = useState(false);
  const isNew = !supplier;

  const onDiscard = () => {
    // Must set setAllFieldValues programatically as setFieldsValue doesn't call onValuesChange event https://github.com/ant-design/ant-design/issues/23782
    formRef.current!.setFieldsValue(initialFieldsValue);
    setFieldsValue(initialFieldsValue);
    if (isNew) {
      setExit({ exit: true, url: '.', replace: false });
    }
  };

  const onSave = async (values: SupplierFormFields) => {
    try {
      setIsActionButtonLoading(true);
      if (isNew) {
        const newSupplier = form2Supplier({
          ...values,
          createdTime: serverTimestamp(),
        });
        const createdSupplier = await addDoc(
          repository!.collection(),
          new Supplier('', newSupplier)
        );
        Messages.created();
        setInitialFieldsValue(values);
        setExit({ exit: true, url: createdSupplier.id, replace: true });
      } else {
        const updatedSupplier = form2Supplier(deltaValues!);
        await setDoc(
          repository!.document(supplier.key),
          new Supplier('', {
            ...updatedSupplier,
            updatedTime: serverTimestamp(),
          }),
          {
            merge: true,
          }
        );
        Messages.saved();
        setInitialFieldsValue(values);
      }
    } catch (e) {
      Notifications.error(e);
    } finally {
      setIsActionButtonLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setIsActionButtonLoading(true);
      await deleteDoc(snapshot!.ref);
      Messages.deleted();
    } catch (e) {
      Notifications.error(e);
    } finally {
      setIsActionButtonLoading(false);
    }
  };

  return (
    <Form
      form={form}
      ref={formRef}
      name='product-detail-form'
      onFinish={onSave}
      labelCol={FormLabelCol}
      initialValues={initialFieldsValue}
      onValuesChange={onFormValuesChange}
      scrollToFirstError={{ behavior: 'smooth' }}
    >
      <DetailLayout
        pageHeader={
          <PageHeader
            className={isDirty ? 'dirty-header-indicator' : undefined}
            onBack={() => history.push('.')}
            title={isNew ? 'Add supplier' : supplier?.name}
            extra={
              <DiscardOrSaveButtons
                allowMetaKeyEnterSubmit
                routeLeavingGuard
                isNew={isNew}
                isDirty={isDirty}
                isLoading={isActionButtonLoading}
                show={isDirty || isNew}
                onSubmit={() => form.submit()}
                onDiscardConfirm={onDiscard}
              />
            }
          />
        }
        mainContent={
          <Card>
            <SupplierContent />
          </Card>
        }
        footerContent={
          <DeleteDiscardOrSaveButtons
            type='supplier'
            isNew={isNew}
            isDirty={isDirty}
            isLoading={isActionButtonLoading}
            onDiscard={onDiscard}
            onDelete={onDelete}
            onSubmit={() => form.submit()}
          />
        }
      />
    </Form>
  );
};

export { SupplierDetail };
