import { CustomerProvider } from '../base/CustomerProvider';
import { DepartmentProvider } from '../base/DepartmentProvider';
import { DrawerProvider } from '../base/DrawerProvider';
import { FeeProvider } from '../base/FeeProvider';
import { GroupProvider } from '../base/GroupProvider';
import { ProductProvider } from '../base/ProductProvider';
import { SaleProvider } from '../base/SaleProvider';

const WithPosProviders = ({ children }: { children: JSX.Element }) => {
  return (
    <ProductProvider>
      <DrawerProvider
        where={{
          fieldPath: 'actualCash',
          opStr: '==',
          value: -1,
        }}
      >
        <GroupProvider>
          <FeeProvider>
            <CustomerProvider>
              <DepartmentProvider>
                <SaleProvider
                  orderBy={{ fieldPath: 'createdTime', directionStr: 'desc' }}
                  limit={1}
                >
                  {children}
                </SaleProvider>
              </DepartmentProvider>
            </CustomerProvider>
          </FeeProvider>
        </GroupProvider>
      </DrawerProvider>
    </ProductProvider>
  );
};

export { WithPosProviders };
