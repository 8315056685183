import { Button, Card, Layout, Modal, Row, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../firebase/Auth';
import { db } from '../../firebase/Fb';
import { AuditLog } from '../../models/auditlog';
import { ppPrice } from '../../utils/Formatter';
import { Notifications } from '../notification/Messages';
import { CartList } from '../sell/register/CartList';

const AuditLogs = () => {
  const { currentCompany, currentStore } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);

  useEffect(() => {
    setLoading(true);

    const q = query(
      collection(
        db,
        'companies',
        currentCompany!.key,
        'stores',
        currentStore!.key,
        'auditlogs'
      ),
      where('createdTime', '>=', moment().subtract(6, 'months').toDate()),
      orderBy('createdTime', 'desc')
    );

    const unsubscribe = onSnapshot(q, {
      next: (snapshot) => {
        const auditLogs = snapshot.docs.map(
          (snapshot) => new AuditLog(snapshot.id, snapshot.data())
        );
        setAuditLogs(auditLogs);
        setLoading(false);
      },
      error: (error) => {
        Notifications.error(error);
        setLoading(false);
      },
    });

    return unsubscribe;
  }, []);

  return (
    <Layout>
      <Content>
        <Row justify='space-between'>
          <Title level={3}>Audit Logs</Title>
        </Row>
        <Card>
          <Table
            bordered
            size='small'
            loading={loading}
            dataSource={auditLogs}
            pagination={{ defaultPageSize: 20 }}
            columns={[
              {
                title: 'Action',
                render: () => {
                  return 'Cart cleared';
                },
              },
              {
                title: 'By',
                dataIndex: 'by',
              },
              {
                align: 'center',
                title: '# of Items',
                render: (auditLog: AuditLog) => {
                  return (
                    <Button
                      type='link'
                      size='small'
                      onClick={() => {
                        Modal.info({
                          maskClosable: true,
                          // @ts-ignore
                          title: auditLog.createdTime.toDate().toLocaleString(),
                          centered: true,
                          content: (
                            <Card style={{ marginTop: 32 }}>
                              <CartList
                                cart={auditLog.cart}
                                setCart={() => {}}
                                maxDiscount={0}
                                viewOnly
                              />
                            </Card>
                          ),
                        });
                      }}
                    >
                      {auditLog.cart.cartItems.reduce(
                        (prev, curr) => prev + curr.quantity,
                        0
                      )}
                    </Button>
                  );
                },
              },
              {
                align: 'right',
                title: 'Total Amount',
                render: (auditLog: AuditLog) => {
                  return ppPrice(auditLog.cart.calculateTotal());
                },
              },
              {
                align: 'right',
                title: 'Timestamp',
                render: (auditLog: AuditLog) => {
                  // @ts-ignore
                  return auditLog.createdTime.toDate().toLocaleString();
                },
              },
            ]}
          />
        </Card>
      </Content>
    </Layout>
  );
};

export default AuditLogs;
